import './App.css';
import { useState } from 'react';
import React from 'react'
import './Assets/Fonts/ikamodoki1_0.ttf';
import './Assets/Fonts/Paintball_Beta_3.ttf';
import supura2 from'./img/supura2.png';
import wakaba from'./img/wepon/wakaba.png';
import sushi from'./img/wepon/supurasyuta.png';
import garon52 from'./img/wepon/garon52.png';
import bake from'./img/wepon/bake.png';
import baleru from'./img/wepon/baleru.png';
import dainamo from'./img/wepon/dainamo.png';
import hidora from'./img/wepon/hidora.png';
import hissen from'./img/wepon/hissen.png';
import hokusai from'./img/wepon/hokusai.png';
import hot from'./img/wepon/hot.png';
import kyanpu from'./img/wepon/kyanpu.png';
import manyu from'./img/wepon/manyu.png';
import modera from'./img/wepon/modera.png';
import nzap from'./img/wepon/nzap.png';
import nova from'./img/wepon/nova.png';
import paburo from'./img/wepon/paburo.png';
import para from'./img/wepon/para.png';
import prime from'./img/wepon/prime.png';
import quado from'./img/wepon/quado.png';
import ritta from'./img/wepon/ritta.png';
import rittasuko from'./img/wepon/rittasuko.png';
import rora from'./img/wepon/rora.png';
import supusuko from'./img/wepon/supusuko.png';
import suputya from'./img/wepon/suputya.png';
import tri from'./img/wepon/tri.png';
import waipa from'./img/wepon/waipa.png';
import simane from'./img/wepon/simane.png';
import l3 from'./img/wepon/l3.png';
import h3 from'./img/wepon/h3.png';
import botoru from'./img/wepon/botoru.png';
import garon96 from'./img/wepon/96garon.png';
import zyetto from'./img/wepon/zyetto.png';
import bordo from'./img/wepon/bordo.png';
import duaru from'./img/wepon/duaru.png';
import supa from'./img/wepon/supa.png';
import kerbin from'./img/wepon/kerbin.png';
import karbon from'./img/wepon/karbon.png';
import varia from'./img/wepon/baria.png';
import supusupi from'./img/wepon/supusupi.png';
import kugel from'./img/wepon/kugel.png';
import noti from'./img/wepon/noti.png';
import take from'./img/wepon/take.png';
import soi from'./img/wepon/soi.png';
import sukuiku from'./img/wepon/sukuiku.png';
import supai from'./img/wepon/supai.png';
import lact from'./img/wepon/lact.png';
import zimwaipa from'./img/wepon/zimwaipa.png';
import long from'./img/wepon/long.png';
import rapi from'./img/wepon/rapi.png';
import kura from'./img/wepon/kura.png';
import sukusuro from'./img/wepon/sukusuro.png';
import ofuro from'./img/wepon/ofuro.png';
import ex from'./img/wepon/ex.png';
import rapieri from'./img/wepon/rapieri.png';

import karingbom from'./img/sub/karingbom.png';
import kyubanbom from'./img/sub/kyubanbom.png';
import linemarker from'./img/sub/linemarker.png';
import quickbom from'./img/sub/quickbom.png';
import sheld from'./img/sub/sheld.png';
import supurabom from'./img/sub/supurabom.png';
import supurinkuler from'./img/sub/supurinkuler.png';
import trappu from'./img/sub/trappu.png';
import tansanbom from'./img/sub/tansanbom.png';
import robotbom from'./img/sub/robotbom.png';
import poizun from'./img/sub/poizun.png';
import bikon from'./img/sub/bikon.png';
import topid from'./img/sub/topid.png';
import sensar from'./img/sub/sensar.png';
import ame from'./img/special/ame.png';
import baria from'./img/special/baria.png';
import enagy from'./img/special/enagy.png';
import hanko from'./img/special/hanko.png';
import kani from'./img/special/kani.png';
import kyuin from'./img/special/kyuin.png';
import megahon from'./img/special/megahon.png';
import misairu from'./img/special/misairu.png';
import nice from'./img/special/nice.png';
import pop from'./img/special/pop.png';
import same from'./img/special/same.png';
import syoku from'./img/special/syoku.png';
import torune from'./img/special/torune.png';
import ultola from'./img/special/ultola.png';
import zyettopack from'./img/special/zyettopack.png';
import mainink from'./img/gear/mainink.png';
import action from'./img/gear/action.png';
import annzenkutu from'./img/gear/annzenkutu.png';
import baika from'./img/gear/baika.png';
import gyakkyou from'./img/gear/gyakkyou.png';
import hitosoku from'./img/gear/hitosoku.png';
import huukatu from'./img/gear/huukatu.png';
import ikaninnzya from'./img/gear/ikaninnzya.png';
import ikasoku from'./img/gear/ikasoku.png';
import inkkaihuku from'./img/gear/inkkaihuku.png';
import kamback from'./img/gear/kamback.png';
import lastspa from'./img/gear/lastspa.png';
import penaup from'./img/gear/penaup.png';
import ribenzi from'./img/gear/ribenzi.png';
import sarmal from'./img/gear/sarmal.png';
import startdush from'./img/gear/startdush.png';
import subink from'./img/gear/subink.png';
import subkeigen from'./img/gear/subkeigen.png';
import subseinou from'./img/gear/subseinou.png';
import supazyan from'./img/gear/supazyan.png';
import supegen from'./img/gear/supegen.png';
import supesei from'./img/gear/supesei.png';
import supezou from'./img/gear/supezou.png';
import suterusu from'./img/gear/suterusu.png';
import taibutu from'./img/gear/taibutu.png';
import ukemi from'./img/gear/ukemi.png';







import {
    Radar, RadarChart, PolarGrid, Legend,
    PolarAngleAxis, PolarRadiusAxis,
} from 'recharts';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import 'react-tabs/style/react-tabs.css';

const data = [
    {subject: '対面力', A: 0, fullMark: 100},
    {subject: '塗り力', A: 0,  fullMark: 100},
    {subject: '機動力', A: 0, fullMark: 100},
  {subject: 'スペシャル', A: 0,  fullMark: 100},
    {subject: '妨害力', A: 0,  fullMark: 100},
    {subject: 'ステルス', A: 0,  fullMark: 100},

　

];


class App extends React.Component {
constructor(props){
  super(props);
  this.state ={
dansu:0,
main:0,
inksub:0,
meseimein:0,
meseisub:0,
submain:0,
subsub:0,
supemain:0,
supesub:0,
kaimain:0,
kaisub:0,
buki:0,
buki3:0,
ikasokumain:0,
ikasokumainsub:0,
ikasokumainhi:0,
ikasokusubhi:0,
hisokumain:0,
hitosokusub:0,
hisokumainhi:0,
hitosokusubhi:0,
bakugenmain:0,
bakugensub:0,
annzennmain:0,
annzennsub:0,
huukatumain:0,
huukatusub:0,
supatanmain:0,
supatansub:0,
supegenmain:0,
supegensub:0,


};
this.anime = React.createRef();
this.anime2 = React.createRef();
this.asd = React.createRef();
this.asd2 = React.createRef();
this.asd3 = React.createRef();
this.asd4 = React.createRef();
this.asd5 = React.createRef();
this.asd6 = React.createRef();
this.asd7 = React.createRef();
this.mainimg =  React.createRef();
this.subimg =  React.createRef();
this.supeimg =  React.createRef();
this.atamaMainimg =  React.createRef();
this.atamaSub1img =  React.createRef();
this.atamaSub2img =  React.createRef();
this.atamaSub3img =  React.createRef();
this.hukuMainimg=  React.createRef();
this.hukuSub1img =  React.createRef();
this.hukuSub2img =  React.createRef();
this.hukuSub3img =  React.createRef();
this.kutuMainimg=  React.createRef();
this.kutuSub1img =  React.createRef();
this.kutuSub2img =  React.createRef();
this.kutuSub3img =  React.createRef();



 this.handleChange = this.handleChange.bind(this);
 this.handleSubmit = this.handleSubmit.bind(this);
 this.handleSubmitidou = this.handleSubmitidou.bind(this);
  this. handleSubmitsonota = this. handleSubmitsonota.bind(this);
this.handleSubmit4 = this.handleSubmit4.bind(this);
this.ika = React.createRef();
this.hito = React.createRef();
this.ikasoku = React.createRef();
this.ikasoku2 = React.createRef();
this.ikanin = React.createRef();
this.hitosoku = React.createRef();
this.hitosoku2 = React.createRef();
this.hitosoku3 = React.createRef();
this.hitosoku4 = React.createRef();



}




handleChange(event) {


const target = event.target;

const name = target.name;
this.setState({[name]: target.value})

/////選択ぶき画像表示
 var mainBuki = this.state.buki;


var mainImage = this.mainimg.current;
var subImage = this.subimg.current;
var supeImage = this.supeimg.current;

if(mainBuki=="supura"){
  mainImage.style.backgroundImage=`url(${sushi})`
subImage.style.backgroundImage=`url(${kyubanbom})`
supeImage.style.backgroundImage=`url(${ultola})`
}else if(mainBuki=="syapu") {
  mainImage.style.backgroundImage=`url(${simane})`
  subImage.style.backgroundImage=`url(${karingbom})`
  supeImage.style.backgroundImage=`url(${kani})`
}else if(mainBuki=="prime") {
  mainImage.style.backgroundImage=`url(${prime})`
  subImage.style.backgroundImage=`url(${linemarker})`
  supeImage.style.backgroundImage=`url(${kani})`
}else if(mainBuki=="l3") {
  mainImage.style.backgroundImage=`url(${l3})`
  subImage.style.backgroundImage=`url(${karingbom})`
  supeImage.style.backgroundImage=`url(${kani})`
}else if(mainBuki=="h3") {
  mainImage.style.backgroundImage=`url(${h3})`
  subImage.style.backgroundImage=`url(${trappu})`
  supeImage.style.backgroundImage=`url(${enagy})`
}else if(mainBuki=="botoru") {
  mainImage.style.backgroundImage=`url(${botoru})`
  subImage.style.backgroundImage=`url(${sheld})`
  supeImage.style.backgroundImage=`url(${ultola})`
}else if(mainBuki=="botoruren") {
  mainImage.style.backgroundImage=`url(${botoru})`
  subImage.style.backgroundImage=`url(${sheld})`
  supeImage.style.backgroundImage=`url(${ultola})`
}else if(mainBuki=="wakaba") {
  mainImage.style.backgroundImage=`url(${wakaba})`
  subImage.style.backgroundImage=`url(${supurabom})`
  supeImage.style.backgroundImage=`url(${baria})`
}else if(mainBuki=="garon52") {
  mainImage.style.backgroundImage=`url(${garon52})`
  subImage.style.backgroundImage=`url(${sheld})`
  supeImage.style.backgroundImage=`url(${megahon})`
}else if(mainBuki=="garon96") {
  mainImage.style.backgroundImage=`url(${garon96})`
  subImage.style.backgroundImage=`url(${supurinkuler})`
  supeImage.style.backgroundImage=`url(${kyuin})`
}else if(mainBuki=="zap") {
  mainImage.style.backgroundImage=`url(${nzap})`
  subImage.style.backgroundImage=`url(${kyubanbom})`
  supeImage.style.backgroundImage=`url(${enagy})`
}else if(mainBuki=="zyetto") {
  mainImage.style.backgroundImage=`url(${zyetto})`
  subImage.style.backgroundImage=`url(${linemarker})`
  supeImage.style.backgroundImage=`url(${kyuin})`
}else if(mainBuki=="borudo") {
  mainImage.style.backgroundImage=`url(${bordo})`
  subImage.style.backgroundImage=`url(${karingbom})`
  supeImage.style.backgroundImage=`url(${hanko})`
}else if(mainBuki=="modera") {
  mainImage.style.backgroundImage=`url(${modera})`
  subImage.style.backgroundImage=`url(${tansanbom})`
  supeImage.style.backgroundImage=`url(${same})`
}else if(mainBuki=="manyu") {
  mainImage.style.backgroundImage=`url(${manyu})`
  subImage.style.backgroundImage=`url(${kyubanbom})`
  supeImage.style.backgroundImage=`url(${kani})`
}else if(mainBuki=="kuaddo") {
  mainImage.style.backgroundImage=`url(${quado})`
    subImage.style.backgroundImage=`url(${robotbom})`
    supeImage.style.backgroundImage=`url(${same})`
}else if(mainBuki=="duaru") {
  mainImage.style.backgroundImage=`url(${duaru})`
    subImage.style.backgroundImage=`url(${supurabom})`
    supeImage.style.backgroundImage=`url(${pop})`
}else if(mainBuki=="supa") {
  mainImage.style.backgroundImage=`url(${supa})`
    subImage.style.backgroundImage=`url(${bikon})`
    supeImage.style.backgroundImage=`url(${enagy})`
}else if(mainBuki=="kerubin") {
  mainImage.style.backgroundImage=`url(${kerbin})`
    subImage.style.backgroundImage=`url(${sheld})`
    supeImage.style.backgroundImage=`url(${nice})`
}else if(mainBuki=="nova") {
  mainImage.style.backgroundImage=`url(${nova})`
      subImage.style.backgroundImage=`url(${supurabom})`
      supeImage.style.backgroundImage=`url(${syoku})`
}else if(mainBuki=="hot") {
  mainImage.style.backgroundImage=`url(${hot})`
      subImage.style.backgroundImage=`url(${robotbom})`
      supeImage.style.backgroundImage=`url(${baria})`
}else if(mainBuki=="long") {
  mainImage.style.backgroundImage=`url(${long})`
      subImage.style.backgroundImage=`url(${kyubanbom})`
      supeImage.style.backgroundImage=`url(${pop})`
}else if(mainBuki=="rapi") {
  mainImage.style.backgroundImage=`url(${rapi})`
      subImage.style.backgroundImage=`url(${trappu})`
      supeImage.style.backgroundImage=`url(${torune})`
}else if(mainBuki=="rapieri") {
  mainImage.style.backgroundImage=`url(${rapieri})`
      subImage.style.backgroundImage=`url(${poizun})`
      supeImage.style.backgroundImage=`url(${kyuin})`
}else if(mainBuki=="kura") {
  mainImage.style.backgroundImage=`url(${kura})`
      subImage.style.backgroundImage=`url(${supurabom})`
      supeImage.style.backgroundImage=`url(${ultola})`
}else if(mainBuki=="dainamo") {
  mainImage.style.backgroundImage=`url(${dainamo})`
      subImage.style.backgroundImage=`url(${supurinkuler})`
      supeImage.style.backgroundImage=`url(${enagy})`
}else if(mainBuki=="rola") {
  mainImage.style.backgroundImage=`url(${rora})`
      subImage.style.backgroundImage=`url(${karingbom})`
      supeImage.style.backgroundImage=`url(${baria})`
}else if(mainBuki=="kabon") {
  mainImage.style.backgroundImage=`url(${karbon})`
      subImage.style.backgroundImage=`url(${robotbom})`
      supeImage.style.backgroundImage=`url(${syoku})`
}else if(mainBuki=="varia") {
  mainImage.style.backgroundImage=`url(${varia})`
      subImage.style.backgroundImage=`url(${trappu})`
      supeImage.style.backgroundImage=`url(${misairu})`
}else if(mainBuki=="haidora") {
  mainImage.style.backgroundImage=`url(${hidora})`
      subImage.style.backgroundImage=`url(${robotbom})`
      supeImage.style.backgroundImage=`url(${nice})`
}else if(mainBuki=="supusupi") {
  mainImage.style.backgroundImage=`url(${supusupi})`
      subImage.style.backgroundImage=`url(${quickbom})`
      supeImage.style.backgroundImage=`url(${hanko})`
}else if(mainBuki=="kugel") {
  mainImage.style.backgroundImage=`url(${kugel})`
      subImage.style.backgroundImage=`url(${tansanbom})`
      supeImage.style.backgroundImage=`url(${zyettopack})`
}else if(mainBuki=="kugeltyou") {
  mainImage.style.backgroundImage=`url(${kugel})`
      subImage.style.backgroundImage=`url(${tansanbom})`
      supeImage.style.backgroundImage=`url(${zyettopack})`
}else if(mainBuki=="nooti") {
  mainImage.style.backgroundImage=`url(${noti})`
      subImage.style.backgroundImage=`url(${trappu})`
      supeImage.style.backgroundImage=`url(${ame})`
}else if(mainBuki=="bareru") {
  mainImage.style.backgroundImage=`url(${baleru})`
      subImage.style.backgroundImage=`url(${supurinkuler})`
      supeImage.style.backgroundImage=`url(${pop})`
}else if(mainBuki=="ritta") {
  mainImage.style.backgroundImage=`url(${ritta})`
      subImage.style.backgroundImage=`url(${trappu})`
      supeImage.style.backgroundImage=`url(${pop})`
}else if(mainBuki=="rittasuko") {
  mainImage.style.backgroundImage=`url(${rittasuko})`
        subImage.style.backgroundImage=`url(${trappu})`
              supeImage.style.backgroundImage=`url(${pop})`
}else if(mainBuki=="soi") {
  mainImage.style.backgroundImage=`url(${soi})`
      subImage.style.backgroundImage=`url(${topid})`
      supeImage.style.backgroundImage=`url(${misairu})`
}else if(mainBuki=="sukuiku") {
  mainImage.style.backgroundImage=`url(${sukuiku})`
      subImage.style.backgroundImage=`url(${trappu})`
      supeImage.style.backgroundImage=`url(${baria})`
}else if(mainBuki=="tyazya") {
  mainImage.style.backgroundImage=`url(${suputya})`
        subImage.style.backgroundImage=`url(${supurabom})`
              supeImage.style.backgroundImage=`url(${kyuin})`
}else if(mainBuki=="tyazyasuko") {
  mainImage.style.backgroundImage=`url(${supusuko})`
        subImage.style.backgroundImage=`url(${supurabom})`
            supeImage.style.backgroundImage=`url(${kyuin})`
}else if(mainBuki=="take") {
  mainImage.style.backgroundImage=`url(${take})`
        subImage.style.backgroundImage=`url(${robotbom})`
            supeImage.style.backgroundImage=`url(${megahon})`
}else if(mainBuki=="paburo") {
  mainImage.style.backgroundImage=`url(${paburo})`
        subImage.style.backgroundImage=`url(${supurabom})`
            supeImage.style.backgroundImage=`url(${megahon})`
}else if(mainBuki=="hokusai") {
  mainImage.style.backgroundImage=`url(${hokusai})`
        subImage.style.backgroundImage=`url(${kyubanbom})`
            supeImage.style.backgroundImage=`url(${syoku})`
}else if(mainBuki=="bake") {
  mainImage.style.backgroundImage=`url(${bake})`
        subImage.style.backgroundImage=`url(${supurabom})`
            supeImage.style.backgroundImage=`url(${torune})`
}else if(mainBuki=="hissen") {
  mainImage.style.backgroundImage=`url(${hissen})`
        subImage.style.backgroundImage=`url(${poizun})`
            supeImage.style.backgroundImage=`url(${zyettopack})`
}else if(mainBuki=="sukusuro") {
  mainImage.style.backgroundImage=`url(${sukusuro})`
        subImage.style.backgroundImage=`url(${tansanbom})`
            supeImage.style.backgroundImage=`url(${nice})`
}else if(mainBuki=="ofuro") {
  mainImage.style.backgroundImage=`url(${ofuro})`
        subImage.style.backgroundImage=`url(${supurinkuler})`
            supeImage.style.backgroundImage=`url(${ame})`
}else if(mainBuki=="ex") {
  mainImage.style.backgroundImage=`url(${ex})`
        subImage.style.backgroundImage=`url(${sensar})`
            supeImage.style.backgroundImage=`url(${ame})`
}else if(mainBuki=="kyanpu") {
  mainImage.style.backgroundImage=`url(${kyanpu})`
    subImage.style.backgroundImage=`url(${bikon})`
        supeImage.style.backgroundImage=`url(${kyuin})`
}else if(mainBuki=="para") {
  mainImage.style.backgroundImage=`url(${para})`
    subImage.style.backgroundImage=`url(${supurinkuler})`
        supeImage.style.backgroundImage=`url(${torune})`
}else if(mainBuki=="supai") {
  mainImage.style.backgroundImage=`url(${supai})`
    subImage.style.backgroundImage=`url(${trappu})`
        supeImage.style.backgroundImage=`url(${same})`
}else if(mainBuki=="sutori") {
  mainImage.style.backgroundImage=`url(${tri})`
    subImage.style.backgroundImage=`url(${poizun})`
        supeImage.style.backgroundImage=`url(${megahon})`
}else if(mainBuki=="lact") {
  mainImage.style.backgroundImage=`url(${lact})`
    subImage.style.backgroundImage=`url(${karingbom})`
        supeImage.style.backgroundImage=`url(${misairu})`
}else if(mainBuki=="waipa") {
  mainImage.style.backgroundImage=`url(${waipa})`
    subImage.style.backgroundImage=`url(${topid})`
        supeImage.style.backgroundImage=`url(${hanko})`
}else if(mainBuki=="zimwaipa") {
  mainImage.style.backgroundImage=`url(${zimwaipa})`
    subImage.style.backgroundImage=`url(${quickbom})`
        supeImage.style.backgroundImage=`url(${syoku})`
}else{
  mainImage.style.backgroundImage=`url(${supura2})`
    subImage.style.backgroundImage=`url(${supura2})`
        supeImage.style.backgroundImage=`url(${supura2})`
}

///ギア画像変更
///ギア選択のstate取得
var atamaMain =  this.state.atamaMainSelect;
var atamaSub1  =  this.state.atamaSub1Select;
var atamaSub2  =  this.state.atamaSub2Select;
var atamaSub3  =  this.state.atamaSub3Select;
var hukuMain  =  this.state.hukuMainSelect;
var hukuSub1  =  this.state.hukuSub1Select;
var hukuSub2  =  this.state.hukuSub2Select;
var hukuSub3  =  this.state.hukuSub3Select;
var kutuMain  =  this.state.kutuMainSelect;
var kutuSub1  =  this.state.kutuSub1Select;
var kutuSub2  =  this.state.kutuSub2Select;
var kutuSub3  =  this.state.kutuSub3Select;


var aM = this.atamaMainimg.current;
var aS1 = this.atamaSub1img.current;
var aS2 = this.atamaSub2img.current;
var aS3 = this.atamaSub3img.current;
var hM = this.hukuMainimg.current;
var hS1 = this.hukuSub1img.current;
var hS2 = this.hukuSub2img.current;
var hS3 = this.hukuSub3img.current;
var kM = this.kutuMainimg.current;
var kS1 = this.kutuSub1img.current;
var kS2 = this.kutuSub2img.current;
var kS3 = this.kutuSub3img.current;

if(atamaMain=="0"){
aM.style.backgroundImage = `url(${mainink})`
}else if(atamaMain=="1"){
  aM.style.backgroundImage = `url(${subink})`
}else if(atamaMain=="2"){
  aM.style.backgroundImage = `url(${inkkaihuku})`
}else if(atamaMain=="3"){
  aM.style.backgroundImage = `url(${hitosoku})`
}else if(atamaMain=="4"){
  aM.style.backgroundImage = `url(${ikasoku})`
}else if(atamaMain=="5"){
  aM.style.backgroundImage = `url(${supezou})`
}else if(atamaMain=="6"){
  aM.style.backgroundImage = `url(${supegen})`
}else if(atamaMain=="7"){
  aM.style.backgroundImage = `url(${supesei})`
}else if(atamaMain=="8"){
  aM.style.backgroundImage = `url(${huukatu})`
}else if(atamaMain=="9"){
  aM.style.backgroundImage = `url(${supazyan})`
}else if(atamaMain=="10"){
  aM.style.backgroundImage = `url(${subseinou})`
}else if(atamaMain=="11"){
  aM.style.backgroundImage = `url(${annzenkutu})`
}else if(atamaMain=="12"){
  aM.style.backgroundImage = `url(${subkeigen})`
}else if(atamaMain=="13"){
  aM.style.backgroundImage = `url(${action})`
}else if(atamaMain=="14"){
  aM.style.backgroundImage = `url(${startdush})`
}else if(atamaMain=="15"){
  aM.style.backgroundImage = `url(${lastspa})`
}else if(atamaMain=="16"){
  aM.style.backgroundImage = `url(${gyakkyou})`
}else if(atamaMain=="17"){
  aM.style.backgroundImage = `url(${kamback})`
}else if(atamaMain=="18"){
  aM.style.backgroundImage = `url(${ikaninnzya})`
}else if(atamaMain=="19"){
  aM.style.backgroundImage = `url(${ribenzi})`
}else if(atamaMain=="20"){
  aM.style.backgroundImage = `url(${sarmal})`
}else if(atamaMain=="21"){
  aM.style.backgroundImage = `url(${penaup})`
}else if(atamaMain=="22"){
  aM.style.backgroundImage = `url(${baika})`
}else if(atamaMain=="23"){
  aM.style.backgroundImage = `url(${suterusu})`
}else if(atamaMain=="24"){
  aM.style.backgroundImage = `url(${taibutu})`
}else if(atamaMain=="25"){
  aM.style.backgroundImage = `url(${ukemi})`
};

///アタマサブ１画像
if(atamaSub1=="0"){
aS1.style.backgroundImage = `url(${mainink})`
}else if(atamaSub1=="1"){
  aS1.style.backgroundImage = `url(${subink})`
}else if(atamaSub1=="2"){
  aS1.style.backgroundImage = `url(${inkkaihuku})`
}else if(atamaSub1=="3"){
  aS1.style.backgroundImage = `url(${hitosoku})`
}else if(atamaSub1=="4"){
  aS1.style.backgroundImage = `url(${ikasoku})`
}else if(atamaSub1=="5"){
  aS1.style.backgroundImage = `url(${supezou})`
}else if(atamaSub1=="6"){
  aS1.style.backgroundImage = `url(${supegen})`
}else if(atamaSub1=="7"){
  aS1.style.backgroundImage = `url(${supesei})`
}else if(atamaSub1=="8"){
  aS1.style.backgroundImage = `url(${huukatu})`
}else if(atamaSub1=="9"){
  aS1.style.backgroundImage = `url(${supazyan})`
}else if(atamaSub1=="10"){
  aS1.style.backgroundImage = `url(${subseinou})`
}else if(atamaSub1=="11"){
  aS1.style.backgroundImage = `url(${annzenkutu})`
}else if(atamaSub1=="12"){
  aS1.style.backgroundImage = `url(${subkeigen})`
}else if(atamaSub1=="13"){
  aS1.style.backgroundImage = `url(${action})`
}else if(atamaSub1=="14"){
  aS1.style.backgroundImage = `url(${startdush})`
}else if(atamaSub1=="15"){
  aS1.style.backgroundImage = `url(${lastspa})`
}else if(atamaSub1=="16"){
  aS1.style.backgroundImage = `url(${gyakkyou})`
}else if(atamaSub1=="17"){
  aS1.style.backgroundImage = `url(${kamback})`
}else if(atamaSub1=="18"){
  aS1.style.backgroundImage = `url(${ikaninnzya})`
}else if(atamaSub1=="19"){
  aS1.style.backgroundImage = `url(${ribenzi})`
}else if(atamaSub1=="20"){
  aS1.style.backgroundImage = `url(${sarmal})`
}else if(atamaSub1=="21"){
  aS1.style.backgroundImage = `url(${penaup})`
}else if(atamaSub1=="22"){
  aS1.style.backgroundImage = `url(${baika})`
}else if(atamaSub1=="23"){
  aS1.style.backgroundImage = `url(${suterusu})`
}else if(atamaSub1=="24"){
  aS1.style.backgroundImage = `url(${taibutu})`
}else if(atamaSub1=="25"){
  aS1.style.backgroundImage = `url(${ukemi})`
};

///アタマサブ２画像
if(atamaSub2=="0"){
aS2.style.backgroundImage = `url(${mainink})`
}else if(atamaSub2=="1"){
  aS2.style.backgroundImage = `url(${subink})`
}else if(atamaSub2=="2"){
  aS2.style.backgroundImage = `url(${inkkaihuku})`
}else if(atamaSub2=="3"){
  aS2.style.backgroundImage = `url(${hitosoku})`
}else if(atamaSub2=="4"){
  aS2.style.backgroundImage = `url(${ikasoku})`
}else if(atamaSub2=="5"){
  aS2.style.backgroundImage = `url(${supezou})`
}else if(atamaSub2=="6"){
  aS2.style.backgroundImage = `url(${supegen})`
}else if(atamaSub2=="7"){
  aS2.style.backgroundImage = `url(${supesei})`
}else if(atamaSub2=="8"){
  aS2.style.backgroundImage = `url(${huukatu})`
}else if(atamaSub2=="9"){
  aS2.style.backgroundImage = `url(${supazyan})`
}else if(atamaSub2=="10"){
  aS2.style.backgroundImage = `url(${subseinou})`
}else if(atamaSub2=="11"){
  aS2.style.backgroundImage = `url(${annzenkutu})`
}else if(atamaSub2=="12"){
  aS2.style.backgroundImage = `url(${subkeigen})`
}else if(atamaSub2=="13"){
  aS2.style.backgroundImage = `url(${action})`
}else if(atamaSub2=="14"){
  aS2.style.backgroundImage = `url(${startdush})`
}else if(atamaSub2=="15"){
  aS2.style.backgroundImage = `url(${lastspa})`
}else if(atamaSub2=="16"){
  aS2.style.backgroundImage = `url(${gyakkyou})`
}else if(atamaSub2=="17"){
  aS2.style.backgroundImage = `url(${kamback})`
}else if(atamaSub2=="18"){
  aS2.style.backgroundImage = `url(${ikaninnzya})`
}else if(atamaSub2=="19"){
  aS2.style.backgroundImage = `url(${ribenzi})`
}else if(atamaSub2=="20"){
  aS2.style.backgroundImage = `url(${sarmal})`
}else if(atamaSub2=="21"){
  aS2.style.backgroundImage = `url(${penaup})`
}else if(atamaSub2=="22"){
  aS2.style.backgroundImage = `url(${baika})`
}else if(atamaSub2=="23"){
  aS2.style.backgroundImage = `url(${suterusu})`
}else if(atamaSub2=="24"){
  aS2.style.backgroundImage = `url(${taibutu})`
}else if(atamaSub2=="25"){
  aS2.style.backgroundImage = `url(${ukemi})`
};

///アタマサブ3画像
if(atamaSub3=="0"){
aS3.style.backgroundImage = `url(${mainink})`
}else if(atamaSub3=="1"){
  aS3.style.backgroundImage = `url(${subink})`
}else if(atamaSub3=="2"){
  aS3.style.backgroundImage = `url(${inkkaihuku})`
}else if(atamaSub3=="3"){
  aS3.style.backgroundImage = `url(${hitosoku})`
}else if(atamaSub3=="4"){
  aS3.style.backgroundImage = `url(${ikasoku})`
}else if(atamaSub3=="5"){
  aS3.style.backgroundImage = `url(${supezou})`
}else if(atamaSub3=="6"){
  aS3.style.backgroundImage = `url(${supegen})`
}else if(atamaSub3=="7"){
  aS3.style.backgroundImage = `url(${supesei})`
}else if(atamaSub3=="8"){
  aS3.style.backgroundImage = `url(${huukatu})`
}else if(atamaSub3=="9"){
  aS3.style.backgroundImage = `url(${supazyan})`
}else if(atamaSub3=="10"){
  aS3.style.backgroundImage = `url(${subseinou})`
}else if(atamaSub3=="11"){
  aS3.style.backgroundImage = `url(${annzenkutu})`
}else if(atamaSub3=="12"){
  aS3.style.backgroundImage = `url(${subkeigen})`
}else if(atamaSub3=="13"){
  aS3.style.backgroundImage = `url(${action})`
}else if(atamaSub3=="14"){
  aS3.style.backgroundImage = `url(${startdush})`
}else if(atamaSub3=="15"){
  aS3.style.backgroundImage = `url(${lastspa})`
}else if(atamaSub3=="16"){
  aS3.style.backgroundImage = `url(${gyakkyou})`
}else if(atamaSub3=="17"){
  aS3.style.backgroundImage = `url(${kamback})`
}else if(atamaSub3=="18"){
  aS3.style.backgroundImage = `url(${ikaninnzya})`
}else if(atamaSub3=="19"){
  aS3.style.backgroundImage = `url(${ribenzi})`
}else if(atamaSub3=="20"){
  aS3.style.backgroundImage = `url(${sarmal})`
}else if(atamaSub3=="21"){
  aS3.style.backgroundImage = `url(${penaup})`
}else if(atamaSub3=="22"){
  aS3.style.backgroundImage = `url(${baika})`
}else if(atamaSub3=="23"){
  aS3.style.backgroundImage = `url(${suterusu})`
}else if(atamaSub3=="24"){
  aS3.style.backgroundImage = `url(${taibutu})`
}else if(atamaSub3=="25"){
  aS3.style.backgroundImage = `url(${ukemi})`
};

/// フクメイン画像
if(hukuMain=="0"){
hM.style.backgroundImage = `url(${mainink})`
}else if(hukuMain=="1"){
  hM.style.backgroundImage = `url(${subink})`
}else if(hukuMain=="2"){
  hM.style.backgroundImage = `url(${inkkaihuku})`
}else if(hukuMain=="3"){
  hM.style.backgroundImage = `url(${hitosoku})`
}else if(hukuMain=="4"){
  hM.style.backgroundImage = `url(${ikasoku})`
}else if(hukuMain=="5"){
  hM.style.backgroundImage = `url(${supezou})`
}else if(hukuMain=="6"){
  hM.style.backgroundImage = `url(${supegen})`
}else if(hukuMain=="7"){
  hM.style.backgroundImage = `url(${supesei})`
}else if(hukuMain=="8"){
  hM.style.backgroundImage = `url(${huukatu})`
}else if(hukuMain=="9"){
  hM.style.backgroundImage = `url(${supazyan})`
}else if(hukuMain=="10"){
  hM.style.backgroundImage = `url(${subseinou})`
}else if(hukuMain=="11"){
  hM.style.backgroundImage = `url(${annzenkutu})`
}else if(hukuMain=="12"){
  hM.style.backgroundImage = `url(${subkeigen})`
}else if(hukuMain=="13"){
  hM.style.backgroundImage = `url(${action})`
}else if(hukuMain=="14"){
  hM.style.backgroundImage = `url(${startdush})`
}else if(hukuMain=="15"){
  hM.style.backgroundImage = `url(${lastspa})`
}else if(hukuMain=="16"){
  hM.style.backgroundImage = `url(${gyakkyou})`
}else if(hukuMain=="17"){
  hM.style.backgroundImage = `url(${kamback})`
}else if(hukuMain=="18"){
  hM.style.backgroundImage = `url(${ikaninnzya})`
}else if(hukuMain=="19"){
  hM.style.backgroundImage = `url(${ribenzi})`
}else if(hukuMain=="20"){
  hM.style.backgroundImage = `url(${sarmal})`
}else if(hukuMain=="21"){
  hM.style.backgroundImage = `url(${penaup})`
}else if(hukuMain=="22"){
  hM.style.backgroundImage = `url(${baika})`
}else if(hukuMain=="23"){
  hM.style.backgroundImage = `url(${suterusu})`
}else if(hukuMain=="24"){
  hM.style.backgroundImage = `url(${taibutu})`
}else if(hukuMain=="25"){
  hM.style.backgroundImage = `url(${ukemi})`
};

/// フクサブ1画像
if(hukuSub1=="0"){
hS1.style.backgroundImage = `url(${mainink})`
}else if(hukuSub1=="1"){
  hS1.style.backgroundImage = `url(${subink})`
}else if(hukuSub1=="2"){
  hS1.style.backgroundImage = `url(${inkkaihuku})`
}else if(hukuSub1=="3"){
  hS1.style.backgroundImage = `url(${hitosoku})`
}else if(hukuSub1=="4"){
  hS1.style.backgroundImage = `url(${ikasoku})`
}else if(hukuSub1=="5"){
  hS1.style.backgroundImage = `url(${supezou})`
}else if(hukuSub1=="6"){
  hS1.style.backgroundImage = `url(${supegen})`
}else if(hukuSub1=="7"){
  hS1.style.backgroundImage = `url(${supesei})`
}else if(hukuSub1=="8"){
  hS1.style.backgroundImage = `url(${huukatu})`
}else if(hukuSub1=="9"){
  hS1.style.backgroundImage = `url(${supazyan})`
}else if(hukuSub1=="10"){
  hS1.style.backgroundImage = `url(${subseinou})`
}else if(hukuSub1=="11"){
  hS1.style.backgroundImage = `url(${annzenkutu})`
}else if(hukuSub1=="12"){
  hS1.style.backgroundImage = `url(${subkeigen})`
}else if(hukuSub1=="13"){
  hS1.style.backgroundImage = `url(${action})`
}else if(hukuSub1=="14"){
  hS1.style.backgroundImage = `url(${startdush})`
}else if(hukuSub1=="15"){
  hS1.style.backgroundImage = `url(${lastspa})`
}else if(hukuSub1=="16"){
  hS1.style.backgroundImage = `url(${gyakkyou})`
}else if(hukuSub1=="17"){
  hS1.style.backgroundImage = `url(${kamback})`
}else if(hukuSub1=="18"){
  hS1.style.backgroundImage = `url(${ikaninnzya})`
}else if(hukuSub1=="19"){
  hS1.style.backgroundImage = `url(${ribenzi})`
}else if(hukuSub1=="20"){
  hS1.style.backgroundImage = `url(${sarmal})`
}else if(hukuSub1=="21"){
  hS1.style.backgroundImage = `url(${penaup})`
}else if(hukuSub1=="22"){
  hS1.style.backgroundImage = `url(${baika})`
}else if(hukuSub1=="23"){
  hS1.style.backgroundImage = `url(${suterusu})`
}else if(hukuSub1=="24"){
  hS1.style.backgroundImage = `url(${taibutu})`
}else if(hukuSub1=="25"){
  hS1.style.backgroundImage = `url(${ukemi})`
};

/// フクサブ2画像
if(hukuSub2=="0"){
hS2.style.backgroundImage = `url(${mainink})`
}else if(hukuSub2=="1"){
  hS2.style.backgroundImage = `url(${subink})`
}else if(hukuSub2=="2"){
  hS2.style.backgroundImage = `url(${inkkaihuku})`
}else if(hukuSub2=="3"){
  hS2.style.backgroundImage = `url(${hitosoku})`
}else if(hukuSub2=="4"){
  hS2.style.backgroundImage = `url(${ikasoku})`
}else if(hukuSub2=="5"){
  hS2.style.backgroundImage = `url(${supezou})`
}else if(hukuSub2=="6"){
  hS2.style.backgroundImage = `url(${supegen})`
}else if(hukuSub2=="7"){
  hS2.style.backgroundImage = `url(${supesei})`
}else if(hukuSub2=="8"){
  hS2.style.backgroundImage = `url(${huukatu})`
}else if(hukuSub2=="9"){
  hS2.style.backgroundImage = `url(${supazyan})`
}else if(hukuSub2=="10"){
  hS2.style.backgroundImage = `url(${subseinou})`
}else if(hukuSub2=="11"){
  hS2.style.backgroundImage = `url(${annzenkutu})`
}else if(hukuSub2=="12"){
  hS2.style.backgroundImage = `url(${subkeigen})`
}else if(hukuSub2=="13"){
  hS2.style.backgroundImage = `url(${action})`
}else if(hukuSub2=="14"){
  hS2.style.backgroundImage = `url(${startdush})`
}else if(hukuSub2=="15"){
  hS2.style.backgroundImage = `url(${lastspa})`
}else if(hukuSub2=="16"){
  hS2.style.backgroundImage = `url(${gyakkyou})`
}else if(hukuSub2=="17"){
  hS2.style.backgroundImage = `url(${kamback})`
}else if(hukuSub2=="18"){
  hS2.style.backgroundImage = `url(${ikaninnzya})`
}else if(hukuSub2=="19"){
  hS2.style.backgroundImage = `url(${ribenzi})`
}else if(hukuSub2=="20"){
  hS2.style.backgroundImage = `url(${sarmal})`
}else if(hukuSub2=="21"){
  hS2.style.backgroundImage = `url(${penaup})`
}else if(hukuSub2=="22"){
  hS2.style.backgroundImage = `url(${baika})`
}else if(hukuSub2=="23"){
  hS2.style.backgroundImage = `url(${suterusu})`
}else if(hukuSub2=="24"){
  hS2.style.backgroundImage = `url(${taibutu})`
}else if(hukuSub2=="25"){
  hS2.style.backgroundImage = `url(${ukemi})`
};

/// フクサブ3画像
if(hukuSub3=="0"){
hS3.style.backgroundImage = `url(${mainink})`
}else if(hukuSub3=="1"){
  hS3.style.backgroundImage = `url(${subink})`
}else if(hukuSub3=="2"){
  hS3.style.backgroundImage = `url(${inkkaihuku})`
}else if(hukuSub3=="3"){
  hS3.style.backgroundImage = `url(${hitosoku})`
}else if(hukuSub3=="4"){
  hS3.style.backgroundImage = `url(${ikasoku})`
}else if(hukuSub3=="5"){
  hS3.style.backgroundImage = `url(${supezou})`
}else if(hukuSub3=="6"){
  hS3.style.backgroundImage = `url(${supegen})`
}else if(hukuSub3=="7"){
  hS3.style.backgroundImage = `url(${supesei})`
}else if(hukuSub3=="8"){
  hS3.style.backgroundImage = `url(${huukatu})`
}else if(hukuSub3=="9"){
  hS3.style.backgroundImage = `url(${supazyan})`
}else if(hukuSub3=="10"){
  hS3.style.backgroundImage = `url(${subseinou})`
}else if(hukuSub3=="11"){
  hS3.style.backgroundImage = `url(${annzenkutu})`
}else if(hukuSub3=="12"){
  hS3.style.backgroundImage = `url(${subkeigen})`
}else if(hukuSub3=="13"){
  hS3.style.backgroundImage = `url(${action})`
}else if(hukuSub3=="14"){
  hS3.style.backgroundImage = `url(${startdush})`
}else if(hukuSub3=="15"){
  hS3.style.backgroundImage = `url(${lastspa})`
}else if(hukuSub3=="16"){
  hS3.style.backgroundImage = `url(${gyakkyou})`
}else if(hukuSub3=="17"){
  hS3.style.backgroundImage = `url(${kamback})`
}else if(hukuSub3=="18"){
  hS3.style.backgroundImage = `url(${ikaninnzya})`
}else if(hukuSub3=="19"){
  hS3.style.backgroundImage = `url(${ribenzi})`
}else if(hukuSub3=="20"){
  hS3.style.backgroundImage = `url(${sarmal})`
}else if(hukuSub3=="21"){
  hS3.style.backgroundImage = `url(${penaup})`
}else if(hukuSub3=="22"){
  hS3.style.backgroundImage = `url(${baika})`
}else if(hukuSub3=="23"){
  hS3.style.backgroundImage = `url(${suterusu})`
}else if(hukuSub3=="24"){
  hS3.style.backgroundImage = `url(${taibutu})`
}else if(hukuSub3=="25"){
  hS3.style.backgroundImage = `url(${ukemi})`
};

/// クツメイン画像
if(kutuMain=="0"){
kM.style.backgroundImage = `url(${mainink})`
}else if(kutuMain=="1"){
  kM.style.backgroundImage = `url(${subink})`
}else if(kutuMain=="2"){
  kM.style.backgroundImage = `url(${inkkaihuku})`
}else if(kutuMain=="3"){
  kM.style.backgroundImage = `url(${hitosoku})`
}else if(kutuMain=="4"){
  kM.style.backgroundImage = `url(${ikasoku})`
}else if(kutuMain=="5"){
  kM.style.backgroundImage = `url(${supezou})`
}else if(kutuMain=="6"){
  kM.style.backgroundImage = `url(${supegen})`
}else if(kutuMain=="7"){
  kM.style.backgroundImage = `url(${supesei})`
}else if(kutuMain=="8"){
  kM.style.backgroundImage = `url(${huukatu})`
}else if(kutuMain=="9"){
  kM.style.backgroundImage = `url(${supazyan})`
}else if(kutuMain=="10"){
  kM.style.backgroundImage = `url(${subseinou})`
}else if(kutuMain=="11"){
  kM.style.backgroundImage = `url(${annzenkutu})`
}else if(kutuMain=="12"){
  kM.style.backgroundImage = `url(${subkeigen})`
}else if(kutuMain=="13"){
  kM.style.backgroundImage = `url(${action})`
}else if(kutuMain=="14"){
  kM.style.backgroundImage = `url(${startdush})`
}else if(kutuMain=="15"){
  kM.style.backgroundImage = `url(${lastspa})`
}else if(kutuMain=="16"){
  kM.style.backgroundImage = `url(${gyakkyou})`
}else if(kutuMain=="17"){
  kM.style.backgroundImage = `url(${kamback})`
}else if(kutuMain=="18"){
  kM.style.backgroundImage = `url(${ikaninnzya})`
}else if(kutuMain=="19"){
  kM.style.backgroundImage = `url(${ribenzi})`
}else if(kutuMain=="20"){
  kM.style.backgroundImage = `url(${sarmal})`
}else if(kutuMain=="21"){
  kM.style.backgroundImage = `url(${penaup})`
}else if(kutuMain=="22"){
  kM.style.backgroundImage = `url(${baika})`
}else if(kutuMain=="23"){
  kM.style.backgroundImage = `url(${suterusu})`
}else if(kutuMain=="24"){
  kM.style.backgroundImage = `url(${taibutu})`
}else if(kutuMain=="25"){
  kM.style.backgroundImage = `url(${ukemi})`
};

/// クツサブ1画像
if(kutuSub1=="0"){
kS1.style.backgroundImage = `url(${mainink})`
}else if(kutuSub1=="1"){
  kS1.style.backgroundImage = `url(${subink})`
}else if(kutuSub1=="2"){
  kS1.style.backgroundImage = `url(${inkkaihuku})`
}else if(kutuSub1=="3"){
  kS1.style.backgroundImage = `url(${hitosoku})`
}else if(kutuSub1=="4"){
  kS1.style.backgroundImage = `url(${ikasoku})`
}else if(kutuSub1=="5"){
  kS1.style.backgroundImage = `url(${supezou})`
}else if(kutuSub1=="6"){
  kS1.style.backgroundImage = `url(${supegen})`
}else if(kutuSub1=="7"){
  kS1.style.backgroundImage = `url(${supesei})`
}else if(kutuSub1=="8"){
  kS1.style.backgroundImage = `url(${huukatu})`
}else if(kutuSub1=="9"){
  kS1.style.backgroundImage = `url(${supazyan})`
}else if(kutuSub1=="10"){
  kS1.style.backgroundImage = `url(${subseinou})`
}else if(kutuSub1=="11"){
  kS1.style.backgroundImage = `url(${annzenkutu})`
}else if(kutuSub1=="12"){
  kS1.style.backgroundImage = `url(${subkeigen})`
}else if(kutuSub1=="13"){
  kS1.style.backgroundImage = `url(${action})`
}else if(kutuSub1=="14"){
  kS1.style.backgroundImage = `url(${startdush})`
}else if(kutuSub1=="15"){
  kS1.style.backgroundImage = `url(${lastspa})`
}else if(kutuSub1=="16"){
  kS1.style.backgroundImage = `url(${gyakkyou})`
}else if(kutuSub1=="17"){
  kS1.style.backgroundImage = `url(${kamback})`
}else if(kutuSub1=="18"){
  kS1.style.backgroundImage = `url(${ikaninnzya})`
}else if(kutuSub1=="19"){
  kS1.style.backgroundImage = `url(${ribenzi})`
}else if(kutuSub1=="20"){
  kS1.style.backgroundImage = `url(${sarmal})`
}else if(kutuSub1=="21"){
  kS1.style.backgroundImage = `url(${penaup})`
}else if(kutuSub1=="22"){
  kS1.style.backgroundImage = `url(${baika})`
}else if(kutuSub1=="23"){
  kS1.style.backgroundImage = `url(${suterusu})`
}else if(kutuSub1=="24"){
  kS1.style.backgroundImage = `url(${taibutu})`
}else if(kutuSub1=="25"){
  kS1.style.backgroundImage = `url(${ukemi})`
};

/// クツサブ2画像
if(kutuSub2=="0"){
kS2.style.backgroundImage = `url(${mainink})`
}else if(kutuSub2=="1"){
  kS2.style.backgroundImage = `url(${subink})`
}else if(kutuSub2=="2"){
  kS2.style.backgroundImage = `url(${inkkaihuku})`
}else if(kutuSub2=="3"){
  kS2.style.backgroundImage = `url(${hitosoku})`
}else if(kutuSub2=="4"){
  kS2.style.backgroundImage = `url(${ikasoku})`
}else if(kutuSub2=="5"){
  kS2.style.backgroundImage = `url(${supezou})`
}else if(kutuSub2=="6"){
  kS2.style.backgroundImage = `url(${supegen})`
}else if(kutuSub2=="7"){
  kS2.style.backgroundImage = `url(${supesei})`
}else if(kutuSub2=="8"){
  kS2.style.backgroundImage = `url(${huukatu})`
}else if(kutuSub2=="9"){
  kS2.style.backgroundImage = `url(${supazyan})`
}else if(kutuSub2=="10"){
  kS2.style.backgroundImage = `url(${subseinou})`
}else if(kutuSub2=="11"){
  kS2.style.backgroundImage = `url(${annzenkutu})`
}else if(kutuSub2=="12"){
  kS2.style.backgroundImage = `url(${subkeigen})`
}else if(kutuSub2=="13"){
  kS2.style.backgroundImage = `url(${action})`
}else if(kutuSub2=="14"){
  kS2.style.backgroundImage = `url(${startdush})`
}else if(kutuSub2=="15"){
  kS2.style.backgroundImage = `url(${lastspa})`
}else if(kutuSub2=="16"){
  kS2.style.backgroundImage = `url(${gyakkyou})`
}else if(kutuSub2=="17"){
  kS2.style.backgroundImage = `url(${kamback})`
}else if(kutuSub2=="18"){
  kS2.style.backgroundImage = `url(${ikaninnzya})`
}else if(kutuSub2=="19"){
  kS2.style.backgroundImage = `url(${ribenzi})`
}else if(kutuSub2=="20"){
  kS2.style.backgroundImage = `url(${sarmal})`
}else if(kutuSub2=="21"){
  kS2.style.backgroundImage = `url(${penaup})`
}else if(kutuSub2=="22"){
  kS2.style.backgroundImage = `url(${baika})`
}else if(kutuSub2=="23"){
  kS2.style.backgroundImage = `url(${suterusu})`
}else if(kutuSub2=="24"){
  kS2.style.backgroundImage = `url(${taibutu})`
}else if(kutuSub2=="25"){
  kS2.style.backgroundImage = `url(${ukemi})`
};

/// クツサブ3画像
if(kutuSub3=="0"){
kS3.style.backgroundImage = `url(${mainink})`
}else if(kutuSub3=="1"){
  kS3.style.backgroundImage = `url(${subink})`
}else if(kutuSub3=="2"){
  kS3.style.backgroundImage = `url(${inkkaihuku})`
}else if(kutuSub3=="3"){
  kS3.style.backgroundImage = `url(${hitosoku})`
}else if(kutuSub3=="4"){
  kS3.style.backgroundImage = `url(${ikasoku})`
}else if(kutuSub3=="5"){
  kS3.style.backgroundImage = `url(${supezou})`
}else if(kutuSub3=="6"){
  kS3.style.backgroundImage = `url(${supegen})`
}else if(kutuSub3=="7"){
  kS3.style.backgroundImage = `url(${supesei})`
}else if(kutuSub3=="8"){
  kS3.style.backgroundImage = `url(${huukatu})`
}else if(kutuSub3=="9"){
  kS3.style.backgroundImage = `url(${supazyan})`
}else if(kutuSub3=="10"){
  kS3.style.backgroundImage = `url(${subseinou})`
}else if(kutuSub3=="11"){
  kS3.style.backgroundImage = `url(${annzenkutu})`
}else if(kutuSub3=="12"){
  kS3.style.backgroundImage = `url(${subkeigen})`
}else if(kutuSub3=="13"){
  kS3.style.backgroundImage = `url(${action})`
}else if(kutuSub3=="14"){
  kS3.style.backgroundImage = `url(${startdush})`
}else if(kutuSub3=="15"){
  kS3.style.backgroundImage = `url(${lastspa})`
}else if(kutuSub3=="16"){
  kS3.style.backgroundImage = `url(${gyakkyou})`
}else if(kutuSub3=="17"){
  kS3.style.backgroundImage = `url(${kamback})`
}else if(kutuSub3=="18"){
  kS3.style.backgroundImage = `url(${ikaninnzya})`
}else if(kutuSub3=="19"){
  kS3.style.backgroundImage = `url(${ribenzi})`
}else if(kutuSub3=="20"){
  kS3.style.backgroundImage = `url(${sarmal})`
}else if(kutuSub3=="21"){
  kS3.style.backgroundImage = `url(${penaup})`
}else if(kutuSub3=="22"){
  kS3.style.backgroundImage = `url(${baika})`
}else if(kutuSub3=="23"){
  kS3.style.backgroundImage = `url(${suterusu})`
}else if(kutuSub3=="24"){
  kS3.style.backgroundImage = `url(${taibutu})`
}else if(kutuSub3=="25"){
  kS3.style.backgroundImage = `url(${ukemi})`
};




}

 handleSubmit(event){



   const target = event.target;

   const name = target.name;
   this.setState({[name]: target.value})

 event.preventDefault();




//// 以下supulatoon3
var l3 = { 'mainseinou': '', 'mainink': 1.15, 'supe': 'カニタンク', 'supepo': 200, 'sub': 'カーリングボム', 'hitosoku': 0.80 ,'jump':0.4,'DegBiasMin':0.01,'DegBiasMax':"",'DegBiasKf':0.01  };
var h3 = { 'mainseinou': '', 'mainink': 2.25, 'supe': 'エナジースタンド', 'supepo': 200, 'sub': 'ポイントセンサー', 'hitosoku': 0.60,'jump':0.4,'DegBiasMin':0.01,'DegBiasMax':"",'DegBiasKf':0.01  };
var botoru = { 'mainseinou': '', 'mainink': 2.2, 'supe': 'ウルトラショット', 'supepo': 200, 'sub': 'スプラッシュシールド', 'hitosoku': 0.72 ,'jump':0.4 ,'DegBiasMin':0.01,'DegBiasMax':"",'DegBiasKf':0.01 };
var duaru = { 'mainseinou': '', 'mainink': 1.2, 'supe': 'ホップソナー', 'supepo': 200, 'sub': 'スプラッシュボム', 'hitosoku': 0.72, 'suraido': 8 ,'jump':0.4,'DegBiasMin':0.04,'DegBiasMax':0.3,'DegBiasKf':0.02 };
var take = { 'mainseinou': '', 'mainink': 8.4,'minmainink': 3.36,  'supe': 'メガホンレーザー', 'supepo': 200, 'sub': 'ロボットボム', 'hitosoku': 0.60 };
var garon96 = { 'mainseinou': '', 'mainink': 2.5, 'supe': 'キューインキ', 'supepo': 200, 'sub': 'スプリンクラー', 'hitosoku': 0.40,'jump':0.4 ,'DegBiasMin':0.04,'DegBiasMax':0.3,'DegBiasKf':0.03};
var borudo = { 'mainseinou': '', 'mainink': 0.8, 'supe': 'ウルトラハンコ', 'supepo': 180, 'sub': 'カーリングボム', 'hitosoku': 0.72,'jump':0.4 ,'DegBiasMin':0.01,'DegBiasMax':"",'DegBiasKf':0.01 };
var botoruren = { 'mainseinou': '', 'mainink': 1.08, 'supe': 'ハイプレorバブル', 'supepo': 200, 'sub': 'スプラッシュシールド', 'hitosoku': 0.72  ,'jump':0.4 ,'DegBiasMin':0.01,'DegBiasMax':"",'DegBiasKf':0.01 };
var long = { 'mainseinou': '', 'mainink': 11, 'supe': 'ホップソナー', 'supepo': 200, 'sub': 'キューバンボム', 'hitosoku': 0.40  ,'jump':0.5 ,'DegBiasMin':0,'DegBiasMax':0,'DegBiasKf':0 };
var rapi = { 'mainseinou': '', 'mainink': 7, 'supe': 'トリプルトルネード', 'supepo': 200, 'sub': 'トラップ', 'hitosoku': 0.55  ,'jump':0.5 ,'DegBiasMin':0,'DegBiasMax':0,'DegBiasKf':0 };
var rapieri = { 'mainseinou': '', 'mainink': 8, 'supe': 'キューインキ', 'supepo': 180, 'sub': 'ポイズンミスト', 'hitosoku': 0.55  ,'jump':0.5 ,'DegBiasMin':0,'DegBiasMax':0,'DegBiasKf':0 };
var kura = { 'mainseinou': '', 'mainink': 4, 'supe': 'ウルトラショット', 'supepo': 180, 'sub': 'スプラッシュボム', 'hitosoku': 0.65,'jump':0.5 ,'DegBiasMin':0,'DegBiasMax':0,'DegBiasKf':0  };
var kabon = { 'mainseinou': '', 'mainink': 3.96, 'supe': 'ショクワンダー', 'supepo': 180, 'sub': 'ロボットボム', 'hitosoku': 1.04 };
var varia = { 'mainseinou': '', 'mainink': 8, 'supe': 'マルチミサイル', 'supepo': 200, 'sub': 'トラップ', 'hitosoku': 0.72 };
var variatate = { 'mainseinou': '', 'mainink': 12, 'supe': 'マルチミサイル', 'supepo':200, 'sub': 'トラップ', 'hitosoku': 0.80 };
var soi = { 'mainseinou': '', 'mainink': 15,'minmainink': 2, 'supe': 'マルチミサイル', 'supepo': 200, 'sub': 'トーピード', 'hitosoku': 0.30, 'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.31 };
var sukuiku = { 'mainseinou': '', 'mainink': 10.5,'minmainink': 1.8667, 'supe': 'グレートバリア', 'supepo': 190, 'sub': 'ポイントセンサー', 'hitosoku': 0.30, 'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.31 };
var sukusuro = { 'mainseinou': '', 'mainink': 8.4, 'supe': 'ナイスダマ', 'supepo': 200, 'sub': 'タンサンボム', 'hitosoku': 0.7 };
var ofuro = { 'mainseinou': '', 'mainink': 8, 'supe': 'アメフラシ', 'supepo': 180, 'sub': 'スプリンクラー', 'hitosoku': 0.5 };
var ex = { 'mainseinou': '', 'mainink': 11.7, 'supe': 'アメフラシ', 'supepo': 200, 'sub': 'ポイントセンサー', 'hitosoku': 0.45 };
var supusupi = { 'mainseinou': '', 'mainink': 0.9, 'supe': 'ウルトラハンコ', 'supepo': 180, 'sub': 'クイックボム', 'hitosoku': 0.86, 'tyazihitosoku': 0.72,'jump':0.3 ,'DegBiasMin':0.1,'DegBiasMax':0.3};
var kugel = { 'mainseinou': '', 'mainink': 0.3125, 'supe': 'ジェットパック', 'supepo': 200, 'sub': 'タンサンボム', 'hitosoku': 0.86, 'tyazihitosoku': 0.86 ,'jump':0.4 ,'DegBiasMin':0.1,'DegBiasMax':0.4};
var bareru = { 'mainseinou': '', 'mainink': 0.625, 'supe': 'ホップソナー', 'supepo': 200, 'sub': 'スプリンクラー', 'hitosoku': 0.45, 'tyazihitosoku': 0.62 };
var kugeltyou = { 'mainseinou': '', 'mainink': 0.625, 'supe': 'ジェットパック', 'supepo': 200, 'sub': 'タンサンボム', 'hitosoku': 0.50, 'tyazihitosoku': 0.86 };
var syapu = { 'mainseinou': '', 'mainink': 0.8, 'supe': 'カニタンク', 'supepo': 200, 'sub': 'クイックボム', 'hitosoku': 0.72 };
var nooti = { 'mainseinou': '', 'mainink': 0.55, 'supe': 'アメフラシ', 'supepo': 190, 'sub': 'ポイントセンサー', 'hitosoku': 0.70, 'tyazihitosoku': 0.40 };
var supa = { 'mainseinou': '', 'mainink': 0.663, 'supe': 'エナジースタンド', 'supepo': 180, 'sub': 'ジャンプビーコン', 'hitosoku': 0.80, 'suraido': 5 ,'jump':0.4 ,'DegBiasMin':0.05,'DegBiasMax':0.4,'DegBiasKf':0.03};
var kerubin = { 'mainseinou': '', 'mainink': 1.4, 'supe': 'ナイスダマ', 'supepo': 180, 'sub': 'スプラッシュシールド', 'hitosoku': 0.60, 'suraido': 8 ,'jump':0.4 ,'DegBiasMin':0.03 , 'DegBiasKf':0.03};
var supai = { 'mainseinou': '', 'mainink': 4, 'supe': 'サメライド', 'supepo': 180, 'sub': 'トラップ', 'hitosoku': 0.72, 'gardhitosoku': 0.72 };

var supura = { 'mainseinou': '', 'mainink': 0.92, 'supe': 'ウルトラショット', 'supepo': 200, 'sub': 'キューバンボム', 'hitosoku': 0.72 ,'jump':0.4};
var prime = { 'mainseinou': '', 'mainink': 2, 'supe': 'カニタンク', 'supepo': 200, 'sub': 'ラインマーカー', 'hitosoku': 0.55 };
   var kuaddo = { 'mainseinou': '', 'mainink': 0.8, 'supe': 'サメライド', 'supepo': 200, 'sub': 'ロボットボム', 'hitosoku': 0.72, 'suraido': 3.0 };
 var manyu = { 'mainseinou': '', 'mainink': 0.722, 'supe': 'カニタンク', 'supepo': 200, 'sub': 'キューバンボム', 'hitosoku': 0.80, 'suraido': 7.0 };
var haidora = { 'mainseinou': '', 'mainink': 0.57, 'supe': 'ナイスダマ', 'supepo': 190, 'sub': 'ロボットボム', 'hitosoku': 0.60, 'tyazihitosoku': 0.40 };
   var nova = { 'mainseinou': '', 'mainink': 7.5, 'supe': 'ショクワンダー', 'supepo': 180, 'sub': 'スプラッシュボム', 'hitosoku': 0.50 };
   var dainamo = { 'mainseinou': '', 'mainink': 18, 'supe': 'エナジースタンド', 'supepo': 190, 'sub': 'スプリンクラー', 'hitosoku': 0.88 };
 var ritta = { 'mainseinou': '', 'mainink': 25, 'supe': 'ポップソナー', 'supepo': 200, 'sub': 'トラップ', 'hitosoku': 0.15, 'tyazihitosoku': 0.88, 'hantyazihitosoku': 0.16 };
 var rittasuko = { 'mainseinou': '', 'mainink': 25, 'supe': 'ポップソナー', 'supepo': 200, 'sub': 'トラップ', 'hitosoku': 0.15, 'tyazihitosoku': 0.88, 'hantyazihitosoku': 0.16 };
var kyanpu = { 'mainseinou': '', 'mainink': 11, 'supe': 'キューインキ', 'supepo': 190, 'sub': 'ジャンプビーコン', 'hitosoku': 0.88 };
   var wakaba = { 'mainseinou': '', 'mainink': 0.45454545, 'supe': 'グレートバリア', 'supepo': 180, 'sub': 'スプラッシュボム', 'hitosoku': 0.72 };
   var garon52 = { 'mainseinou': '', 'mainink': 1.3, 'supe': 'メガホンレーザー', 'supepo': 200, 'sub': 'スプラッシュシールド', 'hitosoku': 0.60 };
   var zap = { 'mainseinou': '', 'mainink': 0.8, 'supe': 'エナジースタンド', 'supepo': 200, 'sub': 'キューバンボム', 'hitosoku': 0.84 };
 var modera = { 'mainseinou': '', 'mainink': 0.55, 'supe': 'サメライド', 'supepo': 200, 'sub': 'タンサンボム', 'hitosoku': 0.72 };
 var hot = { 'mainseinou': '', 'mainink': 10, 'supe': 'グレートバリア', 'supepo': 180, 'sub': 'ロボットボム', 'hitosoku': 0.45 };
   var rola = { 'mainseinou': '', 'mainink': 8.55, 'supe': 'グレートバリア', 'supepo': 180, 'sub': 'カーリングボム', 'hitosoku': 0.96 };
 var paburo = { 'mainseinou': '', 'mainink': 2, 'supe': 'メガホンレーザー', 'supepo': 180, 'sub': 'スプラッシュボム', 'hitosoku': 1.04 };
   var hokusai = { 'mainseinou': '', 'mainink': 3.2, 'supe': 'ショクワンダー', 'supepo': 200, 'sub': 'キューバンボム', 'hitosoku': 0.96 };
   var tyazya = { 'mainseinou': '', 'mainink': 18, 'supe': 'キューインキ', 'supepo': 200, 'sub': 'スプラッシュボム', 'hitosoku': 0.20, 'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.21 };
 var tyazyasuko = { 'mainseinou': '', 'mainink': 18, 'supe': 'キューインキ', 'supepo': 200, 'sub': 'スプラッシュボム', 'hitosoku': 0.20, 'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.21 };
   var bake = { 'mainseinou': '', 'mainink': 7, 'supe': 'トリプルトルネード', 'supepo': 200, 'sub': 'スプラッシュボム', 'hitosoku': 0.40 };
   var hissen = { 'mainseinou': '', 'mainink': 6, 'supe': 'ジェットパック', 'supepo': 190, 'sub': 'ポイズンミスト', 'hitosoku': 0.66 };
   var bareru= { 'mainseinou': '', 'mainink': 0.625, 'supe': 'ポップソナー', 'supepo': 200, 'sub': 'スプリンクラー', 'hitosoku': 0.45, 'tyazihitosoku': 0.62 };
var para = { 'mainseinou': '', 'mainink': 6.325, 'supe': 'トリプルトルネード', 'supepo': 200, 'sub': 'スプリンクラー', 'hitosoku': 0.60, 'gardhitosoku': 0.40 };
var lact = { 'mainseinou': '', 'mainink': 6.5, 'supe': 'マルチミサイル', 'supepo': 200, 'sub': 'カーリングボム', 'hitosoku': 0.96,  };

 var sutori = { 'mainseinou': '', 'mainink': 8.5, 'supe': 'メガホンレーザー', 'supepo': 200, 'sub': 'ポイズンミスト', 'hitosoku':  0.20,  'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.21 };
var   waipa = { 'mainseinou': '', 'mainink': 3, 'supe': ' ウルトラハンコ', 'supepo': 180, 'sub': 'トーピード', 'hitosoku': 0.96, 'gardhitosoku': 0.96 };
var zimwaipa ={'mainseinou': '', 'mainink': 4, 'supe': ' ショクワンダー', 'supepo': 200, 'sub': 'クイックボム', 'hitosoku': 0.72, 'gardhitosoku': 0.72 }



   var スプラッシュボム = { 'ink': 70, 'subseinou': '飛距離アップ' };
   var キューバンボム = { 'ink': 70, 'subseinou': '飛距離アップ' };
   var クイックボム = { 'ink': 40, 'subseinou': '飛距離アップ&弾速アップ' };
   var カーリングボム = { 'ink': 70, 'subseinou': '飛距離アップ' };
   var ロボットボム = { 'ink': 55, 'subseinou': '飛距離アップ' };
   var タンサンボム = { 'ink': 60, 'subseinou': '飛距離アップ' };
   var トーピード = { 'ink': 65, 'subseinou': '飛距離アップ' };
   var トラップ = { 'ink': 60, 'subseinou': '範囲アップ&効果時間アップ' };
   var ポイズンミスト = { 'ink': 60, 'subseinou': '飛距離アップ' };
   var ポイントセンサー = { 'ink': 45, 'subseinou': '飛距離アップ&効果時間アップ' };
   var スプラッシュシールド = { 'ink': 60, 'subseinou': '耐久力アップ' };
   var ジャンプビーコン = { 'ink': 75, 'subseinou': 'ジャンプ時間短縮' };
   var スプリンクラー = { 'ink': 60, 'subseinou': '散布速度減衰の遅延' };
      var   ラインマーカー = { 'ink': 60, 'subseinou': '' };

   var ハイパープレッサー = { 'supeseinou': '発動時間延長' };
   var スーパーチャクチ = { 'supeseinou': 'ダメージ範囲増加' };
   var スプラッシュボムピッチャー = { 'supeseinou': 'ボム連投数増加' };
   var キューバンボムピッチャー = { 'supeseinou': 'ボム連投数増加' };
   var クイックボムピッチャー = { 'supeseinou': 'ボム連投数増加' };
   var ロボボムピッチャー = { 'supeseinou': 'ボム連投数増加' };
   var カーリングボムピッチャー = { 'supeseinou': 'ボム連投数増加' };
   var ジェットパック = { 'supeseinou': '発動時間,爆風範囲増加' };
   var インクアーマー = { 'supeseinou': 'アーマー装着までの時間短縮' };
   var アメフラシ = { 'supeseinou': '発動時間増加,投げる距離増加' };
   var マルチミサイル = { 'supeseinou': 'ロックオン範囲,着弾塗り範囲増加' };
   var バブルランチャー = { 'supeseinou': '爆風半径増加' };
   var ナイスダマ = { 'supeseinou': '溜まるまでの時間短縮' };
   var ウルトラハンコ = { 'supeseinou': '発動時間増加' };
   var イカスフィア = { 'supeseinou': '耐久力アップ,爆風半径増加' };
   var グレートバリア = { 'supeseinou': '耐久値アップ' };
    var ウルトラショット = { 'supeseinou': '塗り・ダメージ範囲、スペシャル時間アップ' };
 var サメライド = { 'supeseinou': '速度・塗り半径アップ' };
  var ショクワンダー = { 'supeseinou': 'インク消費量減少' };
   var カニタンク = { 'supeseinou': 'スペシャル時間アップ' };
    var エナジースタンド = { 'supeseinou': 'パワーアップ時間延長' };
   var メガホンレーザー = { 'supeseinou': 'レーザー持続時間延長' };
      var トリプルトルネード = { 'supeseinou': '発生スピードアップ' };
         var ホップソナー = { 'supeseinou': 'ソナー範囲増加' };
            var キューインキ = { 'supeseinou': '吸引範囲アップ' };


//ブキ選択後のoptionvalue取り出し
var buki = this.state.buki;




//各ギア数を取り出し
var maininkmainG = this.state.main;
var meininksubG = this.state.inksub;
var seinoumainG = this.state.meseimain;
var seinousubG = this.state.meseisub;
var subinkmainG = this.state.submain;
var subinksubG =  this.state.subsub;
var supemainG = this.state.supemain;
var supesubG = this.state.supesub;
var kaihukumainG = this.state.kaimain;
var kaihukusubG = this.state.kaisub;

//ギアのGP割り出し
const total = parseInt(maininkmainG) + parseInt(meininksubG);
var seinoutotal = parseInt(seinoumainG) + parseInt(seinousubG);
var subinktotal = parseInt(subinkmainG) + parseInt(subinksubG);
var supetotal = parseInt(supemainG) + parseInt(supesubG);
var kaihukutotal = parseInt(kaihukumainG) + parseInt(kaihukusubG);

//選択したブキのパラメーター取り出し
///splatooon2
var supept = eval(buki)['supepo'];
var subue = eval(buki)['sub'];
var subink = eval(subue)['ink'];
var supemei =  eval(buki)['supe'];
var subsei = eval(subue)['subseinou'];



//サブ名、スペシャル名を出力


this.state.submei = subue;
this.state.supe = supemei;
this.state.subseinou = subsei;



//インク回復量計算
var hitom = (600 - 380 * (0.033 * kaihukutotal - 0.00027 * kaihukutotal ** 2)) / 60;
var ikam = (180 - 63 * (0.033 * kaihukutotal - 0.00027 * kaihukutotal ** 2)) / 60;




//サブインク消費量・連投数処理始め
if (subue == "ジャンプビーコン" || subue == "スプリンクラー" || subue == "トラップ") {
    var G1 = 1 - 0.4 * (0.033 * subinktotal - 0.00027 * subinktotal ** 2)
    var G1syouhi = subink * G1;
    if (G1 >= 0.60) {
      this.state.subpasent = Math.round(G1syouhi * 10) / 10 + "%";
      var subsuu = 100 / G1syouhi;
      this.state.subren = Math.floor(subsuu);
    } else {
      var G1 = 0.60
      var G1syouhi = subink * G1;
      this.state.subpasent = Math.round(G1syouhi * 10) / 10 + "%";
      var subsuu = 100 / G1syouhi;
      this.state.subren = Math.floor(subsuu);
    }
  } else if (subue == "スプラッシュボム" || subue == "キューバンボム" || subue == "カーリングボム" || subue == "トーピード" || subue == "スプラッシュシールド") {
    var G2 = 1 - 0.35 * (0.033 * subinktotal - 0.00027 * subinktotal ** 2)
    var G2syouhi = subink * G2;
    if (G2 >= 0.65) {
      this.state.subpasent = Math.round(G2syouhi * 10) / 10 + "%";
      if (buki == "wakaba" || buki == "otiba") {
        var subsuu = 110 / G2syouhi;
        this.state.subren = Math.floor(subsuu);
      } else {
        var subsuu = 100 / G2syouhi;
        this.state.subren = Math.floor(subsuu);
      }
    } else {
      var G2 = 0.65
      var G2syouhi = subink * G2;
      this.state.subpasent = Math.round(G2syouhi * 10) / 10 + "%";
      if (buki == "wakaba" || buki == "otiba") {
        var subsuu = 110 / G2syouhi;
        this.state.subren = Math.floor(subsuu);
      } else {
        var subsuu = 100 / G2syouhi;
        this.state.subren = Math.floor(subsuu);
      }
    }
  } else if (subue == "ポイズンミスト" || subue == "タンサンボム" || subue == "ロボットボム" || subue == "ポイントセンサー") {
    var G3 = 1 - 0.3 * (0.033 * subinktotal - 0.00027 * subinktotal ** 2)
    var G3syouhi = subink * G3;
    if (G3 >= 0.70) {
      this.state.subpasent = Math.round(G3syouhi * 10) / 10 + "%";
      if (buki == "momizi") {
        var subsuu = 110 / G3syouhi;
        this.state.subren = Math.floor(subsuu);
      } else {
        var subsuu = 100 / G3syouhi;
        this.state.subren = Math.floor(subsuu);
      }
    } else {
      var G3 = 0.70
      var G3syouhi = subink * G3;
      this.state.subpasent = Math.round(G3syouhi * 10) / 10 + "%";
      if (buki == "momizi") {
        var subsuu = 110 / G3syouhi;
        this.state.subren = Math.floor(subsuu);
      } else {
        var subsuu = 100 / G3syouhi;
        this.state.subren = Math.floor(subsuu);
      }
    }
  } else if (subue == "クイックボム") {
    var G4 = 1 - 0.2 * (0.033 * subinktotal - 0.00027 * subinktotal ** 2)
    var G4syouhi = subink * G4;
    if (G4 >= 0.80) {
      this.state.subpasent = Math.round(G4syouhi * 10) / 10 + "%";
      var subsuu = 100 / G4syouhi;
      this.state.subren = Math.floor(subsuu);

    } else {
      var G4 = 0.80
      var G4syouhi = subink * G4;
      this.state.subpasent = Math.round(G4syouhi * 10) / 10 + "%";
      var subsuu = 100 / G4syouhi;
      this.state.subren = Math.floor(subsuu);
    }
  }
//サブインク消費量・連投数処理終わり

//スペシャル性能、必要塗ポイント処理始め

 var supesyaru = eval(buki)['supe'];
 this.state.supekyou = eval(supesyaru)['supeseinou']

 var sss = ((0.99 * supetotal) - (0.09 * supetotal) ** 2) / 100
 this.state.supeppt = Math.floor(supept / (1 + sss))

//スペシャル性能、必要塗ポイント処理終わり


///メイン性能処理始め
if (buki == "prime" || buki == "primekora" || buki == "primebetu") {
   if (0 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ42.0"
   } else if (3 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ42.8"
   } else if (6 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ43.6"
   } else if (9 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ44.4"
   } else if (10 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ44.6"
   } else if (12 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ45.1"
   } else if (13 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ45.3"
   } else if (15 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ45.7"
   } else if (16 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ46.0"
   } else if (18 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ46.4"
   } else if (19 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ46.6"
   } else if (20 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ46.8"
   } else if (21 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.0"
   } else if (22 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.2"
   } else if (23 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.3"
   } else if (24 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.5"
   } else if (25 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.7"
   } else if (26 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.9"
   } else if (27 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ48.0"
   } else if (28 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ48.2"
   } else if (29 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ48.3"
   } else if (30 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ48.5"
   } else if (31 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ48.6"
   } else if (32 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ48.8"
   } else if (33 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ48.9"
   } else if (34 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ49.0"
   } else if (35 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ49.2"
   } else if (36 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ49.3"
   } else if (37 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ49.4"
   } else if (38 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ49.5"
   } else if (39 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ49.6"
   } else if (41 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ49.8"
   } else if (42 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ49.9"
   }


 } else if (buki == "syapu" || buki == "syapuneo") {
   if (0 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ28.0"
   } else if (3 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ28.9"
   } else if (6 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.3"
   } else if (9 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.9"
   } else if (10 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.1"
   } else if (12 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.5"
   } else if (13 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.6"
   } else if (15 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.0"
   } else if (16 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.2"
   } else if (18 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.5"
   } else if (19 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.7"
   } else if (20 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.8"
   } else if (21 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.0"
   } else if (22 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.1"
   } else if (23 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.3"
   } else if (24 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.4"
   } else if (25 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.5"
   } else if (26 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.7"
   } else if (27 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.8"
   } else if (28 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.9"
   } else if (29 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.1"
   } else if (30 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.2"
   } else if (31 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.3"
   }
 } else if (buki == "l3" || buki == "l3d" || buki == "l3betu") {
   if (0 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.0"
   } else if (3 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.6"
   } else if (6 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.2"
   } else if (9 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.8"
   } else if (10 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.0"
   } else if (12 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.4"
   } else if (13 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.6"
   } else if (15 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.9"
   } else if (16 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.1"
   } else if (18 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.4"
   } else if (19 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.5"
   } else if (20 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.7"
   } else if (21 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.8"
   } else if (22 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.0"
   } else if (23 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.1"
   } else if (24 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.3"
   }
 } else if (buki == "h3" || buki == "h3d" || buki == "h3tye") {
   if (0 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ41.0"
   } else if (3 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ41.9"
   } else if (6 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ42.8"
   } else if (9 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ43.7"
   } else if (10 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ43.9"
   } else if (12 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ44.5"
   } else if (13 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ44.7"
   } else if (15 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ45.2"
   } else if (16 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ45.4"
   } else if (18 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ45.9"
   } else if (19 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ46.2"
   } else if (20 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ46.4"
   } else if (21 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ46.6"
   } else if (22 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ46.8"
   } else if (23 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.0"
   } else if (24 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.2"
   } else if (25 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.4"
   } else if (26 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.6"
   } else if (27 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.8"
   } else if (28 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ48.0"
   } else if (29 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ48.1"
   } else if (30 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ48.3"
   } else if (31 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ48.5"
   } else if (32 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ48.6"
   } else if (33 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ48.8"
   } else if (34 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ48.9"
   } else if (35 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ49.1"
   } else if (36 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ49.2"
   } else if (37 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ49.3"
   } else if (38 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ49.5"
   } else if (39 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ49.6"
   } else if (41 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ49.8"
   } else if (42 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ49.9"
   }

 } else if (buki == "botoruren") {
   if (0 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.0"
   } else if (3 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.6"
   } else if (6 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.2"
   } else if (9 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.8"
   } else if (10 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.0"
   } else if (12 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.4"
   } else if (13 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.6"
   } else if (15 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.9"
   } else if (16 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.1"
   } else if (18 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.4"
   } else if (19 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.5"
   } else if (20 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.7"
   } else if (21 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.8"
   } else if (22 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.0"
   } else if (23 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.1"
   } else if (24 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.3"
   }
 } else if (buki == "manyu" || buki == "manyukora" || buki == "manyube") {
   if (0 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.0"
   } else if (3 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.1"
   } else if (6 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.4"
   } else if (9 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.7"
   } else if (10 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.8"
   } else if (12 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.1"
   } else if (13 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.2"
   } else if (15 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.4"
   } else if (16 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.5"
   } else if (18 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.8"
   } else if (19 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.9"
   } else if (20 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.0"
   } else if (21 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.1"
   } else if (22 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.3"
   } else if (23 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.4"
   } else if (24 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.5"
   } else if (25 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.6"
   } else if (26 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.7"
   } else if (27 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.8"
   } else if (28 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.9"
   } else if (29 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.0"
   } else if (30 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.1"
   } else if (31 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.2"
   } else if (32 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.3"
   }

 } else if (buki == "duaru" || buki == "duarukasu") {
   if (0 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ28.0"
   } else if (3 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ28.5"
   } else if (6 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.0"
   } else if (9 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメー29.5"
   } else if (10 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.6"
   } else if (12 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.0"
   } else if (13 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.1"
   } else if (15 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.4"
   } else if (16 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.5"
   } else if (18 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.8"
   } else if (19 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.9"
   } else if (20 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.0"
   } else if (21 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.2"
   } else if (22 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.3"
   } else if (23 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.4"
   } else if (24 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.5"
   } else if (25 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.6"
   } else if (26 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.7"
   } else if (27 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.8"
   } else if (28 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.9"
   } else if (29 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.0"
   } else if (30 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.1"
   } else if (31 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.2"
   } else if (32 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.3"
   } else if (33 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.4"
   } else if (34 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.5"
   } else if (35 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.6"
   } else if (36 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.6"
   } else if (37 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.7"
   } else if (38 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.8"
   } else if (39 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.9"
   } else if (41 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.0"
   } else if (42 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.0"
   } else if (44 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.2"
   } else if (45 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.2"
   } else if (47 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.3"
   }

 } else if (buki == "haidora" || buki == "haidorakasu") {
   if (0 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.0"
   } else if (3 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.3"
   } else if (6 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.6"
   } else if (9 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.8"
   } else if (10 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.9"
   } else if (12 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.1"
   } else if (13 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.2"
   } else if (15 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.3"
   }

 } else if (buki == "take" || buki == "takehei" || buki == "takeotu") {
   if (0 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ85.0"
   } else if (3 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ86.5"
   } else if (6 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ88.0"
   } else if (9 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメー89.4"
   } else if (10 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ89.8"
   } else if (12 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ90.7"
   } else if (13 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ91.1"
   } else if (15 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ91.5"
   } else if (16 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ92.3"
   } else if (18 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ93.1"
   } else if (19 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ93.5"
   } else if (20 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ93.8"
   } else if (21 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ94.2"
   } else if (22 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ94.5"
   } else if (23 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ94.8"
   } else if (24 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ95.2"
   } else if (25 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ95.5"
   } else if (26 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ95.8"
   } else if (27 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ96.1"
   } else if (28 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ96.4"
   } else if (29 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ96.7"
   } else if (30 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ97.0"
   } else if (31 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ97.2"
   } else if (32 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ97.5"
   } else if (33 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ97.7"
   } else if (34 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ98.0"
   } else if (35 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ98.2"
   } else if (36 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ98.4"
   } else if (37 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ98.6"
   } else if (38 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ98.8"
   } else if (39 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ99.0"
   } else if (41 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ99.4"
   } else if (42 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ99.6"
   } else if (44 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ99.9"
   }
 } else if (buki == "kuaddo" || buki == "kuaddohowa") {
   if (0 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ28.0"
   } else if (3 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ28.5"
   } else if (6 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.0"
   } else if (9 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメー29.5"
   } else if (10 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.6"
   } else if (12 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.0"
   } else if (13 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.1"
   } else if (15 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.4"
   } else if (16 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.5"
   } else if (18 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.8"
   } else if (19 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.9"
   } else if (20 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.0"
   } else if (21 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.2"
   } else if (22 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.3"
   } else if (23 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.4"
   } else if (24 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.5"
   } else if (25 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.6"
   } else if (26 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.7"
   } else if (27 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.8"
   } else if (28 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.9"
   } else if (29 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.0"
   } else if (30 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.1"
   } else if (31 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.2"
   } else if (32 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.3"
   } else if (33 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.4"
   } else if (34 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.5"
   } else if (35 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.6"
   } else if (36 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.6"
   } else if (37 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.7"
   } else if (38 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.8"
   } else if (39 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.9"
   } else if (41 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.0"
   } else if (42 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.0"
   } else if (44 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.2"
   } else if (45 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.2"
   } else if (47 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.3"
   }
 } else if (buki == "borudo" || buki == "borudoneo" || buki == "borudosebun") {
   if (0 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ38.0"
   } else if (3 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ38.9"
   } else if (6 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ39.7"
   } else if (9 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメー40.6"
   } else if (10 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ40.8"
   } else if (12 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ41.3"
   } else if (13 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ41.6"
   } else if (15 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ42.1"
   } else if (16 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ42.3"
   } else if (18 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ42.8"
   } else if (19 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ43.0"
   } else if (20 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ43.2"
   } else if (21 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ43.4"
   } else if (22 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ43.6"
   } else if (23 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ43.8"
   } else if (24 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ44.0"
   } else if (25 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ44.2"
   } else if (26 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ44.4"
   } else if (27 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ44.5"
   } else if (28 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ44.7"
   } else if (29 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ44.9"
   } else if (30 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ45.0"
   } else if (31 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ45.2"
   } else if (32 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ45.4"
   } else if (33 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ45.5"
   } else if (34 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ45.6"
   } else if (35 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ45.8"
   } else if (36 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ45.9"
   } else if (37 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ46.0"
   } else if (38 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ46.2"
   } else if (39 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ46.3"
   } else if (41 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ46.5"
   } else if (42 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ46.6"
   } else if (44 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ46.8"
   } else if (45 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ46.9"
   } else if (47 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.0"
   } else if (48 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.1"
   } else if (51 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.3"
   } else if (54 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.4"
   } else if (57 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.5"
   }
 } else if (buki == "garon96" || buki == "garon96deko") {
   if (0 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ62.0"
   } else if (3 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ63.4"
   } else if (6 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ64.9"
   } else if (9 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメー66.2"
   } else if (10 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ66.6"
   } else if (12 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ67.5"
   } else if (13 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ67.9"
   } else if (15 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ68.7"
   } else if (16 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ69.1"
   } else if (18 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ69.8"
   } else if (19 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ70.2"
   } else if (20 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ70.5"
   } else if (21 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ70.8"
   } else if (22 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ71.2"
   } else if (23 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ71.5"
   } else if (24 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ71.8"
   } else if (25 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ72.1"
   } else if (26 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ72.4"
   } else if (27 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ72.7"
   } else if (28 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ73.0"
   } else if (29 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ73.3"
   } else if (30 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ73.5"
   } else if (31 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ73.8"
   } else if (32 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ74.0"
   } else if (33 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ74.3"
   } else if (34 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ74.5"
   } else if (35 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ74.7"
   } else if (36 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ74.9"
   } else if (37 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ75.1"
   } else if (38 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ75.3"
   } else if (39 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ75.5"
   } else if (41 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ75.9"
   } else if (42 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ76.1"
   } else if (44 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ76.4"
   } else if (45 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ76.5"
   } else if (47 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ76.7"
   } else if (48 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ76.9"
   } else if (51 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ77.2"
   } else if (54 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ77.4"
   } else if (57 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ77.5"
   }
 } else if (buki == "botoru" || buki == "botorufo") {
   if (0 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ38.0"
   } else if (3 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ39.1"
   } else if (6 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ40.1"
   } else if (9 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ41.1"
   } else if (10 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ41.4"
   } else if (12 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ42.0"
   } else if (13 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ42.3"
   } else if (15 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ42.9"
   } else if (16 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ43.2"
   } else if (18 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ43.7"
   } else if (19 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ44.0"
   } else if (20 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ44.2"
   } else if (21 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ44.5"
   } else if (22 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ44.7"
   } else if (23 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ45.0"
   } else if (24 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ45.2"
   } else if (25 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ45.4"
   } else if (26 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ45.7"
   } else if (27 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ45.9"
   } else if (28 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ46.1"
   } else if (29 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ46.3"
   } else if (30 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ46.5"
   } else if (31 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ46.7"
   } else if (32 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ46.8"
   } else if (33 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.0"
   } else if (34 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.2"
   } else if (35 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.3"
   } else if (36 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.5"
   } else if (37 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.7"
   } else if (38 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.8"
   } else if (39 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ47.9"
   } else if (41 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ48.2"
   } else if (42 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ48.3"
   } else if (44 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ48.5"
   } else if (45 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ48.6"
   } else if (47 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ48.8"
   } else if (48 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ48.9"
   } else if (51 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ49.1"
   } else if (54 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ49.3"
   } else if (57 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ49.4"
   }
 } else if (buki == "supa" || buki == "supahyu" || buki == "supakuri") {
   if (0 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ36.0"
   } else if (3 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ36.6"
   } else if (6 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ37.3"
   } else if (9 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ37.9"
   } else if (10 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ38.1"
   } else if (12 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ38.5"
   } else if (13 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ38.7"
   } else if (15 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ39.1"
   } else if (16 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ39.3"
   } else if (18 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ39.6"
   } else if (19 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ39.8"
   } else if (20 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ39.9"
   } else if (21 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ40.1"
   } else if (22 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ40.2"
   } else if (23 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ40.4"
   } else if (24 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ40.5"
   } else if (25 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ40.7"
   } else if (26 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ40.8"
   } else if (27 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ40.9"
   } else if (28 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ41.1"
   } else if (29 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ41.2"
   } else if (30 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ41.3"
   } else if (31 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ41.4"
   } else if (32 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ41.6"
   } else if (33 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ41.7"
   } else if (34 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ41.8"
   } else if (35 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ41.9"
   } else if (36 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ42.0"
   } else if (37 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ42.1"
   } else if (38 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ42.2"
   } else if (39 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ42.3"
   } else if (41 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ42.4"
   } else if (42 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ42.5"
   } else if (44 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ42.6"
   } else if (45 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ42.7"
   } else if (47 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ42.8"
   } else if (48 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ42.9"
   } else if (51 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ43.0"
   } else if (54 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ43.1"
   } else if (57 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ43.2"
   }
 } else if (buki == "kerubin" || buki == "kerubindeko" || buki == "kerubinbetu") {
   if (0 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ(スライド撃ち),ダメージ52.5"
   } else if (3 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ53.5"
   } else if (6 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ54.4"
   } else if (9 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ55.3"
   } else if (10 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ55.6"
   } else if (12 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ56.2"
   } else if (13 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ56.5"
   } else if (15 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ57.0"
   } else if (16 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ57.3"
   } else if (18 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ57.8"
   } else if (19 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ58.0"
   } else if (20 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ58.2"
   } else if (21 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ58.5"
   } else if (22 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ58.7"
   } else if (23 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ58.9"
   } else if (24 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ59.1"
   } else if (25 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ59.3"
   } else if (26 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ59.5"
   } else if (27 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ59.7"
   } else if (28 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ59.9"
   } else if (29 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ60.1"
   } else if (30 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ60.3"
   } else if (31 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ60.5"
   } else if (32 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ60.6"
   } else if (33 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ60.8"
   } else if (34 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ61.0"
   } else if (35 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ61.1"
   } else if (36 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ61.3"
   } else if (37 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ61.4"
   } else if (38 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ61.5"
   } else if (39 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ61.7"
   } else if (41 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ61.9"
   } else if (42 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ62.0"
   } else if (44 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ62.2"
   } else if (45 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ62.3"
   } else if (47 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ62.5"
   } else if (48 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ62.6"
   } else if (51 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ62.7"
   } else if (54 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ62.9"
   } else if (57 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,(スライド撃ち)ダメージ63.0"
   }
 } else if (buki == "kugel" || buki == "kugelhyu") {
   if (0 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ28.0"
   } else if (3 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ28.2"
   } else if (6 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ28.5"
   } else if (9 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ28.7"
   } else if (10 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ28.8"
   } else if (12 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.0"
   } else if (13 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.0"
   } else if (15 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.2"
   } else if (16 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.2"
   } else if (18 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.4"
   } else if (19 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.4"
   } else if (20 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.5"
   } else if (21 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.6"
   } else if (22 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.6"
   } else if (23 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.7"
   } else if (24 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.7"
   } else if (25 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.8"
   } else if (26 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.8"
   } else if (27 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.9"
   } else if (28 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ29.9"
   } else if (29 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.0"
   } else if (30 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.0"
   } else if (31 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.1"
   } else if (32 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.1"
   } else if (33 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.2"
   } else if (34 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.2"
   } else if (35 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.3"
   } else if (36 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.3"
   } else if (37 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.3"
   } else if (38 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.4"
   } else if (39 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.4"
   } else if (41 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.5"
   } else if (42 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.5"
   } else if (44 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.6"
   } else if (45 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.6"
   } else if (47 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.6"
   } else if (48 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.6"
   } else if (51 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.7"
   } else if (54 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.7"
   } else if (57 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.8"
   }
 } else if (buki == "kugeltyou" || buki == "kugelhyutyou") {
   if (0 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.0"
   } else if (3 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.2"
   } else if (6 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.5"
   } else if (9 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.8"
   } else if (10 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ30.9"
   } else if (12 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.0"
   } else if (13 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.1"
   } else if (15 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.3"
   } else if (16 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.3"
   } else if (18 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.5"
   } else if (19 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.5"
   } else if (20 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ1.6"
   } else if (21 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.7"
   } else if (22 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.7"
   } else if (23 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.8"
   } else if (24 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.9"
   } else if (25 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ31.9"
   } else if (26 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.0"
   } else if (27 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.0"
   } else if (28 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.1"
   } else if (29 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.1"
   } else if (30 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.2"
   } else if (31 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.2"
   } else if (32 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.3"
   } else if (33 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.3"
   } else if (34 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.4"
   } else if (35 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.4"
   } else if (36 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.5"
   } else if (37 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.5"
   } else if (38 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.5"
   } else if (39 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.6"
   } else if (41 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.6"
   } else if (42 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.7"
   } else if (44 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.7"
   } else if (45 == seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.8"
   } else if (47 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.8"
   } else if (48 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.8"
   } else if (51 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ2.9"
   } else if (54 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ32.9"
   } else if (57 <= seinoutotal) {
     this.state.meinseinou_kouka = "攻撃力アップ,ダメージ33.0"
   }
 }
 else if (!(eval(buki)['mainseinou'] == "攻撃力アップ")) {
   this.state.meinseinou_kouka = eval(buki)['mainseinou']
 }
//メイン性能処理終わり

//メインインク処理始め：弾数、スライド回数。プライム～ハイドラは軽減がの値が違うため分岐処理

if (buki == "prime" || buki == "primekora" || buki == "primebetu" || buki == "prime3"|| buki == "haidora" || buki == "haidorakasu" ||  buki == "haidora3" ||buki == "h3" || buki == "h3d" || buki == "h3tye" || buki == "novaneo" || buki == "nova" || buki == "novabe" ||  buki == "nova3" ||buki == "ritta" || buki == "ritta3" || buki == "rittasuko" ||  buki == "rittasuko3" || buki == "rittakasu" || buki == "rittakasusuko" || buki == "dainamo" ||  buki == "dainamo3" ||buki == "dainamotesu" || buki == "dainamobe" || buki == "kyanpu" ||buki == "kyanpu3" ||  buki == "kyanpusore" || buki == "kyanpukamo") {
   if (total == 0) {
     var dansu = 100 / parseFloat(eval(buki)['mainink'])
     this.state.field_total = Math.floor(dansu);

   } else if (total == 3) {
     var a = parseFloat(eval(buki)['mainink']) * 0.089
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 6) {
     var a = parseFloat(eval(buki)['mainink']) * 0.146
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 9) {
     var a = parseFloat(eval(buki)['mainink']) * 0.193
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 10) {
     var a = parseFloat(eval(buki)['mainink']) * 0.207
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 12) {
     var a = parseFloat(eval(buki)['mainink']) * 0.234
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 13) {
     var a = parseFloat(eval(buki)['mainink']) * 0.2467
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 15) {
     var a = parseFloat(eval(buki)['mainink']) * 0.2704
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 16) {
     var a = parseFloat(eval(buki)['mainink']) * 0.2816
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 18) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3029
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 19) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3129
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 20) {
     var a = parseFloat(eval(buki)['mainink']) * 0.322
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 21) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3321
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 22) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3412
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 23) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3499
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 24) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3584
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 25) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3665
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 26) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3745
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 27) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3821
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 28) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3894
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 29) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3965
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 30) {
     var a = parseFloat(eval(buki)['mainink']) * 0.403
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 31) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4098
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 32) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4161
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 33) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4222
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 34) {
     var a = parseFloat(eval(buki)['mainink']) * 0.428
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 35) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4336
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 36) {
     var a = parseFloat(eval(buki)['mainink']) * 0.439
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 37) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4441
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 38) {
     var a = parseFloat(eval(buki)['mainink']) * 0.449
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 39) {
     var a = parseFloat(eval(buki)['mainink']) * 0.453
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 40) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4581
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 41) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4623
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 42) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4663
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 43) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4701
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 44) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4737
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 45) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4771
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 46) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4802
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 47) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4832
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 48) {
     var a = parseFloat(eval(buki)['mainink']) * 0.486
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 51) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4929
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 54) {
     var a = parseFloat(eval(buki)['mainink']) * 0.498
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   } else if (total == 57) {
     var a = parseFloat(eval(buki)['mainink']) * 0.50
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);
   }
 } else {

   if (total == 0) {
     var dansu = 100 / parseFloat(eval(buki)['mainink'])
     this.state.field_total = Math.floor(dansu);

     var sl = 100 /  parseFloat(eval(buki)['suraido'])
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 3) {
     var a = parseFloat(eval(buki)['mainink']) * 0.043
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

     var c = parseFloat(eval(buki)['suraido']) * 0.043
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 6) {
     var a = parseFloat(eval(buki)['mainink']) * 0.084
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.084
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 9) {
     var a = parseFloat(eval(buki)['mainink']) * 0.123
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.123
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 10) {
     var a = parseFloat(eval(buki)['mainink']) * 0.136
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.136
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 12) {
     var a = parseFloat(eval(buki)['mainink']) * 0.1607
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.1607
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 13) {
     var a = parseFloat(eval(buki)['mainink']) * 0.1725
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.1725
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 15) {
     var a = parseFloat(eval(buki)['mainink']) * 0.1954
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.1954
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 16) {
     var a = parseFloat(eval(buki)['mainink']) * 0.2065
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.2065
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 18) {
     var a = parseFloat(eval(buki)['mainink']) * 0.2279
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.2279
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 19) {
     var a = parseFloat(eval(buki)['mainink']) * 0.2383
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.2383
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 20) {
     var a = parseFloat(eval(buki)['mainink']) * 0.248
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.248
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 21) {
     var a = parseFloat(eval(buki)['mainink']) * 0.2583
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.2583
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 22) {
     var a = parseFloat(eval(buki)['mainink']) * 0.2679
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.2679
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 23) {
     var a = parseFloat(eval(buki)['mainink']) * 0.2773
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.2773
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 24) {
     var a = parseFloat(eval(buki)['mainink']) * 0.2864
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.2864
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 25) {
     var a = parseFloat(eval(buki)['mainink']) * 0.2953
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.2953
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 26) {
     var a = parseFloat(eval(buki)['mainink']) * 0.304
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.304
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 27) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3124
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.3124
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 28) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3205
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.3205
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 29) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3285
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.3285
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 30) {
     var a = parseFloat(eval(buki)['mainink']) * 0.336
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.336
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 31) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3436
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.3436
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 32) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3508
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.3508
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 33) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3578
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.3578
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 34) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3645
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.3645
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 35) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3709
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.3709
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 36) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3771
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.3771
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 37) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3831
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.3831
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 38) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3888
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.3888
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 39) {
     var a = parseFloat(eval(buki)['mainink']) * 0.394
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.394
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 40) {
     var a = parseFloat(eval(buki)['mainink']) * 0.3996
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.3996
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 41) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4046
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.4046
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 42) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4094
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.4094
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 43) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4139
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.4139
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 44) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4182
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.4182
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 45) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4222
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.4222
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 46) {
     var a = parseFloat(eval(buki)['mainink']) * 0.426
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.426
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 47) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4296
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.4296
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 48) {
     var a = parseFloat(eval(buki)['mainink']) * 0.433
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.433
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 51) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4413
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.4413
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 54) {
     var a = parseFloat(eval(buki)['mainink']) * 0.4476
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.4476
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   } else if (total == 57) {
     var a = parseFloat(eval(buki)['mainink']) * 0.45
     var b = parseFloat(eval(buki)['mainink']) - a
     var dansu = 100 / b
     this.state.field_total = Math.floor(dansu);

       var c = parseFloat(eval(buki)['suraido']) * 0.45
     var d = parseFloat(eval(buki)['suraido']) -c
     var sl  = 100 / d
     this.state.sulatotal = Math.floor(sl);
   }
 }
//メインインク処理終わり

//インク回復処理始め：わかば系はインクタンク量1.1倍のため分岐処理
var ikaanime = this.ika.current;
var hitoanime = this.hito.current;


//インク回復処理終わり
if (buki == "wakaba" || buki == "wakaba3"|| buki == "momizi" || buki == "otiba") {
    var hitomwakaba = hitom * 1.1;
    var ikamwakaba = ikam * 1.1;
    this.state.ikakaikai = Math.round(ikamwakaba * 10) / 10 + "秒"
    this.state.hitokai = Math.round(hitomwakaba * 10) / 10 + "秒"

ikaanime.style.animationDuration = eval('ikamwakaba+"s"');
hitoanime.style.animationDuration = eval('hitowakaba+"s"');
  } else {
    this.state.ikakaikai = Math.round(ikam * 10) / 10 + "秒"
    this.state.hitokai = Math.round(hitom * 10) / 10 + "秒"

ikaanime.style.animationDuration = eval('ikam+"s"');
hitoanime.style.animationDuration = eval('hitom+"s"');
  }



}



handleSubmitidou(event){


   const target = event.target;

   const name = target.name;
   this.setState({[name]: target.value})

 event.preventDefault();

   var supura = { 'mainseinou': 'ジャンプ撃ちでの弾のブレ軽減', 'mainink': 0.92, 'supe': 'スーパーチャクチ', 'supepo': 190, sub: 'クイックボム', 'hitosoku': 0.72 };
  var susikora = { 'mainseinou': 'ジャンプ撃ちでの弾のブレ軽減', 'mainink': 0.92, 'supe': 'ジェットパック', 'supepo': 200, sub: 'スプラッシュボム', 'hitosoku': 0.72 };
  var susibe = { 'mainseinou': 'ジャンプ撃ちでの弾のブレ軽減', 'mainink': 0.92, 'supe': 'マルチミサイル', 'supepo': 180, sub: 'キューバンボム', 'hitosoku': 0.72 };

  var syapu = { 'mainseinou': '攻撃力アップ', 'mainink': 0.8, 'supe': 'ジェットパック', 'supepo': 170, sub: 'ポイズンミスト', 'hitosoku': 0.72 };
  var syapuneo = { 'mainseinou': '攻撃力アップ', 'mainink': 0.8, 'supe': 'キューバンボムピッチャー', 'supepo': 210, sub: 'クイックボム', 'hitosoku': 0.72 };

  var prime = { 'mainseinou': '攻撃力アップ', 'mainink': 2, 'supe': 'アメフラシ', 'supepo': 170, sub: 'ポイントセンサー', 'hitosoku': 0.55 };
  var primekora = { 'mainseinou': '攻撃力アップ', 'mainink': 2, 'supe': 'バブルランチャー', 'supepo': 200, sub: 'キューバンボム', 'hitosoku': 0.55 };
  var primebetu = { 'mainseinou': '攻撃力アップ', 'mainink': 2, 'supe': 'ナイスダマ', 'supepo': 210, sub: 'スプラッシュボム', 'hitosoku': 0.55 };

  var l3 = { 'mainseinou': '攻撃力アップ', 'mainink': 1.15, 'supe': 'イカスフィア', 'supepo': 220, sub: 'カーリングボム', 'hitosoku': 0.80 };
  var l3d = { 'mainseinou': '攻撃力アップ', 'mainink': 1.15, 'supe': 'ジェットパック', 'supepo': 200, sub: 'クイックボム', 'hitosoku': 0.80 };
  var l3betu = { 'mainseinou': '攻撃力アップ', 'mainink': 1.15, 'supe': 'ウルトラハンコ', 'supepo': 180, sub: 'スプラッシュシールド', 'hitosoku': 0.80 };

  var h3 = { 'mainseinou': '攻撃力アップ', 'mainink': 1.8, 'supe': 'マルチミサイル', 'supepo': 170, sub: 'ポイントセンサー', 'hitosoku': 0.60 };
  var h3d = { 'mainseinou': '攻撃力アップ', 'mainink': 1.8, 'supe': 'インクアーマー', 'supepo': 220, sub: 'キューバンボム', 'hitosoku': 0.60 };
  var h3tye = { 'mainseinou': '攻撃力アップ', 'mainink': 1.8, 'supe': 'バブルランチャー', 'supepo': 190, sub: 'スプラッシュシールド', 'hitosoku': 0.60 };

  var botoru = { 'mainseinou': '攻撃力アップ', 'mainink': 2.2, 'supe': 'ハイパープレッサー', 'supepo': 180, sub: 'スプラッシュシールド', 'hitosoku': 0.72 };
  var botorufo = { 'mainseinou': '攻撃力アップ', 'mainink': 2.2, 'supe': 'バブルランチャー', 'supepo': 190, sub: 'スプラッシュボム', 'hitosoku': 0.72 };

  var manyu = { 'mainseinou': '攻撃力アップ', 'mainink': 0.76, 'supe': 'マルチミサイル', 'supepo': 180, sub: 'クイックボム', 'hitosoku': 0.80 };
  var manyukora = { 'mainseinou': '攻撃力アップ', 'mainink': 0.76, 'supe': 'ジェットパック', 'supepo': 200, sub: 'カーリングボム', 'hitosoku': 0.80 };
  var manyube = { 'mainseinou': '攻撃力アップ', 'mainink': 0.76, 'supe': 'イカスフィア', 'supepo': 210, sub: 'キューバンボム', 'hitosoku': 0.80 };

  var kuaddo = { 'mainseinou': '攻撃力アップ', 'mainink': 0.8, 'supe': 'スーパーチャクチ', 'supepo': 190, sub: 'ロボットボム', 'hitosoku': 0.72 };
  var kuaddohowa = { 'mainseinou': '攻撃力アップ', 'mainink': 0.8, 'supe': 'ロボボムピッチャー', 'supepo': 200, sub: 'スプリンクラー', 'hitosoku': 0.72 };

  var duaru = { 'mainseinou': '攻撃力アップ', 'mainink': 1.2, 'supe': 'マルチミサイル', 'supepo': 190, sub: 'ポイントセンサー', 'hitosoku': 0.72 };
  var duarukasu = { 'mainseinou': '攻撃力アップ', 'mainink': 1.2, 'supe': 'アメフラシ', 'supepo': 210, sub: 'スプラッシュボム', 'hitosoku': 0.72 };

  var haidora = { 'mainseinou': '攻撃力アップ', 'mainink': 0.57, 'supe': 'スーパーチャクチ', 'supepo': 170, sub: 'ロボットボム', 'hitosoku': 0.60, 'tyazihitosoku': 0.40 };
  var haidorakasu = { 'mainseinou': '攻撃力アップ', 'mainink': 0.57, 'supe': 'インクアーマー', 'supepo': 200, sub: 'トラップ', 'hitosoku': 0.60, 'tyazihitosoku': 0.40 };

  var take = { 'mainseinou': '攻撃力アップ', 'mainink': 7, 'supe': 'マルチミサイル', 'supepo': 200, sub: 'カーリングボム', 'hitosoku': 0.60 };
  var takehei = { 'mainseinou': '攻撃力アップ', 'mainink': 7, 'supe': 'バブルランチャー', 'supepo': 190, sub: 'タンサンボム', 'hitosoku': 0.60 };
  var takeotu = { 'mainseinou': '攻撃力アップ', 'mainink': 7, 'supe': 'クイックボムピッチャー', 'supepo': 160, sub: 'ポイズンミスト', 'hitosoku': 0.60 };


  var nova = { 'mainseinou': '大ダメージを与える範囲アップ', 'mainink': 7.5, 'supe': 'イカスフィア', 'supepo': 180, sub: 'スプラッシュボム', 'hitosoku': 0.50 };
  var novaneo = { 'mainseinou': '大ダメージを与える範囲アップ', 'mainink': 7.5, 'supe': 'キューバンボムピッチャー', 'supepo': 170, sub: 'トラップ', 'hitosoku': 0.50 };
  var novabe = { 'mainseinou': '大ダメージを与える範囲アップ', 'mainink': 7.5, 'supe': 'アメフラシ', 'supepo': 170, sub: 'タンサンボム', 'hitosoku': 0.50 };

  var dainamo = { 'mainseinou': '確殺範囲、増大', 'mainink': 18, 'supe': 'ハイパープレッサー', 'supepo': 180, sub: 'トラップ', 'hitosoku': 0.88 };
  var dainamotesu = { 'mainseinou': '確殺範囲、増大', 'mainink': 18, 'supe': 'インクアーマー', 'supepo': 190, sub: 'スプラッシュボム', 'hitosoku': 0.88 };
  var dainamobe = { 'mainseinou': '確殺範囲、増大', 'mainink': 18, 'supe': 'ナイスダマ', 'supepo': 180, sub: 'スプリンクラー', 'hitosoku': 0.88 };

  var ritta = { 'mainseinou': '射程アップ', 'mainink': 25, 'supe': 'アメフラシ', 'supepo': 170, sub: 'トラップ', 'hitosoku': 0.15, 'tyazihitosoku': 0.88, 'hantyazihitosoku': 0.16 };
  var rittasuko = { 'mainseinou': '射程アップ', 'mainink': 25, 'supe': 'アメフラシ', 'supepo': 170, sub: 'トラップ', 'hitosoku': 0.15, 'tyazihitosoku': 0.88, 'hantyazihitosoku': 0.16 };
  var rittakasu = { 'mainseinou': '射程アップ', 'mainink': 25, 'supe': 'バブルランチャー', 'supepo': 170, sub: 'ジャンプビーコン', 'hitosoku': 0.15, 'tyazihitosoku': 0.88, 'hantyazihitosoku': 0.16 };
  var rittakasusuko = { 'mainseinou': '射程アップ', 'mainink': 25, 'supe': 'バブルランチャー', 'supepo': 170, sub: 'ジャンプビーコン', 'hitosoku': 0.15, 'tyazihitosoku': 0.88, 'hantyazihitosoku': 0.16 };

  var kyanpu = { 'mainseinou': 'カサ耐久力アップ', 'mainink': 11, 'supe': 'バブルランチャー', 'supepo': 200, sub: 'ジャンプビーコン', 'hitosoku': 0.88 };
  var kyanpusore = { 'mainseinou': 'カサ耐久力アップ', 'mainink': 11, 'supe': 'カーリングボムピッチャー', 'supepo': 170, sub: 'スプラッシュシールド', 'hitosoku': 0.88 };
  var kyanpukamo = { 'mainseinou': 'カサ耐久力アップ', 'mainink': 11, 'supe': 'ウルトラハンコ', 'supepo': 210, sub: 'トラップ', 'hitosoku': 0.88 };

  var wakaba = { 'mainseinou': '塗り性能アップ', 'mainink': 0.45454545, 'supe': 'インクアーマー', 'supepo': 180, sub: 'スプラッシュボム', 'hitosoku': 0.72 };
  var momizi = { 'mainseinou': '塗り性能アップ', 'mainink': 0.45454545, 'supe': 'アメフラシ', 'supepo': 160, sub: 'ロボットボム', 'hitosoku': 0.72 };
  var otiba = { 'mainseinou': '塗り性能アップ', 'mainink': 0.45454545, 'supe': 'バブルランチャー', 'supepo': 200, sub: 'トーピード', 'hitosoku': 0.72 };

  var garon52 = { 'mainseinou': 'ジャンプ撃ちでの弾のブレ軽減', 'mainink': 1.3, 'supe': 'イカスフィア', 'supepo': 170, sub: 'ポイントセンサー', 'hitosoku': 0.60 };
  var garon52deko = { 'mainseinou': 'ジャンプ撃ちでの弾のブレ軽減', 'mainink': 1.3, 'supe': 'ハイパープレッサー', 'supepo': 190, 'sub': 'カーリングボム', 'hitosoku': 0.60 };
  var garon52betu = { 'mainseinou': 'ジャンプ撃ちでの弾のブレ軽減', 'mainink': 1.3, 'supe': 'ナイスダマ', 'supepo': 180, sub: 'スプラッシュシールド', 'hitosoku': 0.60 };

  var garon96 = { 'mainseinou': '攻撃力アップ', 'mainink': 2.5, 'supe': 'インクアーマー', 'supepo': 190, sub: 'スプリンクラー', 'hitosoku': 0.40 };
  var garon96deko = { 'mainseinou': '攻撃力アップ', 'mainink': 2.5, 'supe': 'スーパーチャクチ', 'supepo': 170, sub: 'スプラッシュシールド', 'hitosoku': 0.40 };

  var zyetto = { 'mainseinou': '射程アップ、地上でのブレ軽減', 'mainink': 1.6, 'supe': 'マルチミサイル', 'supepo': 180, sub: 'ポイズンミスト', 'hitosoku': 0.60 };
  var zyettokasu = { 'mainseinou': '射程アップ、地上でのブレ軽減', 'mainink': 1.6, 'supe': 'ハイパープレッサー', 'supepo': 180, sub: 'クイックボム', 'hitosoku': 0.60 };

  var borudo = { 'mainseinou': '攻撃力アップ', 'mainink': 0.8, 'supe': 'スーパーチャクチ', 'supepo': 160, sub: 'カーリングボム', 'hitosoku': 0.80 };
  var borudoneo = { 'mainseinou': '攻撃力アップ', 'mainink': 0.8, 'supe': 'マルチミサイル', 'supepo': 170, sub: 'ジャンプビーコン', 'hitosoku': 0.80 };
  var borudosebun = { 'mainseinou': '攻撃力アップ', 'mainink': 0.8, 'supe': 'ウルトラハンコ', 'supepo': 180, sub: 'スプラッシュボム', 'hitosoku': 0.80 };

  var zap = { 'mainseinou': '塗り性能アップ', 'mainink': 0.8, 'supe': 'インクアーマー', 'supepo': 200, sub: 'キューバンボム', 'hitosoku': 0.84 };
  var zapaka = { 'mainseinou': '塗り性能アップ', 'mainink': 0.8, 'supe': 'マルチミサイル', 'supepo': 180, sub: 'ロボットボム', 'hitosoku': 0.84 };
  var zapfami = { 'mainseinou': '塗り性能アップ', 'mainink': 0.8, 'supe': 'アメフラシ', 'supepo': 170, sub: 'スプリンクラー', 'hitosoku': 0.84 };

  var modera = { 'mainseinou': '塗り性能アップ', 'mainink': 0.55, 'supe': 'カーリングボムピッチャー', 'supepo': 160, sub: 'キューバンボム', 'hitosoku': 0.72 };
  var moderakin = { 'mainseinou': '塗り性能アップ', 'mainink': 0.55, 'supe': 'イカスフィア', 'supepo': 180, sub: 'スプリンクラー', 'hitosoku': 0.72 };
  var moderadou = { 'mainseinou': '塗り性能アップ', 'mainink': 0.55, 'supe': 'ナイスダマ', 'supepo': 190, sub: 'クイックボム', 'hitosoku': 0.72 };

  var botoruren = { 'mainseinou': '攻撃力アップ', 'mainink': 0.9, 'supe': 'ハイプレorバブル', 'supepo': 160, sub: 'カーリングボム', 'hitosoku': 0.72 };

  var hot = { 'mainseinou': 'ジャンプ撃ちでの弾のブレ軽減', 'mainink': 10, 'supe': 'スーパーチャクチ', 'supepo': 170, sub: 'ポイズンミスト', 'hitosoku': 0.45 };
  var hotkasu = { 'mainseinou': 'ジャンプ撃ちでの弾のブレ軽減', 'mainink': 10, 'supe': 'ジェットパック', 'supepo': 190, sub: 'ロボットボム', 'hitosoku': 0.45 };

  var long = { 'mainseinou': 'ジャンプ撃ちでの弾のブレ軽減', 'mainink': 11, 'supe': 'アメフラシ', 'supepo': 200, sub: 'キューバンボム', 'hitosoku': 0.40 };
  var longkasu = { 'mainseinou': 'ジャンプ撃ちでの弾のブレ軽減', 'mainink': 11, 'supe': 'バブルランチャー', 'supepo': 170, sub: 'カーリングボム', 'hitosoku': 0.40 };
  var longneku = { 'mainseinou': 'ジャンプ撃ちでの弾のブレ軽減', 'mainink': 11, 'supe': 'マルチミサイル', 'supepo': 190, sub: 'クイックボム', 'hitosoku': 0.40 };

  var rapi = { 'mainseinou': 'ジャンプ撃ちでの弾のブレ軽減、爆風範囲アップ', 'mainink': 7, 'supe': 'スプラッシュボムピッチャー', 'supepo': 200, sub: 'トラップ', 'hitosoku': 0.55 };
  var rapideko = { 'mainseinou': 'ジャンプ撃ちでの弾のブレ軽減、爆風範囲アップ', 'mainink': 7, 'supe': 'ジェットパック', 'supepo': 190, sub: 'キューバンボム', 'hitosoku': 0.55 };
  var rapibetu = { 'mainseinou': 'ジャンプ撃ちでの弾のブレ軽減、爆風範囲アップ', 'mainink': 7, 'supe': 'イカスフィア', 'supepo': 200, sub: 'トーピード', 'hitosoku': 0.55 };

  var rapieri = { 'mainseinou': 'ジャンプ撃ちでの弾のブレ軽減、爆風範囲アップ', 'mainink': 8, 'supe': 'アメフラシ', 'supepo': 180, sub: 'ポイズンミスト', 'hitosoku': 0.55 };
  var rapierideko = { 'mainseinou': 'ジャンプ撃ちでの弾のブレ軽減、爆風範囲アップ', 'mainink': 8, 'supe': 'インクアーマー', 'supepo': 180, sub: 'スプラッシュシールド', 'hitosoku': 0.55 };

  var kura = { 'mainseinou': 'ジャンプ撃ちでの弾のブレ軽減', 'mainink': 4, 'supe': 'ハイパープレッサー', 'supepo': 180, sub: 'スプラッシュボム', 'hitosoku': 0.65 };
  var kuraneo = { 'mainseinou': 'ジャンプ撃ちでの弾のブレ軽減', 'mainink': 4, 'supe': 'マルチミサイル', 'supepo': 180, sub: 'カーリングボム', 'hitosoku': 0.65 };

  var rola = { 'mainseinou': '確殺範囲、増大', 'mainink': 9, 'supe': 'スーパーチャクチ', 'supepo': 170, sub: 'カーリングボム', 'hitosoku': 0.96 };
  var rolakora = { 'mainseinou': '確殺範囲、増大', 'mainink': 9, 'supe': 'イカスフィア', 'supepo': 170, sub: 'ジャンプビーコン', 'hitosoku': 0.96 };
  var rolabetu = { 'mainseinou': '確殺範囲、増大', 'mainink': 9, 'supe': 'バブルランチャー', 'supepo': 180, sub: 'バブルランチャー', 'hitosoku': 0.96 };

  var kabon = { 'mainseinou': '確殺範囲、増大', 'mainink': 3.96, 'supe': 'アメフラシ', 'supepo': 160, sub: 'ロボットボム', 'hitosoku': 1.04 };
  var kabondeko = { 'mainseinou': '確殺範囲、増大', 'mainink': 3.96, 'supe': 'ロボボムピッチャー', 'supepo': 190, sub: 'クイックボム', 'hitosoku': 1.04 };

  var varia = { 'mainseinou': '確殺範囲、増大', 'mainink': 8, 'supe': 'スプラッシュボムピッチャー', 'supepo': 180, sub: 'スプラッシュシールド', 'hitosoku': 0.72 };
  var variafo = { 'mainseinou': '確殺範囲、増大', 'mainink': 8, 'supe': 'マルチミサイル', 'supepo': 180, sub: 'キューバンボム', 'hitosoku': 0.72 };

  var variatate = { 'mainseinou': '確殺範囲、増大', 'mainink': 12, 'supe': 'スプラッシュボムピッチャー', 'supepo': 180, sub: 'スプラッシュシールド', 'hitosoku': 0.80 };

  var paburo = { 'mainseinou': '塗りながら進むスピードアップ', 'mainink': 2, 'supe': 'スーパーチャクチ', 'supepo': 150, sub: 'スプラッシュボム', 'hitosoku': 1.04 };
  var paburohyu = { 'mainseinou': '塗りながら進むスピードアップ', 'mainink': 2, 'supe': 'イカスフィア', 'supepo': 180, sub: 'トラップ', 'hitosoku': 1.04 };
  var paburopama = { 'mainseinou': '塗りながら進むスピードアップ', 'mainink': 2, 'supe': 'インクアーマー', 'supepo': 180, sub: 'スプリンクラー', 'hitosoku': 1.04 };

  var hokusai = { 'mainseinou': '塗りながら進むスピードアップ', 'mainink': 3.2, 'supe': 'ジェットパック', 'supepo': 170, sub: 'ロボットボム', 'hitosoku': 0.96 };
  var hokusaihyu = { 'mainseinou': '塗りながら進むスピードアップ', 'mainink': 3.2, 'supe': 'マルチミサイル', 'supepo': 170, sub: 'ジャンプビーコン', 'hitosoku': 0.96 };
  var hokusaibetu = { 'mainseinou': '塗りながら進むスピードアップ', 'mainink': 3.2, 'supe': 'ウルトラハンコ', 'supepo': 180, sub: 'キューバンボム', 'hitosoku': 0.96 };

  var tyazya = { 'mainseinou': '(最小)攻撃力アップ', 'mainink': 18, 'supe': 'ハイパープレッサー', 'supepo': 220, sub: 'スプラッシュボム', 'hitosoku': 0.20, 'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.21 };
  var tyazyasuko = { 'mainseinou': '(最小)攻撃力アップ', 'mainink': 18, 'supe': 'ハイパープレッサー', 'supepo': 220, sub: 'スプラッシュボム', 'hitosoku': 0.20, 'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.21 };
  var tyazyakora = { 'mainseinou': '(最小)攻撃力アップ', 'mainink': 18, 'supe': 'キューバンボムピッチャー', 'supepo': 210, sub: 'スプラッシュシールド', 'hitosoku': 0.20, 'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.21 };
  var tyazyakorasuko = { 'mainseinou': '(最小)攻撃力アップ', 'mainink': 18, 'supe': 'キューバンボムピッチャー', 'supepo': 210, sub: 'スプラッシュシールド', 'hitosoku': 0.20, 'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.21 };
  var tyazyabetu = { 'mainseinou': '(最小)攻撃力アップ', 'mainink': 18, 'supe': 'イカスフィア', 'supepo': 190, sub: 'スプリンクラー', 'hitosoku': 0.20, 'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.21 };
  var tyazyabetusuko = { 'mainseinou': '(最小)攻撃力アップ', 'mainink': 18, 'supe': 'イカスフィア', 'supepo': 190, sub: 'スプリンクラー', 'hitosoku': 0.20, 'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.21 };

  var soi = { 'mainseinou': '(最小)攻撃力アップ', 'mainink': 15, 'supe': 'スーパーチャクチ', 'supepo': 160, sub: 'キューバンボム', 'hitosoku': 0.30, 'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.31 };
  var soikasu = { 'mainseinou': '(最小)攻撃力アップ', 'mainink': 15, 'supe': 'ジェットパック', 'supepo': 170, sub: 'カーリングボム', 'hitosoku': 0.30, 'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.31 };

  var sukuiku = { 'mainseinou': '射程アップ', 'mainink': 10.5, 'supe': 'インクアーマー', 'supepo': 170, sub: 'ポイントセンサー', 'hitosoku': 0.30, 'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.31 };
  var sukuikubeta = { 'mainseinou': '射程アップ', 'mainink': 10.5, 'supe': 'イカスフィア', 'supepo': 180, sub: 'ロボットボム', 'hitosoku': 0.30, 'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.31 };
  var sukuikugannma = { 'mainseinou': '射程アップ', 'mainink': 10.5, 'supe': 'ジェットパック', 'supepo': 180, sub: 'キューバンボム', 'hitosoku': 0.30, 'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.31 };

  var bake = { 'mainseinou': '最大ダメージ範囲アップ', 'mainink': 7, 'supe': 'マルチミサイル', 'supepo': 190, sub: 'キューバンボム', 'hitosoku': 0.40 };
  var bakedeko = { 'mainseinou': '最大ダメージ範囲アップ', 'mainink': 7, 'supe': 'イカスフィア', 'supepo': 220, sub: 'スプリンクラー', 'hitosoku': 0.40 };
  var bakesoda = { 'mainseinou': '最大ダメージ範囲アップ', 'mainink': 7, 'supe': 'クイックボムピッチャー', 'supepo': 210, sub: 'スプラッシュボム', 'hitosoku': 0.40 };

  var hissen = { 'mainseinou': '塗り性能アップ', 'mainink': 6, 'supe': 'インクアーマー', 'supepo': 190, sub: 'クイックボム', 'hitosoku': 0.66 };
  var hissenhyu = { 'mainseinou': '塗り性能アップ', 'mainink': 6, 'supe': 'アメフラシ', 'supepo': 170, sub: 'スプラッシュボム', 'hitosoku': 0.66 };

  var sukusuro = { 'mainseinou': '塗り性能アップ', 'mainink': 8.4, 'supe': 'ハイパープレッサー', 'supepo': 190, sub: 'ロボットボム', 'hitosoku': 0.7 };
  var sukusuroneo = { 'mainseinou': '塗り性能アップ', 'mainink': 8.4, 'supe': 'スプラッシュボムピッチャー', 'supepo': 180, sub: 'ポイントセンサー', 'hitosoku': 0.7 };
  var sukusurobetu = { 'mainseinou': '塗り性能アップ', 'mainink': 8.4, 'supe': 'スーパーチャクチ', 'supepo': 190, sub: 'タンサンボム', 'hitosoku': 0.7 };

  var ofuro = { 'mainseinou': '塗り性能アップ', 'mainink': 8, 'supe': 'アメフラシ', 'supepo': 180, sub: 'スプラッシュシールド', 'hitosoku': 0.5 };
  var ofurodeko = { 'mainseinou': '塗り性能アップ', 'mainink': 8, 'supe': 'キューバンボムピッチャー', 'supepo': 190, sub: 'スプリンクラー', 'hitosoku': 0.5 };

  var ex = { 'mainseinou': '塗り性能アップ', 'mainink': 11.7, 'supe': 'バブルランチャー', 'supepo': 210, sub: 'スプリンクラー', 'hitosoku': 0.45 };
  var exkasu = { 'mainseinou': '塗り性能アップ', 'mainink': 11.7, 'supe': 'イカスフィア', 'supepo': 220, sub: 'ポイントセンサー', 'hitosoku': 0.45 };

  var bareru = { 'mainseinou': '射程継続時間アップ', 'mainink': 0.625, 'supe': 'ハイパープレッサー', 'supepo': 200, sub: 'スプリンクラー', 'hitosoku': 0.45, 'tyazihitosoku': 0.62 };
  var barerudeko = { 'mainseinou': '射程継続時間アップ', 'mainink': 0.625, 'supe': 'バブルランチャー', 'supepo': 180, sub: 'スプラッシュシールド', 'hitosoku': 0.45, 'tyazihitosoku': 0.62 };
  var barerurimi = { 'mainseinou': '射程継続時間アップ', 'mainink': 0.625, 'supe': 'ナイスダマ', 'supepo': 190, sub: 'ポイントセンサー', 'hitosoku': 0.45, 'tyazihitosoku': 0.62 };

  var supusupi = { 'mainseinou': '射s程継続時間アップ', 'mainink': 0.9, 'supe': 'マルチミサイル', 'supepo': 210, sub: 'クイックボム', 'hitosoku': 0.86, 'tyazihitosoku': 0.72 };
  var supusupikora = { 'mainseinou': '射程継続時間アップ', 'mainink': 0.9, 'supe': 'アメフラシ', 'supepo': 180, sub: 'カーリングボム', 'hitosoku': 0.86, 'tyazihitosoku': 0.72 };
  var supusupibetu = { 'mainseinou': '射程継続時間アップ', 'mainink': 0.9, 'supe': 'ウルトラハンコ', 'supepo': 180, sub: 'ポイズンミスト', 'hitosoku': 0.86, 'tyazihitosoku': 0.72 };

  var kugel = { 'mainseinou': '攻撃力アップ', 'mainink': 0.3125, 'supe': 'ジェットパック', 'supepo': 200, sub: 'ポイズンミスト', 'hitosoku': 0.86, 'tyazihitosoku': 0.86 };
  var kugeltyou = { 'mainseinou': '攻撃力アップ', 'mainink': 0.625, 'supe': 'ジェットパック', 'supepo': 200, sub: 'ポイズンミスト', 'hitosoku': 0.50, 'tyazihitosoku': 0.86 };
  var kugelhyu = { 'mainseinou': '攻撃力アップ', 'mainink': 0.3125, 'supe': 'アメフラシ', 'supepo': 220, sub: 'ジャンプビーコン', 'hitosoku': 0.86, 'tyazihitosoku': 0.86 };
  var kugelhyutyou = { 'mainseinou': '攻撃力アップ', 'mainink': 0.625, 'supe': 'アメフラシ', 'supepo': 220, sub: 'ジャンプビーコン', 'hitosoku': 0.50, 'tyazihitosoku': 0.86 };


  var nooti = { 'mainseinou': '射程継続時間アップ', 'mainink': 0.55, 'supe': 'イカスフィア', 'supepo': 180, sub: 'ポイントセンサー', 'hitosoku': 0.70, 'tyazihitosoku': 0.40 };
  var nootina = { 'mainseinou': '射程継続時間アップ', 'mainink': 0.55, 'supe': 'ジェットパック', 'supepo': 180, sub: 'キューバンボム', 'hitosoku': 0.70, 'tyazihitosoku': 0.40 };

  var supa = { 'mainseinou': '攻撃力アップ', 'mainink': 0.697, 'supe': 'キューバンボムピッチャー', 'supepo': 170, sub: 'ジャンプビーコン', 'hitosoku': 0.80 };
  var supahyu = { 'mainseinou': '攻撃力アップ', 'mainink': 0.697, 'supe': 'アメフラシ', 'supepo': 170, sub: 'ポイズンミスト', 'hitosoku': 0.80 };
  var supakuri = { 'mainseinou': '攻撃力アップ', 'mainink': 0.697, 'supe': 'スーパーチャクチ', 'supepo': 170, sub: 'トーピード', 'hitosoku': 0.80 };

  var kerubin = { 'mainseinou': '攻撃力アップ', 'mainink': 1.5, 'supe': 'ジェットパック', 'supepo': 180, sub: 'トラップ', 'hitosoku': 0.50 };
  var kerubindeko = { 'mainseinou': '攻撃力アップ', 'mainink': 1.5, 'supe': 'イカスフィア', 'supepo': 180, sub: 'スプラッシュシールド', 'hitosoku': 0.50 };
  var kerubinbetu = { 'mainseinou': '攻撃力アップ', 'mainink': 1.5, 'supe': 'インクアーマー', 'supepo': 180, sub: 'タンサンボム', 'hitosoku': 0.50 };

  var para = { 'mainseinou': 'カサ復活時間短縮', 'mainink': 5.5, 'supe': 'アメフラシ', 'supepo': 200, sub: 'スプリンクラー', 'hitosoku': 0.60, 'gardhitosoku': 0.40 };
  var parasore = { 'mainseinou': 'カサ復活時間短縮', 'mainink': 5.5, 'supe': 'スプラッシュボムピッチャー', 'supepo': 180, sub: 'ロボットボム', 'hitosoku': 0.60, 'gardhitosoku': 0.40 };

  var supai = { 'mainseinou': 'カサ復活時間短縮', 'mainink': 4, 'supe': 'スーパーチャクチ', 'supepo': 150, sub: 'トラップ', 'hitosoku': 0.72, 'gardhitosoku': 0.72 };
  var supaisore = { 'mainseinou': 'カサ復活時間短縮', 'mainink': 4, 'supe': 'イカスフィア', 'supepo': 180, sub: 'スプラッシュボム', 'hitosoku': 0.72, 'gardhitosoku': 0.72 };
  var supaibetu = { 'mainseinou': 'カサ復活時間短縮', 'mainink': 4, 'supe': 'インクアーマー', 'supepo': 200, sub: 'トーピード', 'hitosoku': 0.72, 'gardhitosoku': 0.72 };

  ///以下supulatoon3
  var supura3 = { 'mainseinou': '', 'mainink': 0.92, 'supe': 'ウルトラショット', 'supepo': 200, 'sub': 'キューバンボム', 'hitosoku': 0.72 ,};
  var prime3 = { 'mainseinou': '', 'mainink': 2, 'supe': 'カニタンク', 'supepo': 200, 'sub': 'ラインマーカー', 'hitosoku': 0.55 };

   var manyu3 = { 'mainseinou': '', 'mainink': 0.722, 'supe': 'カニタンク', 'supepo': 200, 'sub': 'キューバンボム', 'hitosoku': 0.80, 'suraido': 7.0 };

  var haidora3 = { 'mainseinou': '', 'mainink': 0.57, 'supe': 'ナイスダマ', 'supepo': 190, 'sub': 'ロボットボム', 'hitosoku': 0.60, 'tyazihitosoku': 0.40 };

     var nova3 = { 'mainseinou': '', 'mainink': 7.5, 'supe': 'ショクワンダー', 'supepo': 180, 'sub': 'スプラッシュボム', 'hitosoku': 0.50 };

     var dainamo3 = { 'mainseinou': '', 'mainink': 18, 'supe': 'エナジースタンド', 'supepo': 190, 'sub': 'スプリンクラー', 'hitosoku': 0.88 };

   var ritta3 = { 'mainseinou': '', 'mainink': 25, 'supe': 'ポップソナー', 'supepo': 200, 'sub': 'トラップ', 'hitosoku': 0.15, 'tyazihitosoku': 0.88, 'hantyazihitosoku': 0.16 };

   var rittasuko3 = { 'mainseinou': '', 'mainink': 25, 'supe': 'ポップソナー', 'supepo': 200, 'sub': 'トラップ', 'hitosoku': 0.15, 'tyazihitosoku': 0.88, 'hantyazihitosoku': 0.16 };

  var kyanpu3 = { 'mainseinou': '', 'mainink': 11, 'supe': 'キューインキ', 'supepo': 190, 'sub': 'ジャンプビーコン', 'hitosoku': 0.88 };

     var wakaba3 = { 'mainseinou': '', 'mainink': 0.45454545, 'supe': 'グレートバリア', 'supepo': 180, 'sub': 'スプラッシュボム', 'hitosoku': 0.72 };

     var garon523 = { 'mainseinou': '', 'mainink': 1.3, 'supe': 'メガホンレーザー', 'supepo': 200, 'sub': 'スプラッシュシールド', 'hitosoku': 0.60 };

     var zap3 = { 'mainseinou': '', 'mainink': 0.8, 'supe': 'エナジースタンド', 'supepo': 200, 'sub': 'キューバンボム', 'hitosoku': 0.84 };

   var modera3 = { 'mainseinou': '', 'mainink': 0.55, 'supe': 'サメライド', 'supepo': 200, 'sub': 'タンサンボム', 'hitosoku': 0.72 };

   var hot3 = { 'mainseinou': '', 'mainink': 10, 'supe': 'グレートバリア', 'supepo': 180, 'sub': 'ロボットボム', 'hitosoku': 0.45 };

     var rola3 = { 'mainseinou': '', 'mainink': 8.55, 'supe': 'グレートバリア', 'supepo': 180, 'sub': 'カーリングボム', 'hitosoku': 0.96 };

   var paburo3 = { 'mainseinou': '', 'mainink': 2, 'supe': 'メガホンレーザー', 'supepo': 180, 'sub': 'スプラッシュボム', 'hitosoku': 1.04 };

     var hokusai3 = { 'mainseinou': '', 'mainink': 3.2, 'supe': 'ショクワンダー', 'supepo': 200, 'sub': 'キューバンボム', 'hitosoku': 0.96 };

     var tyazya3 = { 'mainseinou': '', 'mainink': 18, 'supe': 'キューインキ', 'supepo': 200, 'sub': 'スプラッシュボム', 'hitosoku': 0.20, 'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.21 };

   var tyazyasuko3 = { 'mainseinou': '', 'mainink': 18, 'supe': 'キューインキ', 'supepo': 200, 'sub': 'スプラッシュボム', 'hitosoku': 0.20, 'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.21 };

     var bake3 = { 'mainseinou': '', 'mainink': 7, 'supe': 'トリプルトルネード', 'supepo': 200, 'sub': 'スプラッシュボム', 'hitosoku': 0.40 };

     var hissen3 = { 'mainseinou': '', 'mainink': 6, 'supe': 'ジェットパック', 'supepo': 190, 'sub': 'ポイズンミスト', 'hitosoku': 0.66 };

     var bareru3 = { 'mainseinou': '', 'mainink': 0.625, 'supe': 'ポップソナー', 'supepo': 200, 'sub': 'スプリンクラー', 'hitosoku': 0.45, 'tyazihitosoku': 0.62 };

  var para3 = { 'mainseinou': '', 'mainink': 6.325, 'supe': 'トリプルトルネード', 'supepo': 200, 'sub': 'スプリンクラー', 'hitosoku': 0.60, 'gardhitosoku': 0.40 };

   var sutori3 = { 'mainseinou': '', 'mainink': 5, 'supe': 'メガホンレーザー', 'supepo': 200, 'sub': 'ポイズンミスト', 'hitosoku':  0.20,  'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.21 };

  var   waipa3 = { 'mainseinou': '', 'mainink': 5, 'supe': ' ウルトラハンコ', 'supepo': 180, 'sub': 'トーピード', 'hitosoku': 0.72, 'gardhitosoku': 0.72 };


  //ブキ選択後のoptionvalue取り出し

  var buki = this.state.buki;

  //各ギア数を取り出し
  var ikasokumainG = this.state.ikasokumain;
  var ikasokumainsubG = this.state.ikasokumainsub;
  var ikasokumainhiG = this.state.ikasokumainhi;
  var ikasokusubhiG = this.state.ikasokusubhi;
  var hisokumainG = this.state.hisokumain;
  var hitosokusubG =  this.state.hitosokusub;
  var hisokumainhiG = this.state.hisokumainhi;
  var hitosokusubhiG =  this.state.hitosokusubhi;


  //ギアのGP割り出し
  var ikasokutotal = parseInt(ikasokumainG) + parseInt(ikasokumainsubG );
  var hitosokutotal = parseInt(hisokumainG) + parseInt(hitosokusubG );

  var ikasokutotalhi = parseInt(ikasokumainhiG) + parseInt(ikasokusubhiG);
  var hitosokutotahi = parseInt(hisokumainhiG) + parseInt(hitosokusubhiG);




var ikasokuanime = this.ikasoku.current;
var ikasokuanime2 = this.ikasoku2.current;
var ikaninanime = this.ikanin.current;
var hisyahitosokuanime = this.hitosoku.current;
var hisyahitosokuanime2 = this.hitosoku2.current;
var syahitosoku = this.hitosoku3.current;
var syahitosoku2 = this.hitosoku4.current;
//軽量ブキ処理始め


 if (buki == "borudo" || buki == "borudoneo" || buki == "borudosebun" || buki == "supa" || buki == "supahyu" || buki == "supakuri" || buki == "wakaba" || buki == "momizi" || buki == "otiba" || buki == "modera" || buki == "moderakin" || buki == "moderadou" || buki == "syapuneo" || buki == "syapu" || buki == "zap" || buki == "zapaka" || buki == "zapfami" || buki == "kura" || buki == "kuraneo" || buki == "nova" || buki == "novaneo" || buki == "novabe" || buki == "paburo" || buki == "paburohyu" || buki == "paburopama" || buki == "kabon" || buki == "kabondeko" || buki == "hissen" || buki == "hissenhyu" || buki == "supaisore" || buki == "supaibetu" || buki == "take" || buki == "takehei" || buki == "takeotu"){

//イカ速計算＆出力
  var ikasokudokei = 2.016 + 0.384 * 0.8* (0.033 * ikasokutotal - 0.00027 * ikasokutotal ** 2)*0.9;
  this.state.sokudoika = Math.round(ikasokudokei * 1000) / 1000 + "x/ﾌﾚｰﾑ";

//イカ速アニメ処理

  ikasokuanime.style.animationDuration =  100 / ikasokudokei / 60 + "s";

//非射撃時ヒト速計算＆出力
  var hitosokudokei = 1.04 + 0.4 * (0.033 * hitosokutotal - 0.00027 * hitosokutotal ** 2);
  this.state.sokudohito　= Math.round(hitosokudokei * 1000) / 1000 + "x/ﾌﾚｰﾑ";

//非射撃自ヒト速アニメ処理
hisyahitosokuanime.style.animationDuration = 100 / hitosokudokei / 60 + "s"


//比較用イカ速計算＆出力
var ikasokudokeihika = 2.016 + 0.384 * 0.8* (0.033 * ikasokutotalhi  - 0.00027 * ikasokutotalhi  ** 2)*0.9;
this.state.sokudoikahikaku = Math.round(ikasokudokeihika * 1000) / 1000 + "x/ﾌﾚｰﾑ";

//比較用イカ速アニメ
ikasokuanime2.style.animationDuration =  100 / ikasokudokeihika / 60 + "s";


 //イカニンジャ
 var ikanin = ikasokudokeihika * 0.9;
 this.state.sokudoikanin =  Math.round(ikanin * 1000) / 1000 + "x/ﾌﾚｰﾑ";

//イカニンジャアニメ
ikaninanime.style.animationDuration =  100 / ikanin / 60 + "s";

//比較用非射撃ヒト速
var hitosokudokeihika = 1.04 + 0.4 * (0.033 * hitosokutotahi- 0.00027 * hitosokutotahi** 2);
this.state.sokudohitohikaku = Math.round(hitosokudokeihika * 1000) / 1000 + "x/ﾌﾚｰﾑ";

//比較用非射撃ヒト速アニメ
hisyahitosokuanime2 .style.animationDuration  = 100 / hitosokudokeihika / 60 + "s";


///軽量ブキ終わり

///重量ブキ計算始め
 }else if (buki == "haidora" || buki == "haidorakasu" || buki == "dainamo" || buki == "dainamotesu" || buki == "dainamobe" || buki == "kyanpu" || buki == "kyanpusore" || buki == "kyanpukamo" || buki == "ex" || buki == "exkasu" || buki == "ritta"|| buki == "rittasuko" || buki == "rittakasu" || buki == "rittakasusuko") {

//重量ブキイカ速
  var ikasokudozyuu = 1.728 + 0.672 *0.8* (0.033 * ikasokutotal - 0.00027 * ikasokutotal ** 2)*0.9;
  this.state.sokudoika = Math.round(ikasokudozyuu * 1000) / 1000 + "x/ﾌﾚｰﾑ";

//重量ブキイカ速アニメ
  ikasokuanime.style.animationDuration =  100 / ikasokudozyuu / 60 + "s";

///イカ速比較用
    var ikasokudokeihika = 1.728 + 0.672 *0.8* (0.033 * ikasokutotalhi- 0.00027 * ikasokutotalhi ** 2)*0.9;
    this.state.sokudoikahikaku = Math.round(ikasokudokeihika * 1000) / 1000 + "x/ﾌﾚｰﾑ";

///イカ速比較用アニメ
ikasokuanime2.style.animationDuration =  100 / ikasokudokeihika  / 60 + "s";


/// イカニンジャ
var ikanin = ikasokudokeihika * 0.9;
this.state.sokudoikanin =  Math.round(ikanin * 1000) / 1000 + "x/ﾌﾚｰﾑ";
///イカニンジャアニメ
ikaninanime.style.animationDuration =  100 / ikanin / 60 + "s";

///ヒト速1
var hitosokudokei = 0.88 + 0.56 * (0.033 *  hitosokutotal - 0.00027 *  hitosokutotal ** 2);
this.state.sokudohito　= Math.round(hitosokudokei * 1000) / 1000 + "x/ﾌﾚｰﾑ";

//非射撃自ヒト速アニメ処理
hisyahitosokuanime.style.animationDuration = 100 / hitosokudokei / 60 + "s"


 ///比較用ヒト速
var hitosokudokeihika =  0.88 + 0.56 * (0.033 *  hitosokutotahi - 0.00027 * hitosokutotahi ** 2);
this.state.sokudohitohikaku = Math.round(hitosokudokeihika * 1000) / 1000 + "x/ﾌﾚｰﾑ";

///比較用ヒト速アニメ
hisyahitosokuanime2 .style.animationDuration  = 100 / hitosokudokeihika / 60 + "s";
///重量終わり


}
///中量ブキ始め（残りは中量のみなのでelse）
else {

  ///イカ速
   var ikasokutyu = 1.92 + 0.48 * 0.8 *(0.033 * ikasokutotal - 0.00027 * ikasokutotal ** 2)*0.9;
  this.state.sokudoika = Math.round(ikasokutyu * 1000) / 1000 + "x/ﾌﾚｰﾑ";

  ///イカ速アニメ
  ikasokuanime.style.animationDuration =  100 / ikasokutyu  / 60 + "s";
///比較イカ速
var ikasokutyuH = 1.92 + 0.48 * 0.8 *(0.033 * ikasokutotalhi - 0.00027 * ikasokutotalhi ** 2)*0.9;
this.state.sokudoikahikaku = Math.round(ikasokutyuH * 1000) / 1000 + "x/ﾌﾚｰﾑ";

///イカ速比較用アニメ
ikasokuanime2.style.animationDuration =  100 / ikasokutyuH / 60 + "s";

///イカニンジャ
var ikanin = ikasokutyuH * 0.9
this.state.sokudoikanin =  Math.round(ikanin * 1000) / 1000 + "x/ﾌﾚｰﾑ";
///イカニンジャアニメ
ikaninanime.style.animationDuration =  100 / ikanin / 60 + "s";


///ヒト速
var hitosokudotyu = 0.96 + 0.48 * (0.033 * hitosokutotal - 0.00027 * hitosokutotal ** 2);
this.state.sokudohito　= Math.round(hitosokudotyu * 1000) / 1000 + "x/ﾌﾚｰﾑ";

//非射撃自ヒト速アニメ処理
hisyahitosokuanime.style.animationDuration = 100 / hitosokudotyu / 60 + "s"

///比較用ヒト速
var hitosokudotyuH = 0.96 + 0.48 * (0.033 * hitosokutotahi - 0.00027 * hitosokutotahi  ** 2);
this.state.sokudohitohikaku = Math.round(hitosokudotyuH * 1000) / 1000 + "x/ﾌﾚｰﾑ";

///比較用ヒト速アニメ
hisyahitosokuanime2 .style.animationDuration  = 100 / hitosokudotyuH / 60 + "s";

}
///中量ブキ終わり


///射撃ヒト速始め
var syagekihitosoku = eval(buki)['hitosoku'];

///ノーチラス
///ヒト速
if (buki == "nooti" || buki == "nootina") {
    var nootibuki = parseFloat(syagekihitosoku) * (1 + 0.3 * (0.033 * hitosokutotal - 0.00027 * hitosokutotal ** 2));
this.state.syagekisokudohito = Math.round(nootibuki * 1000) / 1000 + "x/ﾌﾚｰﾑ";
///ヒト速  アニメ
syahitosoku.style.animationDuration =  100 / nootibuki  / 60 + "s";

///比較用ヒト速
var nootibuki2 = parseFloat(syagekihitosoku) * (1 + 0.3 * (0.033 * hitosokutotahi - 0.00027 * hitosokutotahi ** 2));
this.state.syagekisokudohitohikaku = Math.round(nootibuki2 * 1000) / 1000 + "x/ﾌﾚｰﾑ";

///比較用ヒト速アニメ
syahitosoku2.style.animationDuration =  100 / nootibuki2  / 60 + "s";

}

///スプスピ
else if( buki == "supusupi" || buki == "supusupikora" || buki == "supusupibetu") {

  var nootibuki = parseFloat(syagekihitosoku) * (1 + 0.4 * (0.033 * hitosokutotal - 0.00027 * hitosokutotal ** 2));
this.state.syagekisokudohito = Math.round(nootibuki * 1000) / 1000 + "x/ﾌﾚｰﾑ";
///ヒト速  アニメ
syahitosoku.style.animationDuration =  100 / nootibuki  / 60 + "s";

///比較用ヒト速
var nootibuki2 = parseFloat(syagekihitosoku) * (1 + 0.4 * (0.033 * hitosokutotahi - 0.00027 * hitosokutotahi ** 2));
this.state.syagekisokudohitohikaku = Math.round(nootibuki2 * 1000) / 1000 + "x/ﾌﾚｰﾑ";

///比較用ヒト速アニメ
syahitosoku2.style.animationDuration =  100 / nootibuki2  / 60 + "s";
}

///バレル・ハイドラ
else if(buki == "bareru" || buki == "barerudeko" || buki == "barerurimi" || buki == "haidora" || buki == "haidorakasu") {

    var nootibuki = parseFloat(syagekihitosoku) * (1 + 0.35 * (0.033 * hitosokutotal - 0.00027 * hitosokutotal ** 2));
  this.state.syagekisokudohito = Math.round(nootibuki * 1000) / 1000 + "x/ﾌﾚｰﾑ";
  ///ヒト速  アニメ
  syahitosoku.style.animationDuration =  100 / nootibuki  / 60 + "s";

  ///比較用ヒト速
  var nootibuki2 = parseFloat(syagekihitosoku) * (1 + 0.35 * (0.033 * hitosokutotahi - 0.00027 * hitosokutotahi ** 2));
  this.state.syagekisokudohitohikaku = Math.round(nootibuki2 * 1000) / 1000 + "x/ﾌﾚｰﾑ";

  ///比較用ヒト速アニメ
  syahitosoku2.style.animationDuration =  100 / nootibuki2  / 60 + "s";

}

////その他ブキ
else {

    ///ヒト速
    var abuki = parseFloat(syagekihitosoku) * (1 + 0.25 * (0.033 * hitosokutotal - 0.00027 * hitosokutotal ** 2));
    this.state.syagekisokudohito = Math.round(abuki * 1000) / 1000 + "x/ﾌﾚｰﾑ";
    ///ヒト速  アニメ
      syahitosoku.style.animationDuration =  100 /  abuki  / 60 + "s";
///   比較用
    var bbuki = parseFloat(syagekihitosoku) * (1 + 0.25 * (0.033 * hitosokutotahi - 0.00027 * hitosokutotahi ** 2));
    this.state.syagekisokudohitohikaku  = Math.round(bbuki * 1000) / 1000 + "x/ﾌﾚｰﾑ";
      ///比較用　ヒト速  アニメ
      syahitosoku2.style.animationDuration =  100 /  bbuki  / 60 + "s";


}





}

 handleSubmitsonota(event){
   const target = event.target;

   const name = target.name;
   this.setState({[name]: target.value})

 event.preventDefault();


 //各ギア数を取り出し
 var bakumainG  = this.state.bakugenmain;
 var bakusubG = this.state.bakugensub;
 var tekiinkmainG  = this.state.annzennmain;
 var tekisubG  = this.state.annzennsub;
 var huukatumainG  = this.state.huukatumain;
 var huukatusubG  = this.state.huukatusub;
 var zyanpumainG =   this.state.supatanmain;
 var zyanpusubG  = this.state.supatansub;
 var supegenmainG =  this.state.supegenmain;
 var supegensubG  = this.state.supegensub;


 //ギアのGP割り出し
 var bakutotal = parseInt(bakumainG ) + parseInt(bakusubG );
 var tekiinktotal = parseInt( tekiinkmainG) + parseInt(tekisubG);
  var huukatutotal = parseInt(huukatumainG) + parseInt(huukatusubG);
  var zyanputotal = parseInt(zyanpumainG) + parseInt(zyanpusubG );
  var supegentotal = parseInt(supegenmainG) + parseInt(supegensubG);

///爆減処理はじめ


var bakusuperitu = ((0.033 * bakutotal - 0.00027 * bakutotal ** 2) * 35) / 100
  var bakukuiboritu = ((0.033 * bakutotal - 0.00027 * bakutotal ** 2) * 40) / 100
  var bakubomuritu = ((0.033 * bakutotal - 0.00027 * bakutotal ** 2) * 50) / 100

  var zyettopackkin = 50 - (50 * bakusuperitu);
  var zyettopacken = 30 - (30 * bakusuperitu);

  var sufiaatack = 50 - (50 * bakusuperitu);
  var sufiaen = 55 - (55 * bakusuperitu);

  var baburuen = 50 - (50 * bakusuperitu);

  var kuibotyoku = 60 - (60 * bakukuiboritu);
  var kuibokin = 35 - (35 * bakukuiboritu);
  var kuiboen = 25 - (25 * bakukuiboritu);

  var bomuen = 30 - (30 * bakubomuritu);

///ジェットパック
  this.state.zyettoP = "(近爆風)" + Math.round(zyettopackkin * 10) / 10 + "ﾀﾞﾒｰｼﾞ" + "(遠爆風)" + Math.round(zyettopacken * 10) / 10 + "ﾀﾞﾒｰｼﾞ";

///イカスフィア
  this.state.sufiaAT = "(体当たり)" + Math.round(sufiaatack * 10) / 10 + "ﾀﾞﾒｰｼﾞ" + "(遠爆風)" + Math.round(sufiaen * 10) / 10 + "ﾀﾞﾒｰｼﾞ";

///バブル
this.state.baburuE = "(遠爆風)" + Math.round(baburuen * 10) / 10 + "ﾀﾞﾒｰｼﾞ";

///クイックボム
this.state.kuibo =  "(直)" + Math.round(kuibotyoku * 10) / 10 + "ﾀﾞﾒｰｼﾞ" + "(近)" + Math.round(kuibokin * 10) / 10 + "ﾀﾞﾒｰｼﾞ" + "(遠)" + Math.round(kuiboen * 10) / 10 + "ﾀﾞﾒｰｼﾞ";

///その他ボム
this.state.bomu = "(遠爆風)" + Math.round(bomuen * 10) / 10 + "ﾀﾞﾒｰｼﾞ"

///爆減処理終わり

///安全靴処理始め

///スリップダメージ
 var suripdame = 0.3 - 0.15 * (0.033 * tekiinktotal - 0.00027 * tekiinktotal ** 2)
this.state.tekiink = Math.floor(suripdame * 100) / 100 + "ﾀﾞﾒｰｼﾞ"

///スリップダメージ最大値
var damemax = 40 - 20 * (0.033 * tekiinktotal - 0.00027 * tekiinktotal ** 2);
this.state.tekiinkmax= Math.floor(damemax * 10) / 10 + "ﾀﾞﾒｰｼﾞ"

///無敵時間
var aaa = Math.log(2 / 3)
 var bbb = Math.log(0.5)
 var mukouzikan = 39 * (0.033 * tekiinktotal - 0.00027 * tekiinktotal ** 2) ** (aaa / bbb)
 this.state.tekiinkmukou = Math.round((mukouzikan / 60) * 1000) / 1000 + "秒"

///敵インク内移動速度
var tekiinkidou = 0.24 + 0.48 * (0.033 * tekiinktotal - 0.00027 * tekiinktotal ** 2)
this.state.tekiinksokudo = Math.round(tekiinkidou * 1000) / 1000 + "x/1ﾌﾚｰﾑ"

///安全靴処理終わり

///復活短縮始め

///通常時
var huX = 90 - 60 * (0.033 * huukatutotal - 0.00027 * huukatutotal ** 2)
var huY = 270 - 180 * (0.033 * huukatutotal - 0.00027 * huukatutotal ** 2)
 this.state.fuukatu = Math.floor((Math.floor(huX + huY + 150) / 60) * 100) / 100 + "秒" + "(自ｷﾞｱがﾍﾟﾅｱｯﾌﾟの場合+1.17秒)"

///敵ペナアップ
var huXpe = 90 + 45 - 60 * (0.033 * (huukatutotal * 0.15) - 0.00027 * (huukatutotal * 0.15) ** 2)
var huYpe = 270 - 180 * (0.033 * (huukatutotal * 0.15) - 0.00027 * (huukatutotal * 0.15) ** 2)
this.state.fuukatupena= Math.floor((Math.floor(huXpe + huYpe + 150) / 60) * 100) / 100 + "秒" + "(自ｷﾞｱがﾍﾟﾅｱｯﾌﾟの場合+1.17秒)"

///復活短縮終わり

///ジャン短始め

var ccc = Math.log(0.75)
 var ddd = Math.log(0.5)
 var zyanputaikiika = 1 + 80 - 60 * (0.033 * zyanputotal - 0.00027 * zyanputotal ** 2) ** (ccc / ddd);
 var zyanputaikihito = 22 + 80 - 60 * (0.033 * zyanputotal - 0.00027 * zyanputotal ** 2) ** (ccc / ddd);

 var eee = Math.log(19 / 138)

 var zyanpuzikan = 138 - 41.4 * (0.033 * zyanputotal - 0.00027 * zyanputotal ** 2) ** (eee / ddd);

this.state.taiki = Math.floor(zyanputaikiika / 60 * 100) / 100 + "秒"
this.state.taikihito = Math.floor(zyanputaikihito / 60 * 100) / 100 + "秒"
this.state.zyanpu = Math.floor(zyanpuzikan / 60 * 100) / 100 + "秒"
this.state.zyanpugoukei = Math.floor((zyanputaikiika + zyanpuzikan) / 60 * 100) / 100 + "秒"
this.state.zyanpugoukeiaa= Math.floor((zyanputaikihito + zyanpuzikan) / 60 * 100) / 100 + "秒"

///ジャン短終わり

///スぺ減始め
var supeyou1 = Math.log(0.6);
  var supeyou2 = Math.log(0.5);

  var supegenkouka = 50 - 50 * (0.033 * (supegentotal * 1) - 0.00027 * (supegentotal * 1) ** 2) ** (supeyou1 / supeyou2);
  var supegenkouka2 = 50 + 15 - 50 * (0.033 * (supegentotal * 0.7) - 0.00027 * (supegentotal * 0.7) ** 2) ** (supeyou1 / supeyou2);

  if (supegenkouka < 0) {
  this.state.supegen = 0;
  } else {
    this.state.supegen= Math.round(supegenkouka * 100) / 100 + "%";
  }

this.state.supegen2 = Math.round(supegenkouka2 * 100) / 100 + "%";

 }

handleSubmit4(event){
  const target = event.target;

  const name = target.name;
  this.setState({[name]: target.value})

event.preventDefault();

var aM = this.atamaMainimg.current;
var aS1 = this.atamaSub1img.current;
var aS2 = this.atamaSub2img.current;
var aS3 = this.atamaSub3img.current;
var hA = this.hukuMainimg.current;
var hS1 = this.hukuSub1img.current;
var hS2 = this.hukuSub2img.current;
var hS3 = this.hukuSub3img.current;
var kM = this.kutuMainimg.current;
var kS1 = this.kutuSub1img.current;
var kS2 = this.kutuSub2img.current;
var kS3 = this.kutuSub3img.current;

var supura = { 'mainseinou': '', 'mainink': 0.92, 'supe': 'ウルトラショット', 'supepo': 200, 'sub': 'キューバンボム', 'hitosoku': 0.72 ,};
var prime3= { 'mainseinou': '', 'mainink': 2, 'supe': 'カニタンク', 'supepo': 200, 'sub': 'ラインマーカー', 'hitosoku': 0.55 };

 var manyu = { 'mainseinou': '', 'mainink': 0.722, 'supe': 'カニタンク', 'supepo': 200, 'sub': 'キューバンボム', 'hitosoku': 0.80, 'suraido': 7.0 };

var haidora = { 'mainseinou': '', 'mainink': 0.57, 'supe': 'ナイスダマ', 'supepo': 190, 'sub': 'ロボットボム', 'hitosoku': 0.60, 'tyazihitosoku': 0.40 };

   var nova = { 'mainseinou': '', 'mainink': 7.5, 'supe': 'ショクワンダー', 'supepo': 180, 'sub': 'スプラッシュボム', 'hitosoku': 0.50 };

   var dainamo = { 'mainseinou': '', 'mainink': 18, 'supe': 'エナジースタンド', 'supepo': 190, 'sub': 'スプリンクラー', 'hitosoku': 0.88 };

 var ritta = { 'mainseinou': '', 'mainink': 25, 'supe': 'ポップソナー', 'supepo': 200, 'sub': 'トラップ', 'hitosoku': 0.15, 'tyazihitosoku': 0.88, 'hantyazihitosoku': 0.16 };

 var rittasuko = { 'mainseinou': '', 'mainink': 25, 'supe': 'ポップソナー', 'supepo': 200, 'sub': 'トラップ', 'hitosoku': 0.15, 'tyazihitosoku': 0.88, 'hantyazihitosoku': 0.16 };

var kyanpu = { 'mainseinou': '', 'mainink': 11, 'supe': 'キューインキ', 'supepo': 190, 'sub': 'ジャンプビーコン', 'hitosoku': 0.88 };

   var wakaba = { 'mainseinou': '', 'mainink': 0.45454545, 'supe': 'グレートバリア', 'supepo': 180, 'sub': 'スプラッシュボム', 'hitosoku': 0.72 };

   var garon52 = { 'mainseinou': '', 'mainink': 1.3, 'supe': 'メガホンレーザー', 'supepo': 200, 'sub': 'スプラッシュシールド', 'hitosoku': 0.60 };

   var zap = { 'mainseinou': '', 'mainink': 0.8, 'supe': 'エナジースタンド', 'supepo': 200, 'sub': 'キューバンボム', 'hitosoku': 0.84 };

 var modera = { 'mainseinou': '', 'mainink': 0.55, 'supe': 'サメライド', 'supepo': 200, 'sub': 'タンサンボム', 'hitosoku': 0.72 };

 var hot= { 'mainseinou': '', 'mainink': 10, 'supe': 'グレートバリア', 'supepo': 180, 'sub': 'ロボットボム', 'hitosoku': 0.45 };

   var rola = { 'mainseinou': '', 'mainink': 8.55, 'supe': 'グレートバリア', 'supepo': 180, 'sub': 'カーリングボム', 'hitosoku': 0.96 };

 var paburo = { 'mainseinou': '', 'mainink': 2, 'supe': 'メガホンレーザー', 'supepo': 180, 'sub': 'スプラッシュボム', 'hitosoku': 1.04 };

   var hokusai = { 'mainseinou': '', 'mainink': 3.2, 'supe': 'ショクワンダー', 'supepo': 200, 'sub': 'キューバンボム', 'hitosoku': 0.96 };

   var tyazya = { 'mainseinou': '', 'mainink': 18, 'supe': 'キューインキ', 'supepo': 200, 'sub': 'スプラッシュボム', 'hitosoku': 0.20, 'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.21 };

 var tyazyasuko = { 'mainseinou': '', 'mainink': 18, 'supe': 'キューインキ', 'supepo': 200, 'sub': 'スプラッシュボム', 'hitosoku': 0.20, 'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.21 };

   var bake = { 'mainseinou': '', 'mainink': 7, 'supe': 'トリプルトルネード', 'supepo': 200, 'sub': 'スプラッシュボム', 'hitosoku': 0.40 };

   var hissen = { 'mainseinou': '', 'mainink': 6, 'supe': 'ジェットパック', 'supepo': 190, 'sub': 'ポイズンミスト', 'hitosoku': 0.66 };

   var bareru = { 'mainseinou': '', 'mainink': 0.625, 'supe': 'ポップソナー', 'supepo': 200, 'sub': 'スプリンクラー', 'hitosoku': 0.45, 'tyazihitosoku': 0.62 };

var para= { 'mainseinou': '', 'mainink': 6.325, 'supe': 'トリプルトルネード', 'supepo': 200, 'sub': 'スプリンクラー', 'hitosoku': 0.60, 'gardhitosoku': 0.40 };

 var sutori = { 'mainseinou': '', 'mainink': 5, 'supe': 'メガホンレーザー', 'supepo': 200, 'sub': 'ポイズンミスト', 'hitosoku':  0.20,  'tyazihitosoku': 0.96, 'hantyazihitosoku': 0.21 };

var   waipa = { 'mainseinou': '', 'mainink': 5, 'supe': ' ウルトラハンコ', 'supepo': 180, 'sub': 'トーピード', 'hitosoku': 0.72, 'gardhitosoku': 0.72 };

////総合力診断処理


///ギア選択のstate取得
var atamaMain =  this.state.atamaMainSelect;
var atamaSub1  =  this.state.atamaSub1Select;
var atamaSub2  =  this.state.atamaSub2Select;
var atamaSub3  =  this.state.atamaSub3Select;
var hukuMain  =  this.state.hukuMainSelect;
var hukuSub1  =  this.state.hukuSub1Select;
var hukuSub2  =  this.state.hukuSub2Select;
var hukuSub3  =  this.state.hukuSub3Select;
var kutuMain  =  this.state.kutuMainSelect;
var kutuSub1  =  this.state.kutuSub1Select;
var kutuSub2  =  this.state.kutuSub2Select;
var kutuSub3  =  this.state.kutuSub3Select;
///ギアの変数宣言
var mainInk = 0;
var subInk = 0;
var inkKaihuku = 0;
var hitosoku = 0;
var ikasoku = 0;
var supezou = 0;
var supeGen = 0
var supeSei = 0;
var hukkatuTan = 0;
var supaTan = 0;
var subSei = 0;
var aiteInk = 0;
var subEikyou = 0;
var akusyonKyouka = 0;
var startDa = 0;
var lastSpa = 0;
var gakkyou = 0;
var kamBack = 0;
var ikaNin = 0;
var ribenzi = 0;
var sarmal = 0;
var penaUp = 0;
var tuikaBai = 0;
var suterusu = 0;
var taibutu = 0;
var ukemi = 0;

///項目変数
var taimen = 0;
var nuri = 0;
var kidou = 0;
var bougai = 0;
var supesyaru = 0;
var suterusuA = 0;






///対面力処理
////アタマメイン処理
if (atamaMain=="0"||atamaMain=="10"||atamaMain=="11"||atamaMain=="12"||atamaMain=="17"||atamaMain=="15"||atamaMain=="20"||atamaMain=="19"||atamaMain=="8"||atamaMain=="25"){


    taimen = taimen +10



};

///アタマサブ１
if(atamaSub1=="0"||atamaSub1=="10"||atamaSub1=="11"||atamaSub1=="12"||atamaSub1=="17"||atamaSub1=="15"||atamaSub1=="20"||atamaSub1=="19"||atamaSub1=="8"||atamaSub1=="25"){
taimen =  taimen + 3 ;
};

///アタマサブ2
if(atamaSub2=="0"||atamaSub2=="10"||atamaSub2=="11"||atamaSub2=="12"||atamaSub2=="17"||atamaSub2=="15"||atamaSub2=="20"||atamaSub2=="19"||atamaSub2=="8"||atamaSub2=="25"){
taimen =  taimen + 3 ;
};

///アタマサブ3
if(atamaSub3=="0"||atamaSub3=="10"||atamaSub3=="11"||atamaSub3=="12"||atamaSub3=="17"||atamaSub3=="15"||atamaSub3=="20"||atamaSub3=="19"||atamaSub3=="8"||atamaSub3=="25"){
taimen =  taimen + 3 ;
};

///フクメイン
if(hukuMain=="0"||hukuMain=="10"||hukuMain=="11"||hukuMain=="12"||hukuMain=="17"||hukuMain=="15"||hukuMain=="20"||hukuMain=="19"||hukuMain=="8"||hukuMain=="25"){
  taimen = taimen +10
  if (hukuMain=="22"){
    taimen = 0
  }
}

///フクサブ1
if(hukuSub1=="0"||hukuSub1=="10"||hukuSub1=="11"||hukuSub1=="12"||hukuSub1=="17"||hukuSub1=="15"||hukuSub1=="20"||hukuSub1=="19"||hukuSub1=="8"||hukuSub1=="25"){
taimen =  taimen + 3 ;
if (hukuMain=="22"){
  taimen = taimen + 3
}
};

///フクサブ2
if(hukuSub2=="0"||hukuSub2=="10"||hukuSub2=="11"||hukuSub2=="12"||hukuSub2=="17"||hukuSub2=="15"||hukuSub2=="20"||hukuSub2=="19"||hukuSub2=="8"||hukuSub2=="25"){
taimen =  taimen + 3 ;
if (hukuMain=="22"){
  taimen = taimen + 3
}
};

///フクサブ3
if(hukuSub3=="0"||hukuSub3=="10"||hukuSub3=="11"||hukuSub3=="12"||hukuSub3=="17"||hukuSub3=="15"||hukuSub3=="20"||hukuSub3=="19"||hukuSub3=="8"||hukuSub3=="25"){
taimen =  taimen + 3 ;
if (hukuMain=="22"){
  taimen = taimen + 3
}
};

////  クツメイン処理
if (kutuMain=="0"||kutuMain=="10"||kutuMain=="11"||kutuMain=="12"||kutuMain=="17"||kutuMain=="15"||kutuMain=="20"||kutuMain=="19"||kutuMain=="8"||kutuMain=="25"){

    taimen = taimen +10

};
///クツサブ1
if(kutuSub1=="0"||kutuSub1=="10"||kutuSub1=="11"||kutuSub1=="12"||kutuSub1=="17"||kutuSub1=="15"||kutuSub1=="20"||kutuSub1=="19"||kutuSub1=="8"||kutuSub1=="25"){
taimen =  taimen + 3 ;

};

///クツサブ2
if(kutuSub2=="0"||kutuSub2=="10"||kutuSub2=="11"||kutuSub2=="12"||kutuSub2=="17"||kutuSub2=="15"||kutuSub2=="20"||kutuSub2=="19"||kutuSub2=="8"||kutuSub2=="25"){
taimen =  taimen + 3 ;
};

///クツサブ3
if(kutuSub3=="0"||kutuSub3=="10"||kutuSub3=="11"||kutuSub3=="12"||kutuSub3=="17"||kutuSub3=="15"||kutuSub3=="20"||kutuSub3=="19"||kutuSub3=="8"||kutuSub3=="25"){
taimen =  taimen + 3 ;
};


var gurafuTaimen = 0;

 gurafuTaimen = 9.9 * taimen - 8.1 * taimen^2 ;

data[0].A = gurafuTaimen ;
///対面力処理終了

///塗り処理開始
if (atamaMain=="0"||atamaMain=="1"||atamaMain=="2"||atamaMain=="17"||atamaMain=="15"){
    nuri = nuri +10
};

///アタマサブ１
if(atamaSub1=="0"||atamaSub1=="1"||atamaSub1=="2"||atamaSub1=="17"||atamaSub1=="15"){
nuri =  nuri + 3 ;
};

///アタマサブ2
if(atamaSub2=="0"||atamaSub2=="1"||atamaSub2=="2"||atamaSub2=="17"||atamaSub2=="15"){
nuri =  nuri + 3 ;
};

///アタマサブ3
if(atamaSub3=="0"||atamaSub3=="1"||atamaSub3=="2"||atamaSub3=="17"||atamaSub3=="15"){
nuri =  nuri + 3 ;
};

///フクメイン
if(hukuMain=="0"||hukuMain=="1"||hukuMain=="2"||hukuMain=="17"||hukuMain=="15"){
  nuri = nuri +10
  if (hukuMain=="22"){
    taimen = 0
  }
}

///フクサブ1
if(hukuSub1=="0"||hukuSub1=="1"||hukuSub1=="2"||hukuSub1=="17"||hukuSub1=="15"){
nuri =  nuri + 3 ;
if (hukuMain=="22"){
  nuri = nuri + 3
}
};

///フクサブ2
if(hukuSub2=="0"||hukuSub2=="1"||hukuSub2=="2"||hukuSub2=="17"||hukuSub2=="15"){
nuri =  nuri + 3 ;
if (hukuMain=="22"){
  nuri = nuri + 3
}
};

///フクサブ3
if(hukuSub3=="0"||hukuSub3=="1"||hukuSub3=="2"||hukuSub3=="17"||hukuSub3=="15"){
nuri =  nuri + 3 ;
if (hukuMain=="22"){
  nuri = nuri + 3
}
};

////  クツメイン処理
if (kutuMain=="0"||kutuMain=="1"||kutuMain=="2"||kutuMain=="17"||kutuMain=="15"){

    nuri = nuri +10

};
///クツサブ1
if(kutuSub1=="0"||kutuSub1=="1"||kutuSub1=="2"||kutuSub1=="17"||kutuSub1=="15"){
nuri =  nuri + 3 ;
};

///クツサブ2
if(kutuSub2=="0"||kutuSub2=="1"||kutuSub2=="2"||kutuSub2=="17"||kutuSub2=="15"){
nuri =  nuri + 3 ;
};

///クツサブ3
if(kutuSub3=="0"||kutuSub3=="1"||kutuSub3=="2"||kutuSub3=="17"||kutuSub3=="15"){
nuri =  nuri + 3 ;
};

var gurafuNuri = 0;

 gurafuNuri = 9.9 * nuri - 8.1 * nuri^2 ;


data[1].A = gurafuNuri ;
///塗り処理終了

///機動力処理開始
if (atamaMain=="4"||atamaMain=="3"||atamaMain=="17"||atamaMain=="9"||atamaMain=="14"||atamaMain=="13"){
    kidou = kidou +10
};

///アタマサブ１
if(atamaSub1=="4"||atamaSub1=="3"||atamaSub1=="17"||atamaSub1=="9"||atamaSub1=="14"||atamaSub1=="13"){
kidou =  kidou + 3 ;
};

///アタマサブ2
if(atamaSub2=="4"||atamaSub2=="3"||atamaSub2=="17"||atamaSub2=="9"||atamaSub2=="14"||atamaSub2=="13"){
kidou =  kidou + 3 ;
};

///アタマサブ3
if(atamaSub3=="4"||atamaSub3=="3"||atamaSub3=="17"||atamaSub3=="9"||atamaSub3=="14"||atamaSub3=="13"){
kidou =  kidou + 3 ;
};

///フクメイン
if(hukuMain=="4"||hukuMain=="3"||hukuMain=="17"||hukuMain=="9"||hukuMain=="14"||hukuMain=="13"){
  kidou = kidou +10
  if (hukuMain=="22"){
    taimen = 0
  }
}

///フクサブ1
if(hukuSub1=="4"||hukuSub1=="3"||hukuSub1=="17"||hukuSub1=="9"||hukuSub1=="14"||hukuSub1=="13"){
kidou =  kidou + 3 ;
if (hukuMain=="22"){
  kidou = kidou + 3
}
};

///フクサブ2
if(hukuSub2=="4"||hukuSub2=="3"||hukuSub2=="17"||hukuSub2=="9"||hukuSub2=="14"||hukuSub2=="13"){
kidou =  kidou + 3 ;
if (hukuMain=="22"){
  kidou = kidou + 3
}
};

///フクサブ3
if(hukuSub3=="4"||hukuSub3=="3"||hukuSub3=="17"||hukuSub3=="9"||hukuSub3=="14"||hukuSub3=="13"){
kidou =  kidou + 3 ;
if (hukuMain=="22"){
  kidou = kidou + 3
}
};

////  クツメイン処理
if (kutuMain=="4"||kutuMain=="3"||kutuMain=="17"||kutuMain=="9"||kutuMain=="14"||kutuMain=="13"){

    kidou = kidou +10

};
///クツサブ1
if(kutuSub1=="4"||kutuSub1=="3"||kutuSub1=="17"||kutuSub1=="9"||kutuSub1=="14"||kutuSub1=="13"){
kidou =  kidou + 3 ;
};

///クツサブ2
if(kutuSub2=="4"||kutuSub2=="3"||kutuSub2=="17"||kutuSub2=="9"||kutuSub2=="14"||kutuSub2=="13"){
kidou =  kidou + 3 ;
};

///クツサブ3
if(kutuSub3=="4"||kutuSub3=="3"||kutuSub3=="17"||kutuSub3=="9"||kutuSub3=="14"||kutuSub3=="13"){
kidou =  kidou + 3 ;
};

var gurafuKidou = 0;
if(hukuMain=="18"){
  kidou = kidou * 0.8
}
 gurafuKidou = 9.9 * kidou - 8.1 * kidou^2 ;

data[2].A = gurafuKidou ;

///機動力処理終了

///妨害処理開始


///フクメイン
if(hukuMain=="21"){
  bougai = bougai + 50
}


////  クツメイン処理
if (kutuMain=="24"){

  bougai = bougai + 50

};


var gurafuBougai = 0;

 gurafuBougai = bougai

data[4].A = gurafuBougai ;
///妨害処理終了


///スペシャル処理開始
if (atamaMain=="7"||atamaMain=="5"||atamaMain=="6"||atamaMain=="9"||atamaMain=="16"||atamaMain=="17"){
    supesyaru  = supesyaru  +10
};

///アタマサブ１
if(atamaSub1=="7"||atamaSub1=="5"||atamaSub1=="6"||atamaSub1=="9"||atamaSub1=="16"||atamaSub1=="17"){
supesyaru  =  supesyaru  + 3 ;
};

///アタマサブ2
if(atamaSub2=="7"||atamaSub2=="5"||atamaSub2=="6"||atamaSub2=="9"||atamaSub2=="16"||atamaSub2=="17"){
supesyaru  =  supesyaru  + 3 ;
};

///アタマサブ3
if(atamaSub3=="7"||atamaSub3=="5"||atamaSub3=="6"||atamaSub3=="9"||atamaSub3=="16"||atamaSub3=="17"){
supesyaru  =  supesyaru  + 3 ;
};

///フクメイン
if(hukuMain=="7"||hukuMain=="5"||hukuMain=="6"||hukuMain=="9"||hukuMain=="16"||hukuMain=="17"){
  supesyaru  = supesyaru  +10
  if (hukuMain=="22"){
    taimen = 0
  }
}

///フクサブ1
if(hukuSub1=="7"||hukuSub1=="5"||hukuSub1=="6"||hukuSub1=="9"||hukuSub1=="16"||hukuSub1=="17"){
supesyaru  =  supesyaru  + 3 ;
if (hukuMain=="22"){
  supesyaru = supesyaru + 3
}
};

///フクサブ2
if(hukuSub2=="7"||hukuSub2=="5"||hukuSub2=="6"||hukuSub2=="9"||hukuSub2=="16"||hukuSub2=="17"){
supesyaru  =  supesyaru  + 3 ;
if (hukuMain=="22"){
  supesyaru = supesyaru + 3
}
};

///フクサブ3
if(hukuSub3=="7"||hukuSub3=="5"||hukuSub3=="6"||hukuSub3=="9"||hukuSub3=="16"||hukuSub3=="17"){
supesyaru  =  supesyaru  + 3 ;
if (hukuMain=="22"){
  supesyaru = supesyaru + 3
}
};

////  クツメイン処理
if (kutuMain=="7"||kutuMain=="5"||kutuMain=="6"||kutuMain=="9"||kutuMain=="16"||kutuMain=="17"){

    supesyaru  = supesyaru  +10

};
///クツサブ1
if(kutuSub1=="7"||kutuSub1=="5"||kutuSub1=="6"||kutuSub1=="9"||kutuSub1=="16"||kutuSub1=="17"){
supesyaru  =  supesyaru  + 3 ;
};

///クツサブ2
if(kutuSub2=="7"||kutuSub2=="5"||kutuSub2=="6"||kutuSub2=="9"||kutuSub2=="16"||kutuSub2=="17"){
supesyaru  =  supesyaru  + 3 ;
};

///クツサブ3
if(kutuSub3=="7"||kutuSub3=="5"||kutuSub3=="6"||kutuSub3=="9"||kutuSub3=="16"||kutuSub3=="17"){
supesyaru  =  supesyaru  + 3 ;
};

var gurafuSupe = 0;

 gurafuSupe= 9.9 * supesyaru - 8.1 * supesyaru^2 ;

data[3].A =  gurafuSupe ;

///スペシャル処理終了
///ステルス処理開始
///フクメイン
if(hukuMain=="18"){
  suterusuA = suterusuA + 50
}
////  クツメイン処理
if (kutuMain=="23"){
  suterusuA = suterusuA + 50
};

var gurafuSuterusu = 0;

 gurafuSuterusu = suterusuA

data[5].A =  gurafuSuterusu;
///ステルス処理終了
 var sentouryoku  = 0 ;
 sentouryoku  = gurafuTaimen + gurafuNuri + gurafuKidou + gurafuBougai +  gurafuSupe + gurafuSuterusu
this.state.sentouRyoku = sentouryoku




}
render(){

  return (
      <Tabs className="tab1">



      <form onSubmit=""  >



<div>
<p className="title">
スプラトゥーン ギアパワー　ケイサンキ
</p>

<TabList>
<Tab>インク・スペシャル系</Tab>
<Tab>移動系</Tab>
<Tab>その他・特殊</Tab>
<Tab>【NEW】⇒イカパワー診断</Tab>
</TabList>
スプラトゥーン3 ver 1.1.2に対応。
サーバー代、運営費捻出のため広告を貼っています。ご了承ください。
<p>＜2022.10.4＞スプラトゥーン3に対応しました。</p>
<div className="a8Vtuver">
<a   target="_blank" href="https://px.a8.net/svt/ejp?a8mat=3NIQ4Z+B0IQFU+4F56+HX5B5" rel="nofollow">
<img border="0" width="320" height="50" alt="" src="https://www23.a8.net/svt/bgt?aid=220903379666&wid=002&eno=01&mid=s00000020625003010000&mc=1"/></a>
<img border="0" width="1" height="1" src="https://www14.a8.net/0.gif?a8mat=3NIQ4Z+B0IQFU+4F56+HX5B5" alt=""/>
</div>
<div className="bukisentaku">

    <div className="supuraVer">スプラトゥーン3</div>

    <select className="buki" name="buki" id="buki" value={this.state.buki} onChange={this.handleChange} onClick={this.handleChange} onBlur={this.handleChange}>

    <option value="sen">-----ブキをえらぶ-----</option>
    <option value="">-----シューター-----</option>

    <option value="supura" >スプラシューター</option>

    <option value="syapu">シャープマーカー</option>

    <option value="prime">プライムシューター</option>

    <option value="l3" >L3 リールガン</option>

    <option value="h3">H3 リールガン</option>

    <option value="botoru">ボトルガイザー</option>

    <option value="botoruren">ボトルガイザー 連射 </option>

    <option value="wakaba">わかばシューター</option>

    <option value="garon52" >.52ガロン</option>

    <option value="garon96">.96ガロン</option>

    <option value="zyetto">ジェットスイーパー</option>

    <option value="borudo">ボールドマーカー</option>


    <option value="zap" >N-ZAP85 クロ </option>


    <option value="modera" >プロモデラーMG ギン</option>

    <option value="">-----マニューバー-----</option>

    <option value="manyu" >スプラマニューバー】</option>


    <option value="kuaddo"  >クアッドホッパーブラック</option>

    <option value="duaru">デュアルスイーパー</option>

    <option value="supa">スパッタリー</option>

    <option value="kerubin">ケルビン525</option>

    <option value="">-----ブラスター-----</option>

    <option value="nova" >ノヴァブラスター </option>


    <option value="hot" >ホットブラスター</option>

    <option value="long">ロングブラスター</option>

    <option value="rapi">ラピッドブラスター</option>

        <option value="rapieri">Rブラスターエリート</option>


    <option value="kura">クラッシュブラスター</option>

    <option value="">-----ローラー系-----</option>
    <option value="dainamo">ダイナモローラー</option>



    <option value="rola"   >スプラローラー</option>

    <option value="kabon">カーボンローラー</option>

    <option value="varia">ヴァリアブルローラー</option>

    <option value="variatate">ヴァリアブルローラー（タテブリ）</option>
    <option value="">-----スピナー-----</option>
    <option value="haidora">ハイドラント</option>



    <option value="bareru">バレルスピナー</option>

    <option value="supusupi">スプラスピナー</option>

    <option value="kugel">クーゲルシュライバー 短</option>
    <option value="kugeltyou">クーゲルシュライバー 長</option>

    <option value="nooti">ノーチラス47</option>

    <option value="">-----チャージャー-----</option>
    <option value="take">14 式竹筒銃・甲</option>

    <option value="ritta" >リッター4K</option>

    <option value="rittasuko" >リッター4K スコープ</option>


    <option value="tyazya">スプラチャージャー</option>

    <option value="tyazyasuko">スプラチャージャースコープ</option>


    <option value="soi">ソイチューバー</option>

    <option value="sukuiku">スクイックリンα</option>

    <option value="">-----フデ-----</option>
    <option value="paburo">パブロ</option>


    <option value="hokusai">ホクサイ </option>

    <option value="">-----スロッシャー-----</option>
    <option value="bake">バケットスロッシャー</option>


    <option value="hissen">ヒッセン</option>

    <option value="sukusuro">スクリュースロッシャー</option>

    <option value="ofuro">オーバーフロッシャー</option>

    <option value="ex">エクスプロッシャー</option>

    <option value="">-----シェルター-----</option>
    <option value="kyanpu">キャンピングシェルター</option>


    <option value="para"  >パラシェルター</option>


    <option value="supai">スパイガジェット</option>

    <option value="">-----ストリンガー-----</option>
    <option value="sutori" >トライストリンガー</option>
    <option value="lact">LACT-450</option>
    <option value="">-----ワイパー-----</option>
    <option value="waipa" >ドライヴワイパー</option>
      <option value="zimwaipa" >ジムワイパー</option>
    </select>

    <p className="weponimg"><div className="mainimg" ref={this.mainimg}></div><div className="subsupe"><div className="subimg" ref={this.subimg}></div><div className="supeimg" ref={this.supeimg}></div></div></p>

    </div>
     <TabPanel >
<p></p>
    <div className="Header">
    <div className="main">
    メイン

    </div>
    <div className="sub">
    サブ
    </div>
    </div>


    <p className="Header">
    <div>
    <div className="mainink" ></div>
    </div>
    <select className="main" name="main" id="main" value={this.state.main} onChange={this.handleChange} >
    <option value="0">0</option>
    <option value="10">1</option>
    <option value="20">2</option>
    <option value="30">3</option>
    </select>
    <select className="sub"  name="inksub" id="inksub" value={this.state.inksub} onChange={this.handleChange} >
<option value="0">0</option>
<option value="3">1</option>
<option value="6">2</option>
<option value="9">3</option>
<option value="12">4</option>
<option value="15">5</option>
<option value="18">6</option>
<option value="21">7</option>
<option value="24">8</option>
<option value="27">9</option>
</select>
    </p>



    <p className="Header">
    <div>
    <img className="gearimg" src="https://topiblo.com/wp-content/uploads/2020/06/IMG_1104.png" alt="" width="64" height="64"
    />
    </div>

    <select className="main" name="submain" id="submain" value={this.state.submain} onChange={this.handleChange}   >
    <option value="0">0</option>
    <option value="10">1</option>
    <option value="20">2</option>
    <option value="30">3</option>
    </select>
    <select className="sub" name="subsub" id="subsub" value={this.state.subsub} onChange={this.handleChange}  >
    <option value="0">0</option>
    <option value="3">1</option>
    <option value="6">2</option>
    <option value="9">3</option>
    <option value="12">4</option>
    <option value="15">5</option>
    <option value="18">6</option>
    <option value="21">7</option>
    <option value="24">8</option>
    <option value="27">9</option>
    </select>
    </p>
    <p  className="Header">
      <div>
    <img className="gearimg" src="https://splatoon-a.com/wp-content/uploads/2018/07/gia-power-spzouka.png"
    width="64" height="64" />
    </div>
    <select className="main" name="supemain" id="supemain" value={this.state.supemain} onChange={this.handleChange}    >
    <option value="0">0</option>
    <option value="10">1</option>
    <option value="20">2</option>
    <option value="30">3</option>
    </select>
    <select className="sub" name="supesub" id="supesub" value={this.state.supesub} onChange={this.handleChange}>
    <option value="0">0</option>
    <option value="3">1</option>
    <option value="6">2</option>
    <option value="9">3</option>
    <option value="12">4</option>
    <option value="15">5</option>
    <option value="18">6</option>
    <option value="21">7</option>
    <option value="24">8</option>
    <option value="27">9</option>
    </select>
    </p>
    <p className="Header">
      <div>
    <img className="gearimg" src="https://topiblo.com/wp-content/uploads/2020/05/IMG_1073.png" alt="" width="64" height="64"
    />
      </div>
      <select className="main" name="kaimain" id="kaimain" value={this.state.kaimain} onChange={this.handleChange}  >
      <option value="0">0</option>
      <option value="10">1</option>
      <option value="20">2</option>
      <option value="30">3</option>
      </select>
      <select className="sub" name="kaisub" id="kaisub" value={this.state.kaisub} onChange={this.handleChange} >
      <option value="0">0</option>
      <option value="3">1</option>
      <option value="6">2</option>
      <option value="9">3</option>
      <option value="12">4</option>
      <option value="15">5</option>
      <option value="18">6</option>
      <option value="21">7</option>
      <option value="24">8</option>
      <option value="27">9</option>
      </select>

    </p>
    <a  target="_blank" href="https://px.a8.net/svt/ejp?a8mat=2ZLH4R+CNGNBU+50+2I2O41" rel="nofollow">
    <img border="0" width="320" height="50" alt="" src="https://www23.a8.net/svt/bgt?aid=180720891765&wid=002&eno=01&mid=s00000000018015129000&mc=1"/></a>
    <img border="0" width="1" height="1" src="https://www18.a8.net/0.gif?a8mat=2ZLH4R+CNGNBU+50+2I2O41" alt=""/>
<button onClick={this. handleSubmit}　className="keisan"> ------　計算　------ </button>
<p >弾数
<input type="text" id="field_total"  name="field_total" value={this.state.field_total}></input>
</p>

<p className="sulaido">スライド回数
<input type="text" id="sulatotal" name="sulatotal" value={this.state.sulatotal}></input>
</p>

<p className="subwepon" >サブ
<input type="text" id="subname"  name="subname" value={this.state.submei}></input>
</p>
<p>
サブインク消費量
<input type="text" id="subpasent" name="subpasent" value={this.state.subpasent}></input>
</p>

<p className="subren">サブ連投数
<input type="text" id="subren"　name="subren" value={this.state.subren}></input>
</p>
<p>
サブ性能アップ効果
<input type="text"id="subseinou"  name="subseinou" value={this.state.subseinou}></input>
</p>

<p>
スペシャル
<input type="text" id="supe"  name="supe" value={this.state.supe}></input>
</p>

<p>
スペシャル性能アップ効果
<input type="text" id="supekyou"  name="supekyou" className="supekyou" value={this.state.supekyou}></input>
</p>

<p>
スペシャル必要塗りポイント
<input type="text" id="supeppt"   name="supeppt"  value={this.state.supeppt}></input>
</p>



<p>
インク回復速度
イカ状態:空～満タン
<input type="text" id="ikakaikai"  name="ikakaikai" value={this.state.ikakaikai} ></input>
</p>

<p>
インク回復速度
ヒト状態:空～満タン
<input type="text" id="hitokai"  name="hitokai" value={this.state.hitokai}></input>
</p>

<div class="container">

					<div class="box">
						<div class="extend">

							<div id="anime" className="anime" ref={this.ika} ></div>
							イカ状態

						</div>
					</div>

				</div>
				<div class="container2">

					<div class="box2">
						<div class="extend2">

							<div id="anime2" className="anime2" ref={this.hito} ></div>
							ヒト状態

						</div>
					</div>

				</div>


  </TabPanel>
  <TabPanel>
  <p></p>
      <div className="Header">
      <div className="main">
      メイン

      </div>
      <div className="sub">
      サブ
      </div>
      </div>
  <p  className="Header">
  <div>
  <img className="gearimg" src="https://topiblo.com/wp-content/uploads/2020/05/IMG_1087.png"
  alt="" width="64" height="64S" />
  </div>
  <select className="main"  name="ikasokumain" id="ikasokumain" value={this.state.ikasokumain} onChange={this.handleChange} >
<option value="0">0</option>
<option value="10">1</option>
<option value="20">2</option>
<option value="30">3</option>
</select>
<select className="sub" name="ikasokumainsub" id="ikasokumainsub" value={this.state.ikasokumainsub} onChange={this.handleChange} >
<option value="0">0</option>
<option value="3">1</option>
<option value="6">2</option>
<option value="9">3</option>
<option value="12">4</option>
<option value="15">5</option>
<option value="18">6</option>
<option value="21">7</option>
<option value="24">8</option>
<option value="27">9</option>
</select>
  </p>
  <p  className="Header">
    <div>
  <img className="gearimg" src="https://topiblo.com/wp-content/uploads/2020/05/IMG_1086.png"
  width="64" height="64" />
  </div>
  <select className="main" name="hisokumain" id="hisokumain" value={this.state.hisokumain} onChange={this.handleChange}    >
  <option value="0">0</option>
  <option value="10">1</option>
  <option value="20">2</option>
  <option value="30">3</option>
  </select>
  <select className="sub" name="hitosokusub" id="hitosokusub" value={this.state.hitosokusub} onChange={this.handleChange}>
  <option value="0">0</option>
  <option value="3">1</option>
  <option value="6">2</option>
  <option value="9">3</option>
  <option value="12">4</option>
  <option value="15">5</option>
  <option value="18">6</option>
  <option value="21">7</option>
  <option value="24">8</option>
  <option value="27">9</option>
  </select>
  </p>
  比較用↓↓
  <p className="Header">
  <div>
  <img className="gearimg" src="https://topiblo.com/wp-content/uploads/2020/05/IMG_1087.png" alt="" width="64" height="64"
  />

  </div>

  <select className="main" name="ikasokumainhi" id="ikasokumainhi" value={this.state.ikasokumainhi} onChange={this.handleChange}   >
  <option value="0">0</option>
  <option value="10">1</option>
  <option value="20">2</option>
  <option value="30">3</option>
  </select>
  <select className="sub" name="ikasokusubhi" id="ikasokusubhi" value={this.state.ikasokusubhi} onChange={this.handleChange}  >
  <option value="0">0</option>
  <option value="3">1</option>
  <option value="6">2</option>
  <option value="9">3</option>
  <option value="12">4</option>
  <option value="15">5</option>
  <option value="18">6</option>
  <option value="21">7</option>
  <option value="24">8</option>
  <option value="27">9</option>
  </select>
  </p>



  <p className="Header">
    <div>
  <img className="gearimg" src="https://topiblo.com/wp-content/uploads/2020/05/IMG_1086.png"alt="" width="64" height="64"
  />
    </div>
    <select className="main" name="hisokumainhi" id="hisokumainhi" value={this.state.hisokumainhi} onChange={this.handleChange}  >
    <option value="0">0</option>
    <option value="10">1</option>
    <option value="20">2</option>
    <option value="30">3</option>
    </select>
    <select className="sub" name="hitosokusubhi" id="hitosokusubhi" value={this.state.hitosokusubhi} onChange={this.handleChange} >
    <option value="0">0</option>
    <option value="3">1</option>
    <option value="6">2</option>
    <option value="9">3</option>
    <option value="12">4</option>
    <option value="15">5</option>
    <option value="18">6</option>
    <option value="21">7</option>
    <option value="24">8</option>
    <option value="27">9</option>
    </select>
  </p>
  <a  target="_blank" href="https://px.a8.net/svt/ejp?a8mat=2ZLH4R+CNGNBU+50+2I2O41" rel="nofollow">
  <img border="0" width="320" height="50" alt="" src="https://www23.a8.net/svt/bgt?aid=180720891765&wid=002&eno=01&mid=s00000000018015129000&mc=1"/></a>
  <img border="0" width="1" height="1" src="https://www18.a8.net/0.gif?a8mat=2ZLH4R+CNGNBU+50+2I2O41" alt=""/>
<button onClick={this.handleSubmitidou}　className="keisan"> ------　計算　------ </button>

<p>移動速度(50x=試しうちﾗｲﾝ1本分)
<input  id="sokudoika"  name="sokudoika" value={this.state.sokudoika}/>
</p>
					<div className="v_line_fix">
						<div className="yoko">←試し撃ちﾗｲﾝ1本分→</div>
						<div className="v_line_fix2">
							<div ><img className="asd" id="asd" ref={this.ikasoku}  src="https://topiblo.com/wp-content/uploads/2020/06/IMG_1090.png" /></div>
							<div className="yoko2">←試し撃ちﾗｲﾝ1本分→</div>
							<div className="v_line_fix3"></div>
						</div>
					</div>
          <p>【比較用】移動速度(50x=試しうちﾗｲﾝ1本分)
          <input  name="sokudoikahikaku" id="sokudoikahikaku"  type="text" value={this.state.sokudoikahikaku} />
          </p>
          <p>イカニンジャ使用時
                <input  name="sokudoikanin" id="sokudoikanin"  type="text" value={this.state.sokudoikanin}  />
                </p>
                <div className="v_line_fix">
        						<div className="yoko">←試し撃ちﾗｲﾝ1本分→</div>
        						<div className="v_line_fix2">
        							<div ><img  className="asd2" id="asd2" ref={this.ikasoku2} src="https://topiblo.com/wp-content/uploads/2020/06/IMG_1090.png"  /></div>
        							<div ><img  className="asd3" id="asd3" ref={this.ikanin}src="https://topiblo.com/wp-content/uploads/2020/06/IMG_1096.png" /></div>
        							<div className="yoko2">←試し撃ちﾗｲﾝ1本分→</div>
        							<div className="v_line_fix3"></div>
        						</div>
                    	</div>
<p>【非射撃時】移動速度(50x=試しうちﾗｲﾝ1本分)
    <input  name="sokudohito" id="sokudohito"  type="text" value={this.state.sokudohito} />
    </p>
    <div className="v_line_fix">
						<div className="yoko">←試し撃ちﾗｲﾝ1本分→</div>
						<div className="v_line_fix2">
							<div><img className="asd4" id="asd4" ref={this.hitosoku} src="https://topiblo.com/wp-content/uploads/2020/06/IMG_1091.png" /></div>
							<div className="yoko2">←試し撃ちﾗｲﾝ1本分→</div>
							<div className="v_line_fix3"></div>
						</div>
					</div>
<p>【比較用：非射撃時】移動速度(50x=試しうちﾗｲﾝ1本分)
              <input   name="sokudohitohikaku" id="sokudohitohikaku"  type="text" value={this.state.sokudohitohikaku} />
              </p>
              <div className="v_line_fix">
          						<div className="yoko">←試し撃ちﾗｲﾝ1本分→</div>
          						<div className="v_line_fix2">
          							<div ><img className="hitopng" className="asd5" id="asd5" ref={this.hitosoku2} src="https://topiblo.com/wp-content/uploads/2020/06/IMG_1091.png" /></div>
          							<div className="yoko2">←試し撃ちﾗｲﾝ1本分→</div>
          							<div className="v_line_fix3"></div>
          						</div>
          					</div>
                    <p>【射撃時】移動速度(50x=試しうちﾗｲﾝ1本分)
                        <input  name="syagekisokudohito" id="syagekisokudohito"  type="text" value={this.state.syagekisokudohito}  />
                        </p>
                        <div className="v_line_fix">
                    						<div className="yoko">←試し撃ちﾗｲﾝ1本分→</div>
                    						<div className="v_line_fix2">
                    							<div ><img className="asd6" id="asd6" ref={this.hitosoku3} src="https://topiblo.com/wp-content/uploads/2020/06/IMG_1091.png" /></div>
                    							<div className="yoko2">←試し撃ちﾗｲﾝ1本分→</div>
                    							<div className="v_line_fix3"></div>
                    						</div>
                    					</div>
                    <p>【比較用：射撃時】移動速度(50x=試しうちﾗｲﾝ1本分)
                                  <input  name="syagekisokudohitohikaku" id="syagekisokudohitohikaku"  type="text" value={this.state.syagekisokudohitohikaku} />
                                  </p>
                                  <div className="v_line_fix">
                              						<div className="yoko">←試し撃ちﾗｲﾝ1本分→</div>
                              						<div className="v_line_fix2">
                              							<div ><img className="asd7" id="asd7" ref={this.hitosoku4} src="https://topiblo.com/wp-content/uploads/2020/06/IMG_1091.png" /></div>
                              							<div className="yoko2">←試し撃ちﾗｲﾝ1本分→</div>
                              							<div className="v_line_fix3"></div>
                              						</div>
                              					</div>
    </TabPanel>
    <TabPanel>
    <p></p>
        <div className="Header">
        <div className="main">
        メイン

        </div>
        <div className="sub">
        サブ
        </div>
        </div>
<div >  <p className="Header">
  <div>
  <img className="gearimg" src="https://topiblo.com/wp-content/uploads/2020/05/IMG_1005.png" alt="スプラトゥーン2】インク効率アップ(メイン)の効果と使い方｜ゲームエイト"
  width="64" height="64" />
  </div>
  <select className="main" name="bakugenmain" id="bakugenmain" value={this.state.bakugenmain} onChange={this.handleChange} >
  <option value="0">0</option>
  <option value="10">1</option>
  <option value="20">2</option>
  <option value="30">3</option>
  </select>
  <select className="sub"  name="bakugensub" id="bakugensub" value={this.state.bakugensub} onChange={this.handleChange} >
<option value="0">0</option>
<option value="3">1</option>
<option value="6">2</option>
<option value="9">3</option>
<option value="12">4</option>
<option value="15">5</option>
<option value="18">6</option>
<option value="21">7</option>
<option value="24">8</option>
<option value="27">9</option>
</select>
  </p>


  <p  className="Header">
  <div>
  <img className="gearimg" src="https://topiblo.com/wp-content/uploads/2020/05/IMG_1004.png"
  alt="" width="64" height="64S" />
  </div>
  <select className="main"  name="annzennmain" id="annzennmain" value={this.state.annzennmain} onChange={this.handleChange} >
<option value="0">0</option>
<option value="10">1</option>
<option value="20">2</option>
<option value="30">3</option>
</select>
<select className="sub" name="annzennsub" id="annzennsub" value={this.state.annzennsub} onChange={this.handleChange} >
<option value="0">0</option>
<option value="3">1</option>
<option value="6">2</option>
<option value="9">3</option>
<option value="12">4</option>
<option value="15">5</option>
<option value="18">6</option>
<option value="21">7</option>
<option value="24">8</option>
<option value="27">9</option>
</select>
  </p>
  <p className="Header">
  <div>
  <img className="gearimg" src="https://topiblo.com/wp-content/uploads/2020/05/IMG_1007-300x300.png" alt="" width="64" height="64"
  />
  </div>

  <select className="main" name="huukatumain" id="huukatumain" value={this.state.huukatumain} onChange={this.handleChange}   >
  <option value="0">0</option>
  <option value="10">1</option>
  <option value="20">2</option>
  <option value="30">3</option>
  </select>
  <select className="sub" name="huukatusub" id="huukatusub" value={this.state.huukatusub} onChange={this.handleChange}  >
  <option value="0">0</option>
  <option value="3">1</option>
  <option value="6">2</option>
  <option value="9">3</option>
  <option value="12">4</option>
  <option value="15">5</option>
  <option value="18">6</option>
  <option value="21">7</option>
  <option value="24">8</option>
  <option value="27">9</option>
  </select>
  </p>
  <p  className="Header">
    <div>
  <img className="gearimg" src="https://topiblo.com/wp-content/uploads/2020/05/IMG_1065.png"
  width="64" height="64" />
  </div>
  <select className="main" name="supatanmain" id="supatanmain" value={this.state.supatanmain} onChange={this.handleChange}    >
  <option value="0">0</option>
  <option value="10">1</option>
  <option value="20">2</option>
  <option value="30">3</option>
  </select>
  <select className="sub" name="supatansub" id="supatansub" value={this.state.supatansub} onChange={this.handleChange}>
  <option value="0">0</option>
  <option value="3">1</option>
  <option value="6">2</option>
  <option value="9">3</option>
  <option value="12">4</option>
  <option value="15">5</option>
  <option value="18">6</option>
  <option value="21">7</option>
  <option value="24">8</option>
  <option value="27">9</option>
  </select>
  </p>
  <p className="Header">
    <div>
  <img className="gearimg" src="https://topiblo.com/wp-content/uploads/2020/06/IMG_1109.png"alt="" width="64" height="64"
  />
    </div>
    <select className="main" name="supegenmain" id="supegenmain" value={this.state.supegenmain} onChange={this.handleChange}  >
    <option value="0">0</option>
    <option value="10">1</option>
    <option value="20">2</option>
    <option value="30">3</option>
    </select>
    <select className="sub" name="supegensub" id="supegensub" value={this.state.supegensub} onChange={this.handleChange} >
    <option value="0">0</option>
    <option value="3">1</option>
    <option value="6">2</option>
    <option value="9">3</option>
    <option value="12">4</option>
    <option value="15">5</option>
    <option value="18">6</option>
    <option value="21">7</option>
    <option value="24">8</option>
    <option value="27">9</option>
    </select>
  </p>
  <a  target="_blank" href="https://px.a8.net/svt/ejp?a8mat=2ZLH4R+CNGNBU+50+2I2O41" rel="nofollow">
  <img border="0" width="320" height="50" alt="" src="https://www23.a8.net/svt/bgt?aid=180720891765&wid=002&eno=01&mid=s00000000018015129000&mc=1"/></a>
  <img border="0" width="1" height="1" src="https://www18.a8.net/0.gif?a8mat=2ZLH4R+CNGNBU+50+2I2O41" alt=""/>
<button onClick={this. handleSubmitsonota}　className="keisan"> ------　計算　------ </button>

</div>

<p><strong>爆風ダメージ軽減・改効果</strong></p>
<p>ジェットパック被ダメージ</p>
<p><input className="bakugen" name="zyettoP" id="zyettoP" size="8" type="text" value={this.state.zyettoP} /></p>
<p>イカスフィア被ダメージ</p>
<p><input className="bakugen" name="sufiaAT" id="sufiaAT" size="8" type="text" value={this.state.sufiaAT} /></p>
<p>バブルランチャー被ダメージ</p>
<p><input className="bakugen" name="baburuE" id="baburuE" size="8" type="text" value={this.state.baburuE} /></p>
<p>クイックボム被ダメージ</p>
<p><input className="bakugen" name="kuibo" id="kuibo" size="8" type="text" value={this.state.kuibo} /></p>
<p>その他ボム被ダメージ</p>
<p><input className="bakugen" name="bomu" id="bomu" size="8" type="text" value={this.state.bomu} /></p>

<p><strong>相手インク影響軽減効果</strong></p>
<p>敵ｲﾝｸを踏んだ時の1ﾌﾚｰﾑ(1/60秒)あたりのﾀﾞﾒｰｼﾞ</p>
<p><input className="annzen" name="tekiink" id="tekiink" size="8" type="text" value={this.state.tekiink} /></p>
<p>敵ｲﾝｸを踏んだ時のﾀﾞﾒｰｼﾞ最大量</p>
<p><input className="annzen" name="tekiinkmax" id="tekiinkmax" size="8" type="text" value={this.state.tekiinkmax} /></p>
<p>敵ｲﾝｸﾀﾞﾒｰｼﾞ無効時間</p>
<p><input className="annzen" name="tekiinkmukou" id="tekiinkmukou" size="8" type="text" value={this.state.tekiinkmukou} /></p>
<p>ﾋﾄ状態:敵ｲﾝｸ移動速度(50x=試し撃ちﾗｲﾝ1本)</p>
<p><input className="annzen" name="tekiinksokudo" id="tekiinksokudo" size="8" type="text" value={this.state.tekiinksokudo} /></p>

<p><strong>復活時間短縮効果</strong></p>
<p>通常時
<input className="annzen" name="fuukatu" id="fuukatu" size="8" type="text" value={this.state.fuukatu} /></p>
<p> 敵：復活ペナルティ装着時
<input className="annzen" name="fuukatupena" id="fuukatupena" size="8" type="text" value={this.state.fuukatupena} /></p>
<p>(参考)</p>
                       <p> 水没：復活時間>>約7秒</p>
                       <p> 落下：復活時間>>約5.5秒</p>
                       <p> ※自滅の場合は復活短縮ギアの効果は発動しません</p>
<p><strong>スーパージャンプ時間短縮効果</strong></p>
<p> 待機時間:ｼﾞｬﾝﾌﾟ先の選択時にｲｶ状態(A)
<input className="supazyan" name="taiki" id="taiki" size="8" type="text" value={this.state.taiki} /></p>
<p>待機時間:ｼﾞｬﾝﾌﾟ先の選択時にﾋﾄ状態(A')
<input className="supazyan" name="taikihito" id="taikihito" size="8" type="text" value={this.state.taikihito} /></p>
<p>ジャンプ時間(B)
<input className="supazyan" name="zyanpu" id="zyanpu" size="8" type="text" value={this.state.zyanpu} /></p>
<p> 合計:ｼﾞｬﾝﾌﾟ先の選択時にｲｶ状態(A+B)
<input className="supazyan" name="zyanpugoukei" id="zyanpugoukei" size="8" type="text" value={this.state.zyanpugoukei} /></p>
<p>合計:ｼﾞｬﾝﾌﾟ先の選択時にﾋﾄ状態(A'+B)
<input className="supazyan" name="zyanpugoukeiaa" id="zyanpugoukeiaa" size="8" type="text" value={this.state.zyanpugoukeiaa} /></p>

<p><strong>スペシャル減少量ダウン効果</strong></p>
<p>スペシャル減少量[%]
<input className="supegenkouka" name="supegen" id="supegen" size="8" type="text" value={this.state.supegen} /></p>
<p>スペシャル減少量[%](相手がﾍﾟﾅﾙﾃｨｱｯﾌﾟ使用時)
<input className="supegenkouka" name="supegen2" id="supegen2" size="8" type="text" value={this.state.supegen2} /></p>
<p><nobr> ※自分がﾍﾟﾅﾙﾃｨｱｯﾌﾟ使用時は計算結果に+22.5%		</nobr></p>
<p><nobr> ※ｽｰﾊﾟｰﾁｬｸﾁ発動中に狩られた場合計算結果に-25%	</nobr></p>
   </TabPanel>
   <TabPanel>
<div className="screen">
<div className="atama">
<div className="head">アタマ</div>
<div className="headgearImg"></div>
<p className="atamaMain">メイン
<select  className="atamaMainSelect"　name="atamaMainSelect" value={this.state.atamaMainSelect} onChange={this.handleChange} onClick={this.handleChange} onBlur={this.handleChange} >
<option value=""  >メインギア選択</option>
<option value="0"  >メインインク効率アップ</option>
<option value="1">サブインク効率アップ</option>
<option value="2">インク回復力アップ</option>
<option value="3">ヒト移動速度アップ</option>
<option value="4">イカダッシュ速度アップ</option>
<option value="5">スペシャル増加量アップ</option>
<option value="6">スペシャル減少量ダウン</option>
<option value="7">スペシャル性能アップ</option>
<option value="8">復活時間短縮</option>
<option value="9">スーパージャンプ時間短縮</option>
<option value="10">サブ性能アップ</option>
<option value="11">相手インク影響軽減</option>
<option value="12">サブ影響軽減</option>
<option value="13">アクション強化</option>
<option value="14">スタートダッシュ</option>
<option value="15">ラストスパート</option>
<option value="16">逆境強化</option>
<option value="17">カムバック</option>


</select>
<div className="atamaMainimg"  ref={this.atamaMainimg}></div></p>

<p className="atamaSub1">サブ1
<select  className="atamaSub1Select" name="atamaSub1Select" value={this.state.atamaSub1Select} onChange={this.handleChange}  onClick={this.handleChange} onBlur={this.handleChange}>
<option value=""  >サブギア選択</option>
<option value="0"  >メインインク効率アップ</option>
<option value="1">サブインク効率アップ</option>
<option value="2">インク回復力アップ</option>
<option value="3">ヒト移動速度アップ</option>
<option value="4">イカダッシュ速度アップ</option>
<option value="5">スペシャル増加量アップ</option>
<option value="6">スペシャル減少量ダウン</option>
<option value="7">スペシャル性能アップ</option>
<option value="8">復活時間短縮</option>
<option value="9">スーパージャンプ時間短縮</option>
<option value="10">サブ性能アップ</option>
<option value="11">相手インク影響軽減</option>
<option value="12">サブ影響軽減</option>
<option value="13">アクション強化</option>

</select>
<div className="atamaSub1img" ref={this.atamaSub1img} ></div></p>

<p className="atamaSub2">サブ2
<select  className="atamaSub2Select"  name="atamaSub2Select" value={this.state.atamaSub2Select} onChange={this.handleChange}　  onClick={this.handleChange} onBlur={this.handleChange}>
<option value=""  >サブギア選択</option>
<option value="0"  >メインインク効率アップ</option>
<option value="1">サブインク効率アップ</option>
<option value="2">インク回復力アップ</option>
<option value="3">ヒト移動速度アップ</option>
<option value="4">イカダッシュ速度アップ</option>
<option value="5">スペシャル増加量アップ</option>
<option value="6">スペシャル減少量ダウン</option>
<option value="7">スペシャル性能アップ</option>
<option value="8">復活時間短縮</option>
<option value="9">スーパージャンプ時間短縮</option>
<option value="10">サブ性能アップ</option>
<option value="11">相手インク影響軽減</option>
<option value="12">サブ影響軽減</option>
<option value="13">アクション強化</option>

</select>
<div className="atamaSub2img" ref={this.atamaSub2img}></div></p>

<p className="atamaSub3">サブ3
<select  className="atamaSub3Select"  name="atamaSub3Select" value={this.state.atamaSub3Select} onChange={this.handleChange} onClick={this.handleChange} onBlur={this.handleChange}>
<option value=""  >サブギア選択</option>
<option value="0"  >メインインク効率アップ</option>
<option value="1">サブインク効率アップ</option>
<option value="2">インク回復力アップ</option>
<option value="3">ヒト移動速度アップ</option>
<option value="4">イカダッシュ速度アップ</option>
<option value="5">スペシャル増加量アップ</option>
<option value="6">スペシャル減少量ダウン</option>
<option value="7">スペシャル性能アップ</option>
<option value="8">復活時間短縮</option>
<option value="9">スーパージャンプ時間短縮</option>
<option value="10">サブ性能アップ</option>
<option value="11">相手インク影響軽減</option>
<option value="12">サブ影響軽減</option>
<option value="13">アクション強化</option>

</select>
<div className="atamaSub3img" ref={this.atamaSub3img}></div></p>

</div>

<div className="huku">
<div className="bodyg">フク</div>
<div className="hukugearImg"></div>
<p className="hukuMain">メイン
  <select  className="hukuMainSelect" name="hukuMainSelect" value={this.state.hukuMainSelect} onChange={this.handleChange}　 onClick={this.handleChange} onBlur={this.handleChange}>
<option value=""  >メインギア選択</option>
<option value="0"  >メインインク効率アップ</option>
<option value="1">サブインク効率アップ</option>
<option value="2">インク回復力アップ</option>
<option value="3">ヒト移動速度アップ</option>
<option value="4">イカダッシュ速度アップ</option>
<option value="5">スペシャル増加量アップ</option>
<option value="6">スペシャル減少量ダウン</option>
<option value="7">スペシャル性能アップ</option>
<option value="8">復活時間短縮</option>
<option value="9">スーパージャンプ時間短縮</option>
<option value="10">サブ性能アップ</option>
<option value="11">相手インク影響軽減</option>
<option value="12">サブ影響軽減</option>
<option value="13">アクション強化</option>
<option value="18">イカニンジャ</option>
<option value="19">リベンジ</option>
<option value="20">サーマルインク</option>
<option value="21">復活ペナルティアップ</option>
<option value="22">追加ギアパワー倍化</option>

</select>
<div className="hukuMainimg" ref={this.hukuMainimg}></div></p>

<p className="hukuSub1">サブ1
<select  className="hukuSub1Select"  name="hukuSub1Select" value={this.state.hukuSub1Select} onChange={this.handleChange}  onClick={this.handleChange} onBlur={this.handleChange}>
<option value=""  >サブギア選択</option>
<option value="0"  >メインインク効率アップ</option>
<option value="1">サブインク効率アップ</option>
<option value="2">インク回復力アップ</option>
<option value="3">ヒト移動速度アップ</option>
<option value="4">イカダッシュ速度アップ</option>
<option value="5">スペシャル増加量アップ</option>
<option value="6">スペシャル減少量ダウン</option>
<option value="7">スペシャル性能アップ</option>
<option value="8">復活時間短縮</option>
<option value="9">スーパージャンプ時間短縮</option>
<option value="10">サブ性能アップ</option>
<option value="11">相手インク影響軽減</option>
<option value="12">サブ影響軽減</option>
<option value="13">アクション強化</option>

</select>
<div className="hukuSub1img" ref={this.hukuSub1img}></div></p>

<p className="hukuSub2">サブ2
<select  className="hukuSub2Select" name="hukuSub2Select" value={this.state.hukuSub2Select} onChange={this.handleChange}  onClick={this.handleChange} onBlur={this.handleChange}>
<option value=""  >サブギア選択</option>
<option value="0"  >メインインク効率アップ</option>
<option value="1">サブインク効率アップ</option>
<option value="2">インク回復力アップ</option>
<option value="3">ヒト移動速度アップ</option>
<option value="4">イカダッシュ速度アップ</option>
<option value="5">スペシャル増加量アップ</option>
<option value="6">スペシャル減少量ダウン</option>
<option value="7">スペシャル性能アップ</option>
<option value="8">復活時間短縮</option>
<option value="9">スーパージャンプ時間短縮</option>
<option value="10">サブ性能アップ</option>
<option value="11">相手インク影響軽減</option>
<option value="12">サブ影響軽減</option>
<option value="13">アクション強化</option>

</select>
<div className="hukuSub2img" ref={this.hukuSub2img}></div></p>

<p className="hukuSub3">サブ3
<select  className="hukuSub3Select"  name="hukuSub3Select" value={this.state.hukuSub3Select} onChange={this.handleChange}  onClick={this.handleChange} onBlur={this.handleChange}>
<option value=""  >サブギア選択</option>
<option value="0"  >メインインク効率アップ</option>
<option value="1">サブインク効率アップ</option>
<option value="2">インク回復力アップ</option>
<option value="3">ヒト移動速度アップ</option>
<option value="4">イカダッシュ速度アップ</option>
<option value="5">スペシャル増加量アップ</option>
<option value="6">スペシャル減少量ダウン</option>
<option value="7">スペシャル性能アップ</option>
<option value="8">復活時間短縮</option>
<option value="9">スーパージャンプ時間短縮</option>
<option value="10">サブ性能アップ</option>
<option value="11">相手インク影響軽減</option>
<option value="12">サブ影響軽減</option>
<option value="13">アクション強化</option>

</select>
<div className="hukuSub3img" ref={this.hukuSub3img}></div></p>

</div>

<div className="kutu">
<div className="foot">クツ</div>
<div className="kutugearImg"></div>
<p className="kutuMain">メイン
<select  className="kutuMainSelect"  name="kutuMainSelect" value={this.state.kutuMainSelect} onChange={this.handleChange}  onClick={this.handleChange} onBlur={this.handleChange}>
<option value=""  >メインギア選択</option>
<option value="0"  >メインインク効率アップ</option>
<option value="1">サブインク効率アップ</option>
<option value="2">インク回復力アップ</option>
<option value="3">ヒト移動速度アップ</option>
<option value="4">イカダッシュ速度アップ</option>
<option value="5">スペシャル増加量アップ</option>
<option value="6">スペシャル減少量ダウン</option>
<option value="7">スペシャル性能アップ</option>
<option value="8">復活時間短縮</option>
<option value="9">スーパージャンプ時間短縮</option>
<option value="10">サブ性能アップ</option>
<option value="11">相手インク影響軽減</option>
<option value="12">サブ影響軽減</option>
<option value="13">アクション強化</option>





<option value="23">ステルスジャンプ</option>
<option value="24">対物攻撃力アップ</option>
<option value="25">受け身術</option>
</select>
<div className="kutuMainimg" ref={this.kutuMainimg}></div></p>

<p className="kutuSub1">サブ1
<select  className="kutuSub1Select"  name="kutuSub1Select" value={this.state.kutuSub1Select} onChange={this.handleChange}  onClick={this.handleChange} onBlur={this.handleChange}>
<option value=""  >サブギア選択</option>
<option value="0"  >メインインク効率アップ</option>
<option value="1">サブインク効率アップ</option>
<option value="2">インク回復力アップ</option>
<option value="3">ヒト移動速度アップ</option>
<option value="4">イカダッシュ速度アップ</option>
<option value="5">スペシャル増加量アップ</option>
<option value="6">スペシャル減少量ダウン</option>
<option value="7">スペシャル性能アップ</option>
<option value="8">復活時間短縮</option>
<option value="9">スーパージャンプ時間短縮</option>
<option value="10">サブ性能アップ</option>
<option value="11">相手インク影響軽減</option>
<option value="12">サブ影響軽減</option>
<option value="13">アクション強化</option>

</select>
<div className="kutuSub1img"  ref={this.kutuSub1img}></div></p>

<p className="kutuSub2">サブ2
<select  className="kutuSub2Select"    name="kutuSub2Select" value={this.state.kutuSub2Select} onChange={this.handleChange}  onClick={this.handleChange} onBlur={this.handleChange}>
<option value=""  >サブギア選択</option>
<option value="0"  >メインインク効率アップ</option>
<option value="1">サブインク効率アップ</option>
<option value="2">インク回復力アップ</option>
<option value="3">ヒト移動速度アップ</option>
<option value="4">イカダッシュ速度アップ</option>
<option value="5">スペシャル増加量アップ</option>
<option value="6">スペシャル減少量ダウン</option>
<option value="7">スペシャル性能アップ</option>
<option value="8">復活時間短縮</option>
<option value="9">スーパージャンプ時間短縮</option>
<option value="10">サブ性能アップ</option>
<option value="11">相手インク影響軽減</option>
<option value="12">サブ影響軽減</option>
<option value="13">アクション強化</option>

</select>
<div className="kutuSub2img"  ref={this.kutuSub2img}></div></p>

<p className="kutuSub3">サブ3
<select  className="kutuSub3Select"   name="kutuSub3Select" value={this.state.kutuSub3Select} onChange={this.handleChange}  onClick={this.handleChange} onBlur={this.handleChange}>
<option value=""  >サブギア選択</option>
<option value="0"  >メインインク効率アップ</option>
<option value="1">サブインク効率アップ</option>
<option value="2">インク回復力アップ</option>
<option value="3">ヒト移動速度アップ</option>
<option value="4">イカダッシュ速度アップ</option>
<option value="5">スペシャル増加量アップ</option>
<option value="6">スペシャル減少量ダウン</option>
<option value="7">スペシャル性能アップ</option>
<option value="8">復活時間短縮</option>
<option value="9">スーパージャンプ時間短縮</option>
<option value="10">サブ性能アップ</option>
<option value="11">相手インク影響軽減</option>
<option value="12">サブ影響軽減</option>
<option value="13">アクション強化</option>

</select>
<div className="kutuSub3img"  ref={this.kutuSub3img}></div></p>

</div>
</div>
<div className="gurafukeisan">



<button onClick={this. handleSubmit4}  className="keisan"> ------　計算　------ </button>
<div　 className="ikapawer">このギアのイカパワー</div>
<div><input className="sentouRyoku" name="sentouRyoku" id="sentouRyoku" type="text" value={this.state.sentouRyoku} /></div>

</div>
<p></p>
   <RadarChart  // レーダーチャート全体の設定を記述
       cx={250}  // 要素の左端とチャートの中心点との距離(0にするとチャートの左半分が隠れる)
       cy={250}  // 要素の上部とチャートの中心点との距離(0にするとチャートの上半分が隠れる)
       outerRadius={75}  // レーダーチャート全体の大きさ
       width={370}  // レーダーチャートが記載される幅(この幅よりチャートが大きい場合、はみ出た箇所は表示されない)
       height={500}   // レーダーチャートが記載される高さ
       data={data}  // 表示対象のデータ
   >
       {/* レーダーチャートの蜘蛛の巣のような線 */}
       <PolarGrid />
       {/* 項目を決めるデータのキー(サンプルでいう数学や歴史) */}
       <PolarAngleAxis dataKey="subject" />

       {/* 目安となる数値が表示される線を指定  */}
       <PolarRadiusAxis
           angle={30}  // 中心点から水平を0°とした時の角度 垂直にしたいなら90を指定
           domain={[0, 100]}  // リストの１番目の要素が最小値、2番目の要素が最大値
       />

       {/* レーダーを表示 */}
       <Radar
           name="イカパワー"  // そのチャートが誰のデータか指定(チャート下にここで指定した値が表示される)
           dataKey="A"   // 表示する値と対応するdata内のキー
           stroke="#8884d8"  // レーダーの外枠の色
           fill="#8884d8"  // レーダー内の色
           fillOpacity={0.6}  // レーダー内の色の濃さ(1にすると濃さMAX)
       />



       {/* グラフの下のAさんBさんの表記 */}
       <Legend />
   </RadarChart>

   </TabPanel>

    </div>


</form>

  </Tabs>

  );


}


}


export default App;
